import { RoleFieldTypes } from '../types/RoleFieldTypes';

const actionsToSkip = [
  'template',
  'displayName',
  'maxAssign',
  'org',
  'id',
  'permissions',
  'requireTrainingVideoMobile',
];

export const transformFieldsToPermissions = (role: RoleFieldTypes) => {
  const permissions = Object.entries(role)
    .map(([key]) => {
      const parts = key.split('.');
      const action = parts.pop();
      const subject = parts.join('.');
      return { subject, action };
    })
    .filter(({ action }) => action && !actionsToSkip.includes(action));

  return permissions;
};

import { AlertType } from '../types';

export const defaultAspectRatioNumbers = {
  width: 953,
  height: 714.75,
};

export const defaultAspectRatio = {
  width: `${defaultAspectRatioNumbers.width}px`,
  height: `${defaultAspectRatioNumbers.height}px`,
};

export const alertDisplayMap: Record<AlertType, string> = {
  fall: 'Lying on floor',
  out_of_bed: 'Leaving bed',
  out_of_chair: 'Out of chair',
  moving_in_bed: 'Motion in bed',
  hidden: 'Hidden',
  urgent: 'Urgent',
  early_warning: 'Early Warning',
  '': '',
};

export const screens = [
  { label: 'Rooms', path: '/rooms', subject: 'virtual-care.rooms' },
  {
    label: 'Event Review',
    path: '/event-review',
    subject: 'virtual-care.event-review',
  },
  {
    label: 'Tableau Analytics',
    path: '/analytics',
    subject: 'virtual-care.analytics',
  },
  { label: 'Admin', path: '/admin', subject: 'virtual-care.admin' },
] as const;

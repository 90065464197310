import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { User } from '../../types';
import { clearSentryUser, setSentryUser } from '../utility/sentry';

type Props = {
  children: JSX.Element;
};

const CurrentUserContext = createContext<{
  user?: User | null;
  setUser: (user?: User | null) => void;
  isUmbrella: boolean;
  setIsUmbrella: (isUmbrella: boolean) => void;
}>({
  user: null,
  setUser: () => {
    /* empty */
  },
  isUmbrella: false,
  setIsUmbrella: () => {
    /* empty */
  },
});

export const useCurrentUser = () => useContext(CurrentUserContext);
export const useIsUmbrella = () => useCurrentUser().isUmbrella;

export const CurrentUserContextProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User | null | undefined>(null);
  const [isUmbrella, setIsUmbrella] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setSentryUser(user);
    } else {
      clearSentryUser();
    }
  }, [user]);

  const value = useMemo(
    () => ({
      user,
      setUser,
      isUmbrella,
      setIsUmbrella,
    }),
    [user, setUser, isUmbrella, setIsUmbrella],
  );

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};

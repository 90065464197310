import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { ImageNotSupported } from '@mui/icons-material';
import { differenceInSeconds } from 'date-fns';

import { defaultAspectRatio } from '../../rules';
import { useZoom } from '../../utility/hooks';

interface Props {
  onClick?: () => void;
  src?: string;
  width?: number | string;
  height?: number | string;
  enableZoom?: boolean;
  sx?: SxProps<Theme>;
  privacy?: boolean;
  privEndsAt?: string;
  disabled?: boolean;
  loading?: boolean;
  offline?: boolean;
  testID?: string;
}

function secondsToTime(seconds: number) {
  const h = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, '0');

  const m = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, '0');

  const s = Math.floor(seconds % 60)
    .toString()
    .padStart(2, '0');

  return `${h}:${m}:${s}`;
}

const RoomImage = ({
  onClick,
  src,
  width = defaultAspectRatio.width,
  height = defaultAspectRatio.height,
  enableZoom = false,
  sx = {},
  privacy = false,
  privEndsAt,
  disabled = false,
  loading = false,
  offline = false,
  testID,
}: Props) => {
  const zoomHTMLElement = useRef<null | HTMLImageElement>(null);
  const [privTime, setPrivTime] = useState<string | null>(null);

  useZoom(enableZoom, zoomHTMLElement);

  const statusBoxSxProps = useMemo(
    () => ({
      width,
      height,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 1,
    }),
    [width, height],
  );

  useEffect(() => {
    if (privacy && privEndsAt) {
      const interval = setInterval(() => {
        const seconds = differenceInSeconds(new Date(privEndsAt), new Date());

        if (seconds < 0) {
          setPrivTime(null);
        } else {
          setPrivTime(secondsToTime(seconds));
        }
      }, 1000);

      return () => clearInterval(interval);
    }

    return () => true;
  }, [privacy, privEndsAt]);

  return (
    <Box sx={{ ...sx, width, height }} onClick={onClick} data-testid={testID}>
      {disabled && (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.disableAugi.main,
            color: theme.palette.disableAugi.contrastText,
            ...statusBoxSxProps,
          })}
        >
          AUGi Disabled
        </Box>
      )}

      {(offline || privacy) && (
        <Box
          sx={(theme) => ({
            ...(privacy
              ? {
                  background:
                    'linear-gradient(180deg, rgba(71,109,195,1) 0%, rgba(172,193,253,1) 100%)',
                  color: theme.palette.disableAugi.contrastText,
                }
              : {
                  bgcolor: 'grey.400',
                  color: 'grey.600',
                }),
            ...statusBoxSxProps,
          })}
        >
          {privacy ? (
            <Stack direction='column' alignItems='center'>
              <Typography sx={{ userSelect: 'none', fontSize: '1.5em' }}>
                Virtual Curtain
              </Typography>
              <Typography sx={{ userSelect: 'none', fontSize: '1.5em' }}>
                {privTime}
              </Typography>
            </Stack>
          ) : (
            <>
              <ImageNotSupported fontSize='large' />
              <Typography variant='body2' sx={{ userSelect: 'none' }}>
                Basestation Offline
              </Typography>
            </>
          )}
        </Box>
      )}

      {loading && (
        <Skeleton variant='rectangular' width={width} height={height} />
      )}

      <img
        src={src}
        ref={zoomHTMLElement}
        width={width}
        height={height}
        alt=''
      />
    </Box>
  );
};

export default RoomImage;

import { Box, CircularProgress, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Control } from 'react-hook-form';

import { Beacon } from '@inspiren-monorepo/virtual-care/api-contracts';

import { SelectOrg } from './SelectOrg';
import { SelectUser } from './SelectUser';

import { getOrgs } from '../../../data-access/getOrgs';
import ControlledTextField from '../../../modals/ControlledTextField';

interface Props {
  control: Control;
  type: 'add' | 'edit';
  defaultOrg?: string;
  beacons?: Beacon[];
  isAdmin: boolean;
}

export const BeaconModalForm = ({
  control,
  type,
  defaultOrg,
  beacons,
  isAdmin,
}: Props) => {
  const {
    isLoading: orgsLoading,
    data: orgs,
    isSuccess: orgsSuccess,
  } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  if (orgsLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!orgsSuccess) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant='h6' color='error'>
          Error loading organizations
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ display: isAdmin ? undefined : 'none' }}>
        <SelectOrg
          control={control}
          orgs={orgs}
          defaultValue={defaultOrg}
          required={false}
        />
      </Box>
      <ControlledTextField
        id='id'
        label='ID'
        control={control}
        disabled={type === 'edit'}
      />
      <SelectUser
        id='assignedTo'
        label='Assigned To'
        control={control}
        beacons={beacons}
      />
    </>
  );
};

import { useCallback } from 'react';
import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { ControlledError } from '@inspiren-monorepo/shared-types';

import { Basestation } from '../../../../../../types';
import { postBasestation, putBasestation } from '../../../../../utility';
import { makeRoomId } from '../../../../../utility/helpers/id';
import { getBasestations } from '../../../data-access/getBasestations';
import { OnSubmitFormModal } from '../../../modals/FormModalBase';
import { FieldTypes } from '../types/BasestationFieldTypes';

type Props = {
  queryOptions?: Pick<
    UseQueryOptions<Basestation[], unknown, Basestation[], string[]>,
    'refetchOnMount' | 'refetchOnWindowFocus'
  >;
};

const useBasestationUpsert = ({ queryOptions = {} }: Props = {}) => {
  const queryClient = useQueryClient();

  const {
    isLoading: basestationsLoading,
    isError: basestationsError,
    data: basestations,
  } = useQuery({
    ...queryOptions,
    queryKey: ['basestations'],
    queryFn: getBasestations,
  });

  const handleEditSubmit: OnSubmitFormModal<FieldTypes> = useCallback(
    async ({
      organization,
      buildingFloorUnit,
      room: roomNumber,
      assign,
      id,
      ...remainingFields
    }) => {
      const roomId = makeRoomId(organization, buildingFloorUnit, roomNumber);

      const data = {
        ...remainingFields,
        id,
        room: assign ? roomId : 'unassigned',
      };

      if (
        assign &&
        (basestations || []).find(
          (base) => base.room === roomId && base.id !== id,
        )
      ) {
        throw new ControlledError(
          `${roomId} already has a basestation assigned to it. Please remove it before assigning this one`,
        );
      }

      await putBasestation(data);

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['demo-actions'] }),
        queryClient.invalidateQueries({ queryKey: ['basestations'] }),
      ]);
    },
    [basestations],
  );

  const handleAddSubmit: OnSubmitFormModal<FieldTypes> = useCallback(
    async ({
      organization,
      buildingFloorUnit,
      room: roomNumber,
      assign,
      id,
      ...remainingFields
    }) => {
      const roomId = makeRoomId(organization, buildingFloorUnit, roomNumber);

      const data = {
        ...remainingFields,
        id,
        room: assign ? roomId : 'unassigned',
      };

      if (
        assign &&
        (basestations || []).find(
          (base) => base.room === roomId && base.id !== id,
        )
      ) {
        throw new ControlledError(
          `${roomId} already has a basestation assigned to it. Please remove it before assigning this one`,
        );
      }

      await postBasestation(data);

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['demo-actions'] }),
        queryClient.invalidateQueries({ queryKey: ['basestations'] }),
      ]);
    },
    [basestations],
  );

  return {
    basestations,
    basestationsError,
    basestationsLoading,
    handleAddSubmit,
    handleEditSubmit,
  };
};

export default useBasestationUpsert;

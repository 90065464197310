import { useCallback, useMemo } from 'react';
import { groupBy, isEmpty } from 'lodash';

import { basestationsTableFields } from './constants/basestationsTableFields';
import useBasestationUpsert from './hooks/useBasestationUpsert';
import { FieldTypes } from './types/BasestationFieldTypes';

import { DataFields } from '../../types/DataFields';
import ImportTable from '../ImportTable';
import { mapCsvRowsToFields } from '../ImportTable/helpers/mapCsvRowsToFields';
import {
  FieldsConfiguration,
  Importable,
} from '../ImportTable/types/importable';
import { UploadFn } from '../ImportTable/types/uploadFn';
import csvCellMappers from '../ImportTable/utils/csvCellMappers';

const assigneeMapper = (input?: string): string => {
  const fixedInput = csvCellMappers.String(input);

  if (fixedInput?.toLocaleLowerCase() === 'unassigned') {
    return '';
  }

  return fixedInput;
};

const fieldsConfiguration: FieldsConfiguration<FieldTypes> = {
  id: {
    csvHeader: 'id',
    mapper: csvCellMappers.String,
  },
  organization: {
    csvHeader: 'organization',
    mapper: assigneeMapper,
  },
  buildingFloorUnit: {
    csvHeader: 'building-floor-unit',
    mapper: assigneeMapper,
  },
  room: {
    csvHeader: 'room',
    csvHeaderRegExp: /room( id)?/i,
    mapper: assigneeMapper,
  },
  virtual: {
    csvHeader: 'virtual',
    mapper: csvCellMappers.Boolean,
  },
};

const columns: DataFields<FieldTypes> = basestationsTableFields.filter(
  (e) => !!fieldsConfiguration[e.field],
);

const BasestationsImportTable = () => {
  const {
    basestations,
    basestationsLoading,
    handleAddSubmit,
    handleEditSubmit,
  } = useBasestationUpsert({
    queryOptions: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  });

  const basestationsMap = useMemo(
    () => groupBy(basestations || [], 'id'),
    [basestations],
  );

  const mapCsvToFields = useCallback(
    async (csvRows: string[][]): Promise<Importable<FieldTypes>[]> =>
      mapCsvRowsToFields(
        csvRows,
        fieldsConfiguration,
        (e) => !!basestationsMap[e.id],
      ),
    [basestationsMap],
  );

  const createDefaultRow = useCallback(
    (): FieldTypes =>
      ({
        id: '',
        organization: '',
        buildingFloorUnit: '',
        room: '',
        virtual: false,
      }) as FieldTypes,
    [],
  );

  const upload: UploadFn<FieldTypes> = useCallback(
    async (item) => {
      const {
        $meta: { alreadyExists },
        ...rest
      } = item;

      const fn = alreadyExists ? handleEditSubmit : handleAddSubmit;

      await fn({
        ...rest,
        assign:
          !isEmpty(rest.organization) &&
          !isEmpty(rest.buildingFloorUnit) &&
          !isEmpty(rest.room),
      });
    },
    [handleAddSubmit, handleEditSubmit],
  );

  const onRowUpdate = useCallback(
    (row: Importable<FieldTypes>): Importable<FieldTypes> => ({
      ...row,
      $meta: {
        ...row.$meta,
        alreadyExists: !!basestationsMap[row.id] || false,
      },
    }),
    [basestationsMap],
  );

  return (
    <ImportTable
      fields={columns}
      createDefaultRow={createDefaultRow}
      mapCsvToFields={mapCsvToFields}
      loading={basestationsLoading}
      upload={upload}
      itemName='basestations'
      onRowUpdate={onRowUpdate}
      bulkUpdateSupport
    />
  );
};

export default BasestationsImportTable;

import { Box, Unstable_Grid2 as Grid } from '@mui/material';

import { TableauUserView } from '@inspiren-monorepo/util-tableau';

import { TableauView } from './TableauView';

type Params = {
  views: TableauUserView[];
};

export const TableauViews = ({ views }: Params) => (
  <Box sx={{ px: 2, flex: 1, display: 'flex', flexDirection: 'column' }}>
    <Grid container spacing={3} sx={{ width: '100%' }}>
      {views.map((view) => (
        <Grid
          xs={3}
          key={view.id}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <TableauView view={view} />
        </Grid>
      ))}
    </Grid>
  </Box>
);

import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { InterceptorFactory } from '../types';

const DEFAULT_STATUSES_TO_HANDLE = [401, 403];

type Options = {
  onUnauthorized: (response: AxiosResponse) => Promise<void> | void;
  errorLogger?: (error: unknown) => void;
  statusesToHandle?: number[];
  skipPaths?: string[];
};

const shouldSkipPath = (path?: string, skipPaths?: string[]): boolean =>
  !!path && !!skipPaths?.length && skipPaths.includes(path);

const shouldHandleStatus = (
  status: number,
  statusesToHandle?: number[],
): boolean => (statusesToHandle || DEFAULT_STATUSES_TO_HANDLE).includes(status);

const unauthorizedInterceptorFactory: InterceptorFactory<Options> =
  (options: Options) => async (instance: AxiosInstance) => {
    const { onUnauthorized, skipPaths, statusesToHandle, errorLogger } =
      options;

    instance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (!error?.response) {
          throw error;
        }

        try {
          const {
            status,
            config: { url },
          } = error.response;

          if (
            shouldHandleStatus(status, statusesToHandle) &&
            !shouldSkipPath(url, skipPaths)
          ) {
            await onUnauthorized(error.response);
          }
        } catch (error_) {
          errorLogger?.(error_);
        }

        throw error;
      },
    );
  };

export default unauthorizedInterceptorFactory;

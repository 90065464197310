import { RoomRegion } from '@inspiren-monorepo/hilq/api-contracts';

import { Api } from '../../../utility/client';

export const getRoomRegions = async (
  roomId: string,
): Promise<RoomRegion[][]> => {
  try {
    const res = await Api.get<RoomRegion[][]>(
      `/telesitting/v1/rooms/${roomId}/regions`,
    );

    return res?.data;
  } catch (error) {
    console.error('error requesting room regions');
    throw error;
  }
};

import { User } from '../../../../types';
import { Api } from '../../../utility/client';

export type AdminToolUser = Pick<
  User,
  | 'mainId'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'roleId'
  | 'unit'
  | 'levelAccess'
  | 'username'
  | 'beacon'
  | 'mobilePhone'
  | 'mobilePhoneVerified'
  | 'auth0Connection'
>;

export interface LastKey {
  mainId: string;
  secondaryId: string;
  org: string;
  email: string;
}

export interface UsersTableQueryResponse {
  users: AdminToolUser[];
  lastKey: LastKey | undefined;
}

export async function getUsersTableQuery(org: string, lastKey?: LastKey) {
  return Api.get<UsersTableQueryResponse>('admin/v1/users/table', {
    params: {
      org,
      lastKey,
    },
  }).then((res) => res.data);
}

import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import * as yup from 'yup';

import TableBase from './TableBase';

import { getFloors } from '../../../utility';
import { hours } from '../../../utility/constants';
import {
  extractBuildingFloorFromFloorId,
  extractOrgFromFloorId,
} from '../../../utility/helpers/id';
import { RenderEscalationValues } from '../components/RenderEscalationValues';
import { getOrgs } from '../data-access/getOrgs';
import { getUnits } from '../data-access/getUnits';
import { postUnit } from '../data-access/postUnit';
import { putUnit } from '../data-access/putUnit';
import { UnitUpdate } from '../data-access/types/UnitUpdate';
import { OnSubmitFormModal } from '../modals/FormModalBase';
import { returnEscTooltipLabel } from '../modals/helpers/returnTooltipLabel';
import { DataFields } from '../types/DataFields';

export type FieldTypes = {
  org: string;
  buildingFloor: string;
  name: string;
  displayName: string;
  address: string;
  curtainFeature: boolean;
  privOpts: string[];
  wThresh: string;
  rThresh: string;
  nThresh: string;
  wnThresh: string;
  nStart: string;
  nEnd: string;
  disableAugi: boolean;
  imageExp: string;
  eventExp: string;
  audible: boolean;
  hide: boolean;
  nightLow: boolean;
  escFall: {
    assigned: string;
    unit: string;
    building: string;
  };
  escOOC: {
    assigned: string;
    unit: string;
    building: string;
  };
  escLB: {
    assigned: string;
    unit: string;
    building: string;
  };
  escUnit: boolean;
  escHidden: {
    assigned: string;
    unit: string;
    building: string;
  };
  escUrgent: {
    assigned: string;
    unit: string;
    building: string;
  };
  escWarning: {
    assigned: string;
    unit: string;
    building: string;
  };
  disableAugiAlertInterval: string;
};

const numberSchema = yup
  .number()
  .typeError('This setting must be a number')
  .min(0)
  .integer('This setting must be a whole number')
  // Empty string is also an acceptable value
  .transform((value, originalValue) => (originalValue === '' ? null : value))
  .nullable();

const escNumberSchema = yup.object({
  assigned: yup
    .number()
    .typeError('This setting must be a number')
    .integer('This setting must be a whole number')
    // Empty string is also an acceptable value
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable(),
  unit: yup
    .number()
    .typeError('This setting must be a number')
    .integer('This setting must be a whole number')
    // Empty string is also an acceptable value
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable(),
  building: yup
    .number()
    .typeError('This setting must be a number')
    .integer('This setting must be a whole number')
    // Empty string is also an acceptable value
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable(),
});

const fields: DataFields<FieldTypes> = [
  {
    field: 'org',
    label: 'Organization',
    width: 150,
    editType: 'special',
    schema: yup.string().required('You must select an organization'),
  },
  {
    field: 'buildingFloor',
    label: 'Building-Floor',
    width: 200,
    editType: 'special',
    schema: yup.string().required('You must select a building and floor'),
  },
  {
    field: 'name',
    label: 'ID',
    width: 150,
    editType: 'text',
    editable: false,
    schema: yup
      .string()
      .required('You must provide an ID')
      .matches(
        /^[\d.a-z]+$/i,
        'ID can only include letters, numbers, and periods.',
      ),
  },
  {
    field: 'displayName',
    label: 'Display name',
    width: 180,
    editType: 'text',
    initialValue: '',
    schema: yup.string().notRequired(),
  },
  {
    field: 'address',
    label: 'Address',
    width: 180,
    editType: 'text',
    disabled: true,
    hideOnAdd: true,
  },
  {
    field: 'curtainFeature',
    label: 'Virtual Curtain',
    width: 160,
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'disableAugi',
    label: 'Disable AUGi',
    width: 160,
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'audible',
    label: 'Audible Messages',
    width: 160,
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'nightLow',
    label: 'Night Low Fall Risk',
    width: 160,
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'hide',
    label: 'Hide',
    width: 160,
    editType: 'boolean',
    hideOnAdd: true,
  },
  {
    field: 'privOpts',
    label: 'Virtual curtain duration options',
    width: 220,
    editType: 'special',
    hideOnAdd: true,
    initialValue: [],
    renderCell: ({ value }) =>
      value?.map((num: string) => `${num} min`).join(', '),
    schema: yup.array(numberSchema),
  },
  {
    field: 'wThresh',
    label: 'Warning threshold (day)',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
  },
  {
    field: 'wnThresh',
    label: 'Warning threshold (night)',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
  },
  {
    field: 'rThresh',
    label: 'Rounding threshold',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
  },
  {
    field: 'nThresh',
    label: 'Night mode threshold',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
  },
  {
    field: 'nStart',
    label: 'Night mode start',
    width: 200,
    editType: 'select',
    hideOnAdd: true,
    options: hours,
    renderCell: ({ value }) =>
      hours.find((hour) => hour.value === value)?.label,
  },
  {
    field: 'nEnd',
    label: 'Night mode end',
    width: 200,
    editType: 'select',
    hideOnAdd: true,
    options: hours,
    renderCell: ({ value }) =>
      hours.find((hour) => hour.value === value)?.label,
  },
  {
    field: 'imageExp',
    label: 'Image deletion timeframe',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
    renderCell: ({ value }) => value && `${value} days`,
  },
  {
    field: 'eventExp',
    label: 'Event deletion timeframe',
    width: 200,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
    renderCell: ({ value }) => value && `${value} days`,
  },
  {
    field: 'escFall',
    label: 'Fall Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escFall'),
    renderCell: ({ value }) => RenderEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escLB',
    label: 'Leaving Bed Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escLB'),
    renderCell: ({ value }) => RenderEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escOOC',
    label: 'Out Of Chair Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escOOC'),
    renderCell: ({ value }) => RenderEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escHidden',
    label: 'Hidden Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escHidden'),
    renderCell: ({ value }) => RenderEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escUrgent',
    label: 'Urgent Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escUrgent'),
    renderCell: ({ value }) => RenderEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escWarning',
    label: 'Warning Escalation Order',
    width: 200,
    editType: 'text-group',
    hideOnAdd: true,
    initialValue: {},
    tooltip: returnEscTooltipLabel('escWarning'),
    renderCell: ({ value }) => RenderEscalationValues(value),
    schema: escNumberSchema,
    properties: ['assigned', 'unit', 'building'],
  },
  {
    field: 'escUnit',
    label: 'Listen To Other Unit Escalations',
    width: 225,
    editType: 'boolean',
    hideOnAdd: true,
    tooltip: returnEscTooltipLabel('escUnit'),
  },
  {
    field: 'disableAugiAlertInterval',
    label: 'Disable AUGi Alert Interval',
    width: 225,
    editType: 'text',
    hideOnAdd: true,
    schema: numberSchema,
    renderCell: ({ value }) => (value ? `${value} hours` : 'Off'),
    tooltip:
      'Number of hours between mobile app reminder alerts for disabled AUGis. 0 or blank to turn off reminders.',
  },
];

const UnitsTable = () => {
  const queryClient = useQueryClient();

  const { isLoading: orgsLoading, isError: orgsError } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const { isLoading: floorsLoading, isError: floorsError } = useQuery({
    queryKey: ['floors'],
    queryFn: getFloors,
  });

  const {
    isLoading: unitsLoading,
    isError: unitsError,
    data: units,
  } = useQuery({
    queryKey: ['units'],
    queryFn: getUnits,
  });

  const handleEditSubmit: OnSubmitFormModal<FieldTypes> = useCallback(
    async ({
      org,
      buildingFloor,
      name,
      displayName,
      privOpts,
      curtainFeature,
      wThresh,
      rThresh,
      nThresh,
      wnThresh,
      nStart,
      nEnd,
      disableAugi,
      imageExp,
      eventExp,
      audible,
      hide,
      escFall,
      escLB,
      escOOC,
      escUnit,
      escHidden,
      escUrgent,
      escWarning,
      disableAugiAlertInterval,
      nightLow,
    }) => {
      try {
        const convertToNumber = (value: string | null) =>
          value !== null ? Number(value) : null;

        const data: UnitUpdate = {
          floor: `${org}-${buildingFloor}`,
          name,
          displayName,
          privOpts: privOpts?.map((o) => +o),
          privOff: !curtainFeature,
          wThresh: convertToNumber(wThresh),
          rThresh: convertToNumber(rThresh),
          nThresh: convertToNumber(nThresh),
          wnThresh: convertToNumber(wnThresh),
          nStart: convertToNumber(nStart),
          nEnd: convertToNumber(nEnd),
          disableAugi,
          imageExp: convertToNumber(imageExp),
          eventExp: convertToNumber(eventExp),
          audible,
          nightLow,
          hide,
          escFall: {
            assigned: convertToNumber(escFall?.assigned),
            unit: convertToNumber(escFall?.unit),
            building: convertToNumber(escFall?.building),
          },
          escLB: {
            assigned: convertToNumber(escLB?.assigned),
            unit: convertToNumber(escLB?.unit),
            building: convertToNumber(escLB?.building),
          },
          escOOC: {
            assigned: convertToNumber(escOOC?.assigned),
            unit: convertToNumber(escOOC?.unit),
            building: convertToNumber(escOOC?.building),
          },
          escHidden: {
            assigned: convertToNumber(escHidden?.assigned),
            unit: convertToNumber(escHidden?.unit),
            building: convertToNumber(escHidden?.building),
          },
          escUrgent: {
            assigned: convertToNumber(escUrgent?.assigned),
            unit: convertToNumber(escUrgent?.unit),
            building: convertToNumber(escUrgent?.building),
          },
          escWarning: {
            assigned: convertToNumber(escWarning?.assigned),
            unit: convertToNumber(escWarning?.unit),
            building: convertToNumber(escWarning?.building),
          },
          escUnit,
          disableAugiAlertInterval: convertToNumber(disableAugiAlertInterval),
        };

        await putUnit(data);

        await queryClient.invalidateQueries({
          queryKey: ['units'],
        });

        toast.success(`Successfully updated unit: ${name || displayName}`);
      } catch (error) {
        toast.error(`Error updating unit${error ? `: ${error}` : ''}`);
      }
    },
    [],
  );

  const handleAddSubmit: OnSubmitFormModal<FieldTypes> = useCallback(
    async ({ org, buildingFloor, name, displayName }) => {
      try {
        const data = {
          floor: `${org}-${buildingFloor}`,
          name,
          displayName: displayName || undefined,
        };

        await postUnit(data);

        await queryClient.invalidateQueries({
          queryKey: ['units'],
        });

        toast.success(`Successfully added unit: ${name || displayName}`);
      } catch (error) {
        toast.error(`Error adding unit${error ? `: ${error}` : ''}`);
      }
    },
    [],
  );

  const data = useMemo(
    () =>
      (units || []).map(
        ({
          floor,
          privOpts,
          displayName,
          wThresh,
          rThresh,
          nThresh,
          wnThresh,
          nStart,
          nEnd,
          imageExp,
          eventExp,
          privOff,
          escFall,
          escLB,
          escOOC,
          escHidden,
          escUrgent,
          escWarning,
          disableAugiAlertInterval,
          ...remainingFields
        }) => ({
          org: extractOrgFromFloorId(floor),
          buildingFloor: extractBuildingFloorFromFloorId(floor),
          privOpts: privOpts?.map((o) => o.toString()) || [],
          displayName: displayName || '',
          wThresh: wThresh?.toString() || '',
          rThresh: rThresh?.toString() || '',
          nThresh: nThresh?.toString() || '',
          wnThresh: wnThresh?.toString() || '',
          nStart: nStart?.toString() || '',
          nEnd: nEnd?.toString() || '',
          imageExp: imageExp?.toString() || '',
          eventExp: eventExp?.toString() || '',
          escFall: escFall || {},
          escLB: escLB || {},
          escOOC: escOOC || {},
          escHidden: escHidden || {},
          escUrgent: escUrgent || {},
          escWarning: escWarning || {},
          curtainFeature: !privOff,
          disableAugiAlertInterval: disableAugiAlertInterval?.toString() || '',
          ...remainingFields,
        }),
      ),
    [units],
  );

  return (
    <TableBase<FieldTypes>
      itemName='Unit'
      fields={fields}
      data={data}
      getRowId={({ org, buildingFloor, name }) =>
        `${org}-${buildingFloor}-${name}`
      }
      defaultSort='org'
      loading={unitsLoading}
      modalLoading={orgsLoading || floorsLoading}
      modalError={orgsError || floorsError}
      error={unitsError}
      onEditSubmit={handleEditSubmit}
      onAddSubmit={handleAddSubmit}
    />
  );
};

export default UnitsTable;

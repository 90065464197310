import { StaffEventRes } from '../../../../types';
import { Api } from '../../../utility/client';

export const getStaffEvents = async (
  room: string,
  start: string,
  end: string,
) => {
  try {
    const url = `/eventReview/v1/events/staff/${room}/${start}/${end}`;
    const res = await Api.get<StaffEventRes[]>(url);

    return res?.data;
  } catch (error) {
    console.error('error fetching staff events');
    throw error;
  }
};

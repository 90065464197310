import { SxProps, Theme, Toolbar } from '@mui/material';
import { noop } from 'lodash';

import RoomMenu from './RoomCard/RoomMenu';
import { AudibleMessageIndicator } from './components/AudibleMessageIndicator';

import { TeleRoom } from '../../../types';
import FallRiskPill from '../shared/FallRiskPill';

type Props = {
  room: TeleRoom;
  playingAudibleMessage: boolean;
  onPrivacyModeChange: () => void;
  onToggleZoneUpdate?: () => void;
  privacy: boolean;
  size?: 'small' | 'large';
  sx?: SxProps<Theme>;
  showMenu?: boolean;
};

const RoomTopBar = ({
  room,
  onPrivacyModeChange,
  onToggleZoneUpdate,
  privacy,
  size = 'small',
  sx = {},
  playingAudibleMessage,
  showMenu = false,
}: Props) => {
  const { name, fallRiskLevel = 'off' } = room;

  return (
    <Toolbar
      disableGutters
      sx={{
        position: 'absolute',
        zIndex: 100,
        opacity: 0.8,
        '&.MuiToolbar-root': {
          minHeight: 0,
        },
        ...sx,
      }}
    >
      <FallRiskPill
        label={`Room ${name}`}
        fallRisk={fallRiskLevel}
        sx={{ mr: 1 }}
        size={size === 'large' ? 'large' : 'medium'}
        bold
      />

      {showMenu && (
        <RoomMenu
          roomId={room.mainId}
          baseId={room.baseId}
          roomName={name}
          fallRiskLevel={fallRiskLevel}
          onPrivacyModeChange={onPrivacyModeChange}
          onToggleZoneUpdate={onToggleZoneUpdate || noop}
          offline={Boolean(room.offline)}
          privacy={privacy}
          augiDisabled={room.disable || false}
          unitId={room.unit}
          isDemoSession={room.isDemoSession}
          isDemoAction={room.isDemoAction}
        />
      )}

      {playingAudibleMessage && <AudibleMessageIndicator size={size} />}
    </Toolbar>
  );
};

export default RoomTopBar;

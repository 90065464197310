import { Identify, identify, init, track } from '@amplitude/analytics-browser';

import { User } from '../../../types';

export const configAmp = (user: User) => {
  init(
    import.meta.env.VITE_API_ENV === 'production'
      ? import.meta.env.VITE_VC_AMPLITUDE_KEY
      : import.meta.env.VITE_VC_AMPLITUDE_KEY_DEV,
    user.mainId,
    { defaultTracking: true },
  );

  const identifyObj = new Identify();

  identifyObj.set('First Name', user.firstName);
  identifyObj.set('Last Name', user.lastName);
  identifyObj.set('Organization', user.org);
  identifyObj.set('Role', user.roleId);
  if (user.unit) identifyObj.set('Unit', user.unit);
  if (user.department) identifyObj.set('Department', user.department);

  identify(identifyObj);
};

export const sendAmpEvent = (
  eventType: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventProperties: Record<string, any> = {},
) => {
  track(eventType, eventProperties);
};

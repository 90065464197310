import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { Unit } from '../../../../types';
import { getUnits } from '../data-access/getUnits';

type Props = Omit<
  UseQueryOptions<Unit[], Error, Unit[], string[]>,
  'queryKey' | 'queryFn'
>;

export const useUnits = (props: Props = {}) =>
  useQuery({
    ...props,
    queryKey: ['units'],
    queryFn: getUnits,
  });

import { useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';

import { Room } from '@inspiren-monorepo/shared-types';

import { useCurrentUser } from '../../../HOC/CurrentUserContextProvider';
import { generateNotifSound } from '../helpers/generateNotifSound';

export const useNotifSound = (room: Room) => {
  const { user } = useCurrentUser();
  const muteAlerts = useMemo(() => user?.mute, [user]);

  useEffect(() => {
    if (!isEmpty(room.alert) && !room.offline && !muteAlerts && !room.disable) {
      const soundPlaySession = sessionStorage.getItem(
        `soundPlaySession-${room.mainId}-${room.notificationId}`,
      );

      if (!soundPlaySession) {
        const audio = new Audio(generateNotifSound(room.alert || ''));
        audio.play();

        sessionStorage.setItem(
          `soundPlaySession-${room.mainId}-${room.notificationId}`,
          'true',
        );
      }
    } else {
      sessionStorage.removeItem(
        `soundPlaySession-${room.mainId}-${room.notificationId}`,
      );
    }
  }, [room]);
};

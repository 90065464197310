import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Control, useWatch } from 'react-hook-form';

import { getVirtualScenarios } from '../../data-access/getVirtualScenarios';
import ControlledCheckbox from '../ControlledCheckbox';
import ControlledDropdown from '../ControlledDropdown';

interface Props {
  control: Control;
}

const SelectVirtualScenario = ({ control }: Props) => {
  const { isLoading, data: scenarios } = useQuery({
    queryKey: ['virtual-scenarios'],
    queryFn: getVirtualScenarios,
  });

  const isVirtualChecked = useWatch({ name: 'virtual', control });

  return (
    <Accordion
      expanded={isVirtualChecked}
      disableGutters
      elevation={0}
      sx={{
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        sx={{
          p: 0,
          '& .MuiAccordionSummary-content.Mui-expanded': {
            mb: 0,
          },
        }}
      >
        <ControlledCheckbox id='virtual' label='Virtual' control={control} />
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <ControlledDropdown
          id='scenario'
          label='Scenario'
          items={(scenarios || []).map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
          control={control}
          disabled={isLoading}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default SelectVirtualScenario;

import { useEffect, useState } from 'react';
import { skipToken, useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';

import { AudibleMessage } from '../../../../types';
import { Api } from '../../../utility/client';

type AudibleMessages = Record<string, AudibleMessage[]>;

interface AudibleMessageState {
  loading: boolean;
  languages: string[];
  audibleMessages: AudibleMessages;
}

export const useAudibleMessages = (org: string | null) => {
  const [state, setState] = useState<AudibleMessageState>({
    loading: true,
    languages: [],
    audibleMessages: {},
  });

  const {
    isLoading,
    data: audibleMessages,
    error,
  } = useQuery({
    queryKey: ['audibleMessages', org],
    queryFn: org
      ? async () =>
          Api.get<AudibleMessages>(`/v1/audible/${org}`).then((res) => res.data)
      : skipToken,
  });

  useEffect(() => {
    if (isNil(org)) {
      setState({ loading: false, languages: [], audibleMessages: {} });
      return;
    }

    if (!isLoading) {
      setState({
        loading: false,
        audibleMessages: audibleMessages || {},
        languages: Object.keys(audibleMessages || {}).sort(),
      });
    }
  }, [org, isLoading, audibleMessages]);

  return {
    ...state,
    error,
  };
};

import { AlertType } from '@inspiren-monorepo/shared-types';

import alarming from '../sounds/alarming.mp3';
import security_breach_alarm from '../sounds/security_breach_alarm.mp3';
import standard_alarm from '../sounds/standard_alarm.mp3';

export const generateNotifSound = (alertType: AlertType) => {
  switch (alertType) {
    case 'fall':
      return security_breach_alarm;
    case 'out_of_bed':
      return alarming;
    case 'hidden':
      return alarming;
    case 'out_of_chair':
      return alarming;
    case 'moving_in_bed':
      return standard_alarm;
    case 'urgent':
      return security_breach_alarm;
    case 'early_warning':
      return alarming;
    default:
      return alarming;
  }
};

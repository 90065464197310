import { useCallback, useMemo } from 'react';
import {
  Box,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  Typography,
} from '@mui/material';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { capitalize } from 'lodash';

import { AudibleMessage } from '../../../../../types';

interface Props {
  roomName: string;
  lang: string;
  languages: string[];
  volume: number;
  audibleMessages: Record<string, AudibleMessage[]>;
  onVolumeChange: (volume: number) => void;
  onLangChange: (lang: string) => void;
  onMessageSelect: (message: AudibleMessage) => void;
}

export const volumeOptions = [
  { value: 15, label: 'Low' },
  { value: 30, label: 'Medium' },
  { value: 100, label: 'High' },
];

export const RoomAudibleMessageModalForm = ({
  roomName,
  audibleMessages,
  lang,
  languages,
  volume,
  onVolumeChange,
  onLangChange,
  onMessageSelect,
}: Props) => {
  const volumeIndex = useMemo(
    () => volumeOptions.findIndex((option) => option.value === volume),
    [volume],
  );

  const handleLangChange = useCallback(
    (event: SelectChangeEvent) => {
      onLangChange(event.target.value);
    },
    [onLangChange],
  );

  const handleVolumeChange = useCallback(
    (_event: Event, newValue: number | number[]) => {
      onVolumeChange(volumeOptions[newValue as number].value);
    },
    [onVolumeChange],
  );

  return (
    <>
      <Typography sx={{ pr: 3 }}>
        Which message would you like to send to <b>Room {roomName}</b>
      </Typography>
      <List
        dense
        sx={{
          flex: 1,
          overflow: 'auto',
          mb: 1,
        }}
      >
        {audibleMessages[lang]?.map((message) => (
          <ListItem disablePadding key={message.id}>
            <ListItemButton
              onClick={() => onMessageSelect(message)}
              sx={{ py: 1, borderRadius: 1 }}
            >
              <ListItemText
                primary={message.title}
                primaryTypographyProps={{ sx: { fontSize: '1rem' } }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Stack justifyContent='space-between' alignItems='center' direction='row'>
        <Box sx={{ flex: 3, display: 'flex', alignItems: 'center' }}>
          <VolumeDownIcon />
          <Slider
            aria-label='Volume'
            value={volumeIndex}
            onChange={handleVolumeChange}
            sx={{ flex: 1, mx: 1 }}
            min={0}
            max={volumeOptions.length - 1}
            step={1}
            valueLabelDisplay='auto'
            valueLabelFormat={(value) => volumeOptions[value].label}
          />
          <VolumeUpIcon />
        </Box>
        <FormControl size='small' sx={{ flex: 2, pl: 1 }}>
          <Select
            onChange={handleLangChange}
            value={lang}
            MenuProps={{
              sx: {
                zIndex: 1501,
              },
            }}
          >
            {languages.map((l) => (
              <MenuItem value={l} key={l}>
                {capitalize(l)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </>
  );
};

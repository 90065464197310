import { useEffect, useState } from 'react';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';

import csvCellMappers from '../utils/csvCellMappers';

type Props = {
  skipValidation: boolean;
  valueOptions: (string | { value: string; label: string })[];
  params: GridRenderEditCellParams<string>;
};

export const useFieldValidator = ({
  skipValidation,
  valueOptions,
  params,
}: Props) => {
  const apiRef = useGridApiContext();
  const [invalidValueError, setInvalidValueError] = useState(false);

  const { id, field, value } = params;

  useEffect(() => {
    if (skipValidation) {
      setInvalidValueError(false);
      return;
    }

    const allowedValues = [
      '',
      ...valueOptions.map((e) => (typeof e === 'string' ? e : e.value)),
    ];

    const mapper = csvCellMappers.StringPredefined(allowedValues, true);
    const newValue = mapper(value);

    if (newValue !== value) {
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newValue,
        debounceMs: 0,
      });
    }

    const isValidValue = newValue || allowedValues.includes(newValue);
    setInvalidValueError(!isValidValue);
  }, [valueOptions, value, skipValidation]);

  return { invalidValueError };
};

import { skipToken, useQuery } from '@tanstack/react-query';

import { getAdminOrgSettings } from '../data-access/getAdminOrgSettings';

interface Params {
  org?: string;
  enabled?: boolean;
}

export const useAdminOrgSettings = ({ org, enabled = true }: Params) =>
  useQuery({
    queryKey: ['org', org, 'settings'],
    queryFn: org && enabled ? async () => getAdminOrgSettings(org) : skipToken,
  });

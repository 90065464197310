import { LoadingButton } from '@mui/lab';

import { useVerificationCodeCooldown } from '@inspiren-monorepo/shared-react';

import { resendVerificationCode } from './data-access/resendVerificationCode';

const ResendVerificationCode = () => {
  const { isPending, requestVerificationCodeResend, resendText, cooldown } =
    useVerificationCodeCooldown({
      resendVerificationCodeMutationFn: resendVerificationCode,
    });

  return (
    <LoadingButton
      loading={isPending}
      onClick={() => requestVerificationCodeResend()}
      disabled={cooldown > 0}
      variant='outlined'
    >
      {resendText}
    </LoadingButton>
  );
};

export default ResendVerificationCode;

import { Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { RoomRegion } from '@inspiren-monorepo/hilq/api-contracts';
import { convertToRelative, ZoneUpdate } from '@inspiren-monorepo/shared-react';

import { defaultAspectRatioNumbers } from '../../../rules';
import { getRoomRegions } from '../data-access/getRoomRegions';
import { updateRoomRegions } from '../data-access/updateRoomRegions';

const { width, height } = defaultAspectRatioNumbers;

interface ZoneUpdateWrapperProps {
  imagesIsLoading: boolean;
  lastSuccessfulImage?: string;
  roomId: string;
  roomName: string;
  toggleZoneUpdate: () => void;
}

export const ZoneUpdateWrapper = ({
  lastSuccessfulImage,
  imagesIsLoading,
  roomId,
  roomName,
  toggleZoneUpdate,
}: ZoneUpdateWrapperProps) => {
  const queryClient = useQueryClient();

  const { mutate, isPending: isSavePending } = useMutation({
    mutationFn: updateRoomRegions,
    onSuccess: () => {
      toast.success(
        'Room Zones Updated. Please wait for the image to refresh.',
      );

      toggleZoneUpdate();
      queryClient.invalidateQueries({ queryKey: ['regionConfig'] });
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  const saveRegions = (allRegions: RoomRegion[]) => {
    mutate({
      roomId,
      regions: convertToRelative(allRegions, width, height),
    });
  };

  const regionConfigParams = {
    queryKey: ['regionConfig', roomId],
    queryFn: () => getRoomRegions(roomId),
  };

  return (
    <Stack>
      <ZoneUpdate
        direction='column-reverse'
        imagesIsLoading={imagesIsLoading}
        lastSuccessfulImage={lastSuccessfulImage}
        showExitIgnore
        showControls
        handleCancel={toggleZoneUpdate}
        isSavePending={isSavePending}
        regionConfigParams={regionConfigParams}
        roomName={roomName}
        saveRegions={saveRegions}
        width={width}
        height={height}
      />
    </Stack>
  );
};

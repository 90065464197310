import { GridRowId } from '@mui/x-data-grid';

import { Importable } from '../../ImportTable/types/importable';
import { FieldTypes } from '../types/BasestationFieldTypes';

const importProperAssignmentValidator = (
  _uniqueId: GridRowId,
  params: FieldTypes,
  field: keyof FieldTypes,
  _rows: Importable<FieldTypes>[],
) => {
  if (params[field]) {
    return null;
  }

  const assignmentFields: (keyof FieldTypes)[] = [
    'organization',
    'buildingFloorUnit',
    'room',
  ];

  const set = new Set(assignmentFields.map((e) => Boolean(params[e])));

  if (set.size !== 1) {
    return 'You have to assign organization, buildingFloorUnit, room or un-assign';
  }

  return null;
};

export default importProperAssignmentValidator;

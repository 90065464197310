import { Stack, SxProps, Theme, Toolbar } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { FALL_RISK_OPTIONS } from '@inspiren-monorepo/shared-types';
import {
  getStaffInRoomStatus,
  parseRoomId,
} from '@inspiren-monorepo/util-rooms';

import RoomStatus from './components/RoomStatus';
import SendAlertMenu from './components/SendAlertMenu';
import { useBeaconAssignments } from './hooks/useBeaconAssignments';

import { AlertType, TeleRoom } from '../../../../../types';
import { sendAmpEvent } from '../../../../utility/amplitude';
import FallRiskPill from '../../../shared/FallRiskPill';

type Props = {
  room: TeleRoom;
  onMenuClose?: () => void;
  resolve?: () => void;
  resolveNotifLoading: boolean;
  setTooltip: (state: boolean) => void;
  size?: 'small' | 'large';
  sx?: SxProps<Theme>;
  alert: AlertType;
  offline?: boolean;
  showFallRisk?: boolean;
  disabled?: boolean;
  fallRiskPillTestID?: string;
};

const RoomBottomBar = ({
  room,
  setTooltip,
  onMenuClose,
  resolve,
  size = 'small',
  sx = {},
  alert,
  offline,
  showFallRisk = true,
  resolveNotifLoading,
  disabled,
  fallRiskPillTestID,
}: Props) => {
  const { fallRiskLevel = 'off' } = room;
  const { org } = parseRoomId(room.mainId);

  const { data: beaconAssignments = [] } = useBeaconAssignments({ org });

  const staffInRoomStatus = getStaffInRoomStatus(room, beaconAssignments);

  return (
    <Toolbar
      disableGutters
      sx={{
        ...sx,
        position: 'absolute',
        zIndex: 100,
        bottom: 0,
        width: '100%',
        flexWrap: 'wrap-reverse',
        rowGap: 1,
        opacity: 0.8,
        '&.MuiToolbar-root': {
          minHeight: 0,
        },
      }}
    >
      <Stack direction='row' flex='1'>
        <Stack direction='column' spacing={1.5} mr='auto'>
          {showFallRisk && (
            <FallRiskPill
              label={FALL_RISK_OPTIONS[fallRiskLevel].description}
              fallRisk={fallRiskLevel}
              size='small'
              testID={fallRiskPillTestID}
            />
          )}

          {!offline && staffInRoomStatus && (
            <RoomStatus
              status={staffInRoomStatus}
              sx={{
                px: size === 'large' ? 4 : 2,
                py: size === 'large' ? 1 : 0.5,
              }}
            />
          )}
        </Stack>

        {!offline && !alert && !disabled && (
          <SendAlertMenu
            roomId={room.mainId}
            size={size}
            onMenuClose={onMenuClose}
          />
        )}

        {alert && (
          <LoadingButton
            size={size === 'large' ? 'medium' : 'small'}
            variant='contained'
            color='secondary'
            onClick={() => {
              resolve?.();
              sendAmpEvent('Resolved Notif', { room: room.mainId, alert });
            }}
            onMouseOver={() => setTooltip(false)}
            onMouseLeave={() => setTooltip(true)}
            loading={resolveNotifLoading}
          >
            Resolve
          </LoadingButton>
        )}
      </Stack>
    </Toolbar>
  );
};

export default RoomBottomBar;

import parsePhoneNumber, { NumberFormat, AsYouType } from 'libphonenumber-js';

import { ControlledError } from '@inspiren-monorepo/shared-types';

const US_REGION_CODE = 'US';

export const isValidMobileNumber = (number: string) => {
  try {
    const phoneNumber = parsePhoneNumber(number, US_REGION_CODE);

    return phoneNumber?.isValid();
  } catch {
    return false;
  }
};

export enum PhoneNumberFormat {
  E164,
  INTERNATIONAL,
  NATIONAL,
  US_NATIONAL_OTHER_INTERNATIONAL,
}

const formatMapper: Omit<
  Record<PhoneNumberFormat, NumberFormat>,
  PhoneNumberFormat.US_NATIONAL_OTHER_INTERNATIONAL
> = {
  [PhoneNumberFormat.E164]: 'E.164',
  [PhoneNumberFormat.INTERNATIONAL]: 'INTERNATIONAL',
  [PhoneNumberFormat.NATIONAL]: 'NATIONAL',
};

export const formatPhoneNumber = (
  number: string,
  format: PhoneNumberFormat,
) => {
  const phoneNumber = parsePhoneNumber(number, US_REGION_CODE);

  if (!phoneNumber?.isValid()) {
    throw new ControlledError('Invalid phone number');
  }

  let numberFormat: NumberFormat;

  if (format !== PhoneNumberFormat.US_NATIONAL_OTHER_INTERNATIONAL) {
    numberFormat = formatMapper[format];
  } else if (phoneNumber.country === US_REGION_CODE) {
    numberFormat = 'NATIONAL';
  } else {
    numberFormat = 'INTERNATIONAL';
  }

  return phoneNumber.format(numberFormat);
};

export const formatPhoneNumberSafe = (
  number: string,
  format: PhoneNumberFormat,
) => {
  try {
    return formatPhoneNumber(number, format);
  } catch {
    return number;
  }
};

const charsToSkip = ['(', ')'];
const charsToSkipRegExp = new RegExp(`[${charsToSkip.join('')}]`, 'g');

export const formatAsYouType = (input: string) => {
  const asYouType = new AsYouType({
    defaultCallingCode: '1',
    defaultCountry: US_REGION_CODE,
  });

  const formatted = asYouType.input(input);
  // we remove () chars and replace - with space for better UX experience
  return formatted.replace(charsToSkipRegExp, '').replace('-', ' ');
};

import { Box, InputLabel, Typography } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Control, Controller } from 'react-hook-form';

interface Props {
  control: Control<{ confirmationCode: string }>;
  onComplete: (value: string, event: any) => void;
}

const ControlledVerificationCodeInput = ({ control, onComplete }: Props) => (
  <Controller
    control={control}
    name='confirmationCode'
    rules={{
      required: 'Please enter a 6 digit PIN',
      pattern: {
        value: /^\d{6}$/,
        message: 'Please enter a 6 digit PIN',
      },
    }}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <Box mt={2}>
        <InputLabel htmlFor='verificationCode' sx={{ mb: 1 }}>
          Enter the confirmation code
        </InputLabel>

        <MuiOtpInput
          id='verificationCode'
          length={6}
          onChange={onChange}
          value={value}
          autoFocus
          onComplete={(newValue) => onComplete(newValue, onChange)}
          width={400}
          mb={2}
        />

        {error && <Typography color='error'>{error.message}</Typography>}
      </Box>
    )}
  />
);

export default ControlledVerificationCodeInput;

import React from 'react';
import { Button } from '@mui/material';
import { Menu } from '@mui/icons-material';

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const DrawerToggleButton = ({ onClick }: Props) => (
  <Button
    startIcon={<Menu />}
    onClick={onClick}
    sx={{ display: { sm: 'none' }, mb: 2 }}
  >
    View Tables
  </Button>
);

export default DrawerToggleButton;

import { Card, Checkbox, Typography } from '@mui/material';
import { Circle, CircleOutlined } from '@mui/icons-material';

import { Room } from '../../../../types';
import { extractFloorNumberFromRoomId } from '../../../utility/helpers/id';

type Props = {
  data: Room;
  selected: boolean;
  onClick?: () => void;
};

const RoomSelectCard = ({ data, selected, onClick }: Props) => {
  const { name } = data;

  const floor = extractFloorNumberFromRoomId(data.mainId);

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: 'grey.100',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        p: 1.5,
        mr: 1.5,
        mb: 1.5,
        width: 300,
      }}
      onClick={() => onClick?.()}
    >
      <Checkbox
        checked={selected}
        sx={{ mr: 1 }}
        icon={<CircleOutlined />}
        checkedIcon={<Circle />}
      />
      <Typography sx={{ fontWeight: 600, mr: 2, userSelect: 'none' }}>
        Room {name}
      </Typography>
      {floor && (
        <Typography sx={{ mr: 1, userSelect: 'none' }}>
          Floor {floor}
        </Typography>
      )}
    </Card>
  );
};

export default RoomSelectCard;

import { useMemo } from 'react';
import { Stack } from '@mui/material';

import { fields } from './EditOrganizationSettingsModal';

import { Organization, Role } from '../../../../../../types';
import { ShowPageField } from '../../../components/ShowPageField';
import { useTrainingVideos } from '../../../hooks/useTrainingVideo';

interface Props {
  org?: Organization;
  roles?: Role[];
  loading: boolean;
}

export const OrgSettingsPanel = ({ org, roles, loading }: Props) => {
  const { data: trainingVideos, isLoading: trainingVideosLoading } =
    useTrainingVideos();

  const trainingVideoLabel = useMemo(
    () =>
      trainingVideos?.videos.find(
        (video) => video.url === org?.defaultTrainingVideoUrlMobile,
      )?.path,
    [org?.defaultTrainingVideoUrlMobile, trainingVideos?.videos],
  );

  const roleLabel = useMemo(
    () => roles?.find((role) => role.id === org?.defaultRoleId)?.displayName,
    [org?.defaultRoleId, roles],
  );

  return (
    <Stack gap={2} my={2}>
      <ShowPageField
        label='Display Name'
        value={org?.name || ''}
        loading={loading}
        data-testid='orgDisplayName'
      />
      <ShowPageField
        label='Domains'
        value={org?.domains?.join(', ') || ''}
        loading={loading}
        tooltip={fields.domains.tooltip}
      />
      <ShowPageField
        label='IP Allowlist'
        value={org?.ipAllowlist?.join(', ') || ''}
        loading={loading}
        data-testid='orgIpAllowlist'
      />
      <ShowPageField
        label='Default Sort'
        value={org?.defaultSort}
        loading={loading}
      />
      <ShowPageField
        label='Default Training Video (Mobile)'
        value={trainingVideoLabel || ''}
        loading={loading || trainingVideosLoading}
      />
      <ShowPageField
        label='Auth0 Tenant Url'
        value={org?.auth0TenantUrl || ''}
        loading={loading}
        tooltip={fields.auth0TenantUrl.tooltip}
      />
      <ShowPageField
        label='Auth0 Web Client Id'
        value={org?.auth0WebClientId || ''}
        loading={loading}
        tooltip={fields.auth0WebClientId.tooltip}
      />
      <ShowPageField
        label='Auth0 Mobile Client Id'
        value={org?.auth0MobileClientId || ''}
        loading={loading}
        tooltip={fields.auth0MobileClientId.tooltip}
      />
      <ShowPageField
        label='Auth0 Enterprise Connections'
        value={org?.auth0EnterpriseConnections?.join(', ') || ''}
        loading={loading}
        tooltip={fields.auth0EnterpriseConnections.tooltip}
      />
      <ShowPageField
        label='Auth0 Db Connection'
        value={org?.auth0DbConnection || ''}
        loading={loading}
        tooltip={fields.auth0DbConnection.tooltip}
      />
      <ShowPageField
        label='Default Role Id'
        value={roleLabel || ''}
        loading={loading}
        tooltip={fields.defaultRoleId.tooltip}
      />
      <ShowPageField
        label='Four Digit Validation'
        value={org?.fourDigitValidation ? 'Yes' : 'No'}
        loading={loading}
        tooltip={fields.fourDigitValidation.tooltip}
        data-testid='orgFourDigitValidation'
      />
      <ShowPageField
        label='Default Level Access'
        value={org?.defaultLevelAccess}
        loading={loading}
        tooltip={fields.defaultLevelAccess.tooltip}
        data-testid='defaultLevelAccess'
      />
      <ShowPageField
        label='Default Unit Id'
        value={org?.defaultUnitId}
        loading={loading}
        tooltip={fields.defaultUnitId.tooltip}
        data-testid='defaultUnitId'
      />
      <ShowPageField
        label='Label All Activity'
        value={org?.labelAllNotifs ? 'Yes' : 'No'}
        loading={loading}
        tooltip={fields.labelAllNotifs.tooltip}
        data-testid='orgLabelAllNotifs'
      />
    </Stack>
  );
};

import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { getCustomUnit } from '../data-access/getCustomUnit';

export const useRooms = (unit: string) => {
  const state = useQuery({
    queryKey: ['customUnits', unit],
    queryFn: () => getCustomUnit(unit),
    enabled: !isEmpty(unit),
    refetchInterval: 3000,
  });

  return state;
};

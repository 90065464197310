import { memo, useMemo } from 'react';
import { Toolbar } from '@mui/material';

import { RoomAudibleMessagesModalButton } from './RoomAudibleMessagesModalButton';
import { useUnitForRoom } from './hooks/useUnitForRoom';

import { TeleRoom } from '../../../types';

interface Props {
  room: TeleRoom;
  disabled?: boolean;
  privacy?: boolean;
  offline?: boolean;
}

export const RoomSideBar = memo(
  ({ room, disabled, privacy, offline }: Props) => {
    const unit = useUnitForRoom(room.mainId);

    const hideAudibleMessageButton = useMemo(
      () => !unit?.audible || !room?.audible || disabled || privacy || offline,
      [unit, room, disabled, privacy, offline],
    );

    return (
      <Toolbar
        disableGutters
        sx={{
          position: 'absolute',
          zIndex: 99,
          top: '60px',
          right: 0,
          width: '48px',
          height: 'calc(100% - 120px)',
          opacity: 0.8,
        }}
      >
        {!hideAudibleMessageButton && (
          <RoomAudibleMessagesModalButton
            roomId={room.mainId}
            roomName={room.name}
            baseId={room.baseId}
          />
        )}
      </Toolbar>
    );
  },
);

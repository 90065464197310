import { useQuery } from '@tanstack/react-query';
import { Control } from 'react-hook-form';

import { getBuildings } from '../../data-access/getBuildings';
import { ControlledAutocomplete } from '../ControlledAutocomplete';

type Props = {
  control: Control;
  type: 'add' | 'edit';
  tooltip?: string;
};

const SelectBuilding = ({ control, type, tooltip }: Props) => {
  const { isLoading: buildingsLoading, data: buildings } = useQuery({
    queryKey: ['buildings'],
    queryFn: getBuildings,
  });

  return (
    <ControlledAutocomplete
      id='building'
      label='Building'
      items={(buildings || []).map(({ org, id }) => `${org}-${id}`).sort()}
      control={control}
      disabled={type === 'edit' || buildingsLoading}
      tooltip={tooltip}
    />
  );
};

export default SelectBuilding;

import { EventReviewRoom } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Organization, Unit } from '../../../../types';

export function updateUrlParams(
  selectedOrg: Organization | null,
  selectedUnit: Unit | null,
  selectedRoom: EventReviewRoom | null,
  startDate: Date | null,
  endDate: Date | null,
) {
  if (!selectedOrg || !selectedUnit || !selectedRoom || !startDate || !endDate)
    return;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  urlParams.set('org', selectedOrg.id);
  urlParams.set('unit', `${selectedUnit.floor}-${selectedUnit.name}`);
  urlParams.set('room', selectedRoom.name);
  urlParams.set('start', startDate.toISOString());
  urlParams.set('end', endDate.toISOString());
  window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
}

import { useRef } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';

import { RoomRegion } from '@inspiren-monorepo/hilq/api-contracts';

import { Canvas } from './Canvas';

interface Props {
  roomName: string;
  isLoading: boolean;
  handleDrawingPointChange: (e: React.MouseEvent<HTMLElement>) => void;
  chairPoints: RoomRegion[];
  bedPoints: RoomRegion[];
  bedEdgePoints: RoomRegion[];
  multiRegions: RoomRegion[][];
  handleMouseMove: (e: React.MouseEvent<HTMLElement>) => void;
  handleMouseUp: (e: React.MouseEvent<HTMLElement>) => void;
  lastSuccessfulImage?: string;
  showExitIgnore?: boolean;
  width: number;
  height: number;
}

export const ZoneUpdateImage = ({
  roomName,
  isLoading,
  handleDrawingPointChange,
  chairPoints,
  bedEdgePoints,
  bedPoints,
  multiRegions,
  handleMouseMove,
  handleMouseUp,
  lastSuccessfulImage,
  showExitIgnore,
  width,
  height,
}: Props) => {
  const canvasRef = useRef(null);

  return (
    <Box>
      <Box
        sx={{
          width: `${width}px`,
          height: `${height}px`,
          position: 'relative',
          backgroundColor: 'primary.main',
        }}
      >
        <Stack
          direction='row'
          sx={{
            position: 'absolute',
            width: '100%',
            bottom: 30,
            padding: '0px 37px',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <Stack>
            <Typography sx={{ fontSize: 28, color: '#fff' }}>
              Room {roomName}
            </Typography>
          </Stack>{' '}
        </Stack>
        {!lastSuccessfulImage || isLoading ? (
          <CircularProgress
            color='inherit'
            sx={{ top: '50%', left: '50%', position: 'absolute' }}
          />
        ) : (
          <>
            <img
              src={lastSuccessfulImage || ''}
              width='100%'
              height='100%'
              alt=''
            />
            <Canvas
              ref={canvasRef}
              width={width}
              height={height}
              handleDrawingPointChange={handleDrawingPointChange}
              chairPoints={chairPoints}
              bedPoints={bedPoints}
              bedEdgePoints={bedEdgePoints}
              multiRegions={multiRegions}
              showExitIgnore={showExitIgnore}
              handleMouseMove={handleMouseMove}
              handleMouseUp={handleMouseUp}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

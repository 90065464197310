// custom component
// takes a component, and permission { action: string, subject: string }
// gets user from context
// checks if user has permission to view component
// if yes, return component

import { Permissions } from '@inspiren-monorepo/feature-permissions';

import { useCurrentUser } from '../../HOC/CurrentUserContextProvider';

interface Props {
  children: JSX.Element;
  permission: Permissions;
}

export const Can = ({ children, permission }: Props) => {
  const { user } = useCurrentUser();
  const ability = user?.ability;
  const { action, subject } = permission;
  return ability?.can(action, subject) && children;
};

import { Permissions } from '@inspiren-monorepo/feature-permissions';

import { Role } from '../../../../../../types';

export const transformPermissionsToFields = (rolesData: Role[]) => {
  const updatedRoles = rolesData?.map((role: Role) => {
    const updatedRole = {
      ...role,
      ...role.permissions.reduce((acc, permission: Permissions) => {
        const permissionField = `${permission.subject}.${permission.action}`;
        return {
          ...acc,
          [permissionField]: true,
        };
      }, {}),
    };

    return updatedRole;
  });

  const roles = updatedRoles?.map((role: Role) => {
    const updatedRole = {
      ...role,
      maxAssign: role.maxAssign?.toString() || '',
    };

    return updatedRole;
  });

  return roles;
};

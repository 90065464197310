import { Control, Controller } from 'react-hook-form';

import SelectUnit from './SelectUnit';

interface Props {
  control: Control;
  org?: string;
}

const ControlledSelectedUnit = ({ org, control }: Props) => (
  <Controller
    name='unit'
    control={control}
    defaultValue={null}
    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
      <SelectUnit
        org={org}
        error={error}
        onChange={onChange}
        value={value}
        id={name}
        label='Unit'
      />
    )}
  />
);

export default ControlledSelectedUnit;

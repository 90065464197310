import { useCallback, useEffect, useState } from 'react';
import {
  IconButton,
  Popover,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useAuth0 } from '@auth0/auth0-react';
import { v4 as uuidv4 } from 'uuid';

import { RoomAudibleMessageModalConfirm } from './components/RoomAudibleMessageModalConfirm';
import { RoomAudibleMessageModalForm } from './components/RoomAudibleMessageModalForm';
import { RoomAudibleMessageModalResult } from './components/RoomAudibleMessageModalResult';

import { AudibleMessage } from '../../../../types';
import { useUser } from '../../../hooks/useUser';
import { sendAmpEvent } from '../../../utility/amplitude';
import { sendAudibleMessageToBasestation } from '../data-access/sendAudibleMessageToBasestation';
import { useAudibleMessageRequestStatus } from '../hooks/useAudibleMessageRequestStatus';

interface Props {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  roomId: string;
  roomName: string;
  baseId: string;
  languages: string[];
  audibleMessages: Record<string, AudibleMessage[]>;
}

enum AudibleMessagesStep {
  SELECT,
  CONFIRM,
  SENDING_ERROR,
  RESULT,
}

export const RoomAudibleMessageModal = ({
  open,
  anchorEl,
  roomId,
  roomName,
  baseId,
  languages,
  audibleMessages,
  onClose,
}: Props) => {
  const theme = useTheme();

  const [volume, setVolume] = useState<number>(30); // 30 = medium by default
  const [lang, setLang] = useState<string>('english'); // "english" by default

  const [audibleMessage, setAudibleMessage] = useState<AudibleMessage | null>(
    null,
  );

  const [step, setStep] = useState(AudibleMessagesStep.SELECT);
  const [requestId, setRequestId] = useState<string | null>(null);
  const { user: authUser } = useAuth0();
  const { loading: userLoading, user } = useUser(authUser?.email);

  const audibleMessageStatus = useAudibleMessageRequestStatus(
    baseId,
    requestId,
  );

  const handleSendToBasestation = useCallback(async () => {
    try {
      if (audibleMessage) {
        const newRequestId = uuidv4();
        setRequestId(newRequestId);

        await sendAudibleMessageToBasestation(
          newRequestId,
          user!.mainId,
          audibleMessage.id,
          roomId,
          volume,
        );

        setStep(AudibleMessagesStep.RESULT);

        sendAmpEvent('Send Audible Message', {
          audibleMessageId: audibleMessage.id,
          roomId,
          volume,
        });
      }
    } catch (error) {
      console.error(error);
      setStep(AudibleMessagesStep.SENDING_ERROR);
    }
  }, [user, audibleMessage, volume, lang, roomId]);

  const handleMessageSelect = useCallback(
    (selectedAudibleMessage: AudibleMessage) => {
      setAudibleMessage(selectedAudibleMessage);
      setStep(AudibleMessagesStep.CONFIRM);
    },
    [],
  );

  const handleCancel = useCallback(() => {
    setAudibleMessage(null);
    setStep(AudibleMessagesStep.SELECT);
  }, []);

  useEffect(() => {
    if (!open) {
      setStep(AudibleMessagesStep.SELECT);
      setRequestId(null);
    }
  }, [open]);

  if (!open || userLoading) return null;

  return (
    <Popover
      id={`audio-messages-${roomId}`}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      anchorEl={anchorEl}
      style={{
        zIndex: 1500,
        marginLeft: '16px',
      }}
    >
      <Stack
        sx={{
          width: '320px',
          height: '310px',
          direction: 'column',
          p: 2,
          position: 'relative',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        {step === AudibleMessagesStep.SELECT && (
          <RoomAudibleMessageModalForm
            audibleMessages={audibleMessages}
            languages={languages}
            onMessageSelect={handleMessageSelect}
            lang={lang}
            onLangChange={setLang}
            volume={volume}
            onVolumeChange={setVolume}
            roomName={roomName}
          />
        )}
        {step === AudibleMessagesStep.CONFIRM && audibleMessage && (
          <RoomAudibleMessageModalConfirm
            audibleMessage={audibleMessage}
            volume={volume}
            roomName={roomName}
            onCancel={handleCancel}
            onSubmit={handleSendToBasestation}
            lang={lang}
          />
        )}
        {step === AudibleMessagesStep.SENDING_ERROR && (
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            sx={{ flex: 1 }}
          >
            <Typography
              sx={{
                mb: 2,
                color: theme.palette.error.main,
                px: 2,
                textAlign: 'center',
              }}
            >
              Unable to send audible message at this time
            </Typography>
            <ErrorOutlineIcon
              sx={{ color: theme.palette.error.main, fontSize: '48px' }}
            />
          </Stack>
        )}
        {step === AudibleMessagesStep.RESULT && requestId && (
          <RoomAudibleMessageModalResult
            audibleMessageStatus={audibleMessageStatus}
          />
        )}
      </Stack>
    </Popover>
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import {
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { isEmpty, orderBy, sortBy, uniq } from 'lodash';
import { useNavigate } from 'react-router-dom';

import RoomSelectCard from './RoomSelectCard';

import { Room, User } from '../../../../types';
import { useUserOrg } from '../../../hooks/useUserOrg';
import { sendAmpEvent } from '../../../utility/amplitude';
import {
  formatUserLabelWithRole,
  formatUserRole,
} from '../../../utility/helpers/helpers';
import { useOrganizationRoles } from '../../Admin/hooks/useOrganizationRoles';
import { Can } from '../../Can/Can';
import LoadingAutocomplete from '../../shared/LoadingAutocomplete';
import { getEligibleRoomsForUser } from '../data-access/getEligibleRoomsForUser';
import { updateRoomAssignments } from '../data-access/updateRoomAssignments';
import { useTelesittingUsers } from '../hooks/useTelesittingUsers';
import { useUnits } from '../hooks/useUnits';

type FilterOption = {
  label?: string;
  value?: string;
  type: 'User' | 'Unit';
  displayName?: string;
  buildingDisplayName?: string;
  name?: string;
  address?: string;
};

const defaultFilterOptions: FilterOption[] = [
  { label: 'Assigned', value: 'assigned', type: 'User' },
];

const ManageRooms = () => {
  const navigate = useNavigate();
  const { user: currentAuthUser } = useAuth0();
  const queryClient = useQueryClient();
  const userOrg = useUserOrg();
  const { roleMap } = useOrganizationRoles(userOrg);
  const [assignee, setAssignee] = useState<User | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<FilterOption[]>([]);
  const [selectedRooms, setSelectedRooms] = useState<string[]>([]);
  const { users, isFetching: usersLoading } = useTelesittingUsers(userOrg);

  const { units } = useUnits();

  const {
    data: eligibleRooms = [],
    isLoading: roomsLoading,
    isFetching: roomsFetching,
  } = useQuery({
    queryKey: ['eligibleRooms', assignee?.mainId],
    queryFn: assignee
      ? () => getEligibleRoomsForUser(assignee.mainId)
      : skipToken,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const currentUser = users?.find(
    (user) => user.email === currentAuthUser?.email,
  );

  const toggleRoomSelection = useCallback(
    (roomId: string) => {
      if (selectedRooms.includes(roomId)) {
        setSelectedRooms((state) => state.filter((room) => room !== roomId));
      } else {
        setSelectedRooms((state) => [...state, roomId]);
      }
    },
    [selectedRooms],
  );

  const assignRoomsMutation = useMutation({
    mutationFn: updateRoomAssignments,
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: ['customUnits'],
      });

      queryClient.invalidateQueries({
        queryKey: ['eligibleRooms', assignee?.mainId],
      });
    },
  });

  const handleAssignRoomMutation = useCallback(() => {
    if (!assignee) return;

    assignRoomsMutation.mutate({
      roomIds: selectedRooms,
      userId: assignee?.mainId,
    });

    sendAmpEvent('Assigned Rooms', {
      rooms: selectedRooms,
      user: assignee?.mainId,
    });

    queryClient.invalidateQueries({ queryKey: ['demo-actions'] });
    localStorage.setItem('dropdownSelection', 'CUSTOM');
    navigate('/rooms');
  }, [assignee, selectedRooms, assignRoomsMutation]);

  const cancel = useCallback(() => {
    navigate('/rooms');
  }, []);

  const filterOptions = useMemo(() => {
    const eligibleUnits = uniq(eligibleRooms.map((room) => room.unit));

    const unitOptions = eligibleUnits.map((eligUnit) => {
      const u = units?.find(
        (unit) => `Room-${unit.floor}-${unit.name}` === eligUnit,
      );

      return {
        type: 'Unit',
        value: eligUnit,
        address: u?.address,
        buildingDisplayName: u?.buildingDisplayName,
        displayName: u?.displayName,
        name: u?.name,
      } as FilterOption;
    });

    const sortedUnits = orderBy(
      unitOptions,
      [
        (item) => item?.buildingDisplayName?.toLowerCase(),
        (item) => item?.displayName?.toLowerCase(),
        (item) => item?.name?.toLowerCase(),
      ],
      ['asc', 'asc', 'asc'],
    );

    return [...defaultFilterOptions, ...sortedUnits];
  }, [eligibleRooms, units, defaultFilterOptions]);

  const visibleRooms = useMemo(() => {
    if (isEmpty(selectedFilters)) return eligibleRooms;

    const rooms: Room[] = [];

    selectedFilters.forEach((filter) => {
      if (filter.type === 'User' && filter.value === 'assigned')
        rooms.push(...eligibleRooms.filter((room) => room.assigned));

      if (filter.type === 'Unit')
        rooms.push(
          ...eligibleRooms.filter((room) => room.unit === filter.value),
        );
    });

    return sortBy(uniq(rooms), 'mainId');
  }, [selectedFilters, eligibleRooms]);

  useEffect(() => {
    if (currentUser && !assignee) {
      setAssignee(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (eligibleRooms) {
      setSelectedRooms(
        eligibleRooms
          .filter((room) => room.assigned)
          .map((room) => room.mainId),
      );
    }
  }, [eligibleRooms]);

  return (
    <Container sx={{ pt: 4, position: 'relative', minHeight: '75vh' }}>
      {users?.length !== 1 && (
        <Can
          permission={{
            action: 'view',
            subject: 'virtual-care.rooms.manage-room-assignments-all',
          }}
        >
          <>
            <Typography variant='h2' mb={3}>
              Select a user
            </Typography>

            <Stack direction='row' spacing={1}>
              <LoadingAutocomplete
                label='User'
                loading={usersLoading && isEmpty(users)}
                size='small'
                id='user'
                options={users || []}
                sx={{ width: 360 }}
                renderOption={(props, option) => {
                  const displayRole = formatUserRole(
                    option.roleId,
                    roleMap,
                    option.role,
                  );

                  return (
                    <li {...props} key={option.mainId}>
                      <Box display='inline' fontWeight='700'>
                        {option.firstName} {option.lastName}
                      </Box>
                      <Box
                        display='inline'
                        ml={0.75}
                        fontSize={13}
                        fontWeight='700'
                        color='grey.600'
                      >
                        {displayRole && `(${displayRole})`}
                      </Box>
                    </li>
                  );
                }}
                getOptionLabel={(option) =>
                  formatUserLabelWithRole(option, roleMap)
                }
                isOptionEqualToValue={(option, value) =>
                  option.mainId === value.mainId
                }
                onChange={(_, value) => setAssignee(value)}
                value={assignee}
              />
            </Stack>
          </>
        </Can>
      )}

      <Typography
        variant='body1'
        component='h3'
        sx={{ mt: 4, mb: 0.5, fontWeight: 600 }}
      >
        Assigning rooms to
      </Typography>
      <Typography variant='h3' component='h4' sx={{ mb: 3, fontWeight: 800 }}>
        {assignee ? formatUserLabelWithRole(assignee, roleMap) : '-'}
      </Typography>

      <Autocomplete
        multiple
        size='small'
        id='filter'
        options={filterOptions}
        sx={{ width: 500 }}
        renderInput={(params) => <TextField {...params} label='Filter' />}
        value={selectedFilters}
        onChange={(_, value) => setSelectedFilters(value)}
        getOptionLabel={(option) => {
          if (option.type === 'User') return option.label || '';
          return (
            `${option?.buildingDisplayName || option?.address} - ${
              option?.displayName || option?.name
            }` || ''
          );
        }}
        renderOption={(props, option) => {
          if (option.type === 'User')
            return <li {...props}>{option?.label}</li>;
          return <li {...props}>{option?.displayName || option?.name}</li>;
        }}
        groupBy={(option) =>
          option.buildingDisplayName || option.address || option.type
        }
        disabled={isEmpty(eligibleRooms)}
        disableCloseOnSelect
      />

      <Box mt={1} mb={2}>
        <Button
          onClick={() =>
            setSelectedRooms(visibleRooms.map((room) => room.mainId))
          }
          disabled={isEmpty(eligibleRooms)}
        >
          Select all
        </Button>
        <Button
          onClick={() => setSelectedRooms([])}
          disabled={isEmpty(eligibleRooms)}
        >
          Reset
        </Button>
      </Box>

      {!roomsLoading && !roomsFetching ? (
        <Box display='flex' flexWrap='wrap' pb={12}>
          {sortBy(visibleRooms, 'mainId').map((room) => (
            <RoomSelectCard
              key={room.mainId}
              data={room}
              selected={selectedRooms.includes(room.mainId)}
              onClick={() => toggleRoomSelection(room.mainId)}
            />
          ))}
        </Box>
      ) : (
        <Box
          display='flex'
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            minHeight: '30vh',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Box position='absolute' bottom={20} right={40}>
        <Button onClick={cancel}>Cancel</Button>
        <Button
          variant='contained'
          onClick={handleAssignRoomMutation}
          sx={{ ml: 1 }}
          disabled={!assignee}
        >
          Done
        </Button>
      </Box>
    </Container>
  );
};

export default Sentry.withProfiler(ManageRooms);

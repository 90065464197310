import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { skipToken, useQuery } from '@tanstack/react-query';
import { isBefore } from 'date-fns';
import { flattenDeep, isEmpty, isNil, sortBy } from 'lodash';

import { roundToNearestXSeconds } from '@inspiren-monorepo/util-formatters';

import EventImage from './EventImage';
import ImageMenu from './ImageMenu';
import Playbar, { SpeedOption } from './Playbar';
import { getRoomLiveImages } from './data-access/getRoomLiveImages';

import { useCurrentUser } from '../../../HOC/CurrentUserContextProvider';
import { sendAmpEvent } from '../../../utility/amplitude';
import {
  doesRangeExceedLimitForImages,
  isPastImageExpiration,
} from '../../../utility/helpers/time';
import { useOrganizationRoles } from '../../Admin/hooks/useOrganizationRoles';
import { splitDatesIntoMinWindows } from '../helpers/splitDatesIntoMinWindows';
import { useMarks } from '../hooks/useMarks';
import { useEventReviewStore } from '../store/EventReviewStore';

const speedOptions: SpeedOption[] = [
  { label: 'Slow', value: 1000 },
  { label: 'Normal', value: 500 },
];

const ImagePlayer = () => {
  const { user } = useCurrentUser();
  const { roleMap } = useOrganizationRoles(user?.org);

  const {
    position,
    setImagesLoading,
    incrementPosition,
    selectedRoom,
    selectedUnit,
    startDate,
    endDate,
    images,
    imagesLoading,
    setImages,
    setPosition,
    setImagesError,
    setLastImagesLoading,
    showStaffEventMarks,
    showNotifMarks,
  } = useEventReviewStore();

  const tenMinuteWindows = useMemo(() => {
    if (isNil(startDate) || isNil(endDate)) return null;
    return splitDatesIntoMinWindows(startDate, endDate);
  }, [startDate, endDate]);

  // first hour is only if we have windows 0-5
  const firstHourWindows = useMemo(() => {
    if (isNil(tenMinuteWindows)) return null;
    return tenMinuteWindows.slice(0, 5);
  }, [tenMinuteWindows]);

  const lastHourWindows = useMemo(() => {
    if (isNil(tenMinuteWindows)) return null;
    return tenMinuteWindows.slice(5);
  }, [tenMinuteWindows]);

  const {
    data: firstHourData,
    isFetching,
    isLoading,
    error,
    refetch,
    isSuccess,
  } = useQuery({
    queryKey: ['eventReview', 'images', selectedRoom?.mainId, firstHourWindows],
    queryFn:
      selectedRoom &&
      firstHourWindows &&
      !doesRangeExceedLimitForImages(startDate, endDate) &&
      !isPastImageExpiration(endDate, selectedUnit)
        ? () => getRoomLiveImages(selectedRoom?.mainId || '', firstHourWindows)
        : skipToken,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isSuccess && !isEmpty(firstHourData.results)) {
      const imgs = flattenDeep(firstHourData.results.map((res) => res.images));

      if (isNil(images)) {
        setImages(sortBy(imgs, 'time'));
        return;
      }

      // we have to find img by time and replace the url with the new one
      const newImages = [...images];

      imgs.forEach((img) => {
        const index = images.findIndex((i) => i.time === img.time);

        if (index >= 0) {
          newImages[index] = img;
        }
      });

      if (lastHourWindows && lastHourWindows.length > 0) {
        const filteredImages = newImages.filter((i) => {
          if (i.url === '') {
            return !isBefore(new Date(i.time), lastHourWindows[0].start);
          }

          return true;
        });

        setImages(sortBy(filteredImages, 'time'));
      } else {
        const filteredImages = newImages.filter((i) => i.url !== '');
        setImages(sortBy(filteredImages, 'time'));
      }
    }
  }, [firstHourData]);

  const {
    data: lastHourData,
    isFetching: isFetchingLastImgs,
    isLoading: isLoadingLastImgs,
    refetch: refetchLastImgs,
    isSuccess: isSuccessLastImgs,
  } = useQuery({
    queryKey: ['eventReview', 'images', selectedRoom?.mainId, lastHourWindows],
    queryFn:
      selectedRoom &&
      lastHourWindows &&
      !doesRangeExceedLimitForImages(startDate, endDate) &&
      isSuccess
        ? () => getRoomLiveImages(selectedRoom?.mainId || '', lastHourWindows)
        : skipToken,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isSuccessLastImgs && !isEmpty(lastHourData.results)) {
      const imgs = flattenDeep(lastHourData.results.map((res) => res.images));

      if (isNil(images)) {
        setImages(sortBy(imgs, 'time'));
        return;
      }

      // we have to find img by time and replace the url with the new one
      const newImages = [...images];

      imgs.forEach((img) => {
        const index = images.findIndex((i) => i.time === img.time);

        if (index >= 0) {
          newImages[index] = img;
        }
      });

      const filteredImages = newImages.filter((i) => i.url !== '');
      setImages(sortBy(filteredImages, 'time'));
    }
  }, [lastHourData]);

  useEffect(() => {}, [lastHourData]);

  useEffect(() => {
    setImagesLoading(isLoading && isFetching);
  }, [isLoading, isFetching]);

  useEffect(() => {
    setLastImagesLoading(isLoadingLastImgs && isFetchingLastImgs);
  }, [isLoadingLastImgs, isFetchingLastImgs]);

  useEffect(() => {
    if (error) {
      setImagesLoading(false);
      setLastImagesLoading(false);
      setImages([]);
      setImagesError(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (
      !doesRangeExceedLimitForImages(startDate, endDate) &&
      startDate &&
      endDate &&
      !isPastImageExpiration(endDate, selectedUnit)
    ) {
      // generate timestamps for the time frame and set images { url: null, time: timestamp }
      const imgs = [];
      let currentDate = new Date(startDate.getTime());
      currentDate = roundToNearestXSeconds(currentDate, 3); // Round to the nearest interval.

      // if end date is greater than now, set it to now
      const lastDate = isBefore(new Date(endDate), new Date())
        ? new Date(endDate.getTime())
        : new Date();

      while (currentDate <= lastDate) {
        imgs.push({
          url: '',
          time: currentDate.toISOString(),
        });

        // Increment by one rounding unit.
        currentDate = new Date(currentDate.getTime() + 3 * 1000);
      }

      setImages(imgs);
    }
  }, [startDate, endDate, selectedUnit, selectedRoom]);

  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [speed, setSpeed] = useState<SpeedOption>(speedOptions[1]);
  const [railWidth, setRailWidth] = useState<number | null>(null);

  const handleRailWidthChange = useCallback((width: number | null) => {
    setRailWidth(width);
  }, []);

  const imgUrl = images && images[position] ? images[position].url : null;
  const maxPosition = images && images.length > 0 ? images.length - 1 : null;

  const spacePerMark = useMemo(() => {
    if (!railWidth || !maxPosition) return null;
    return railWidth / maxPosition;
  }, [railWidth, maxPosition]);

  const disabled = !images || images.length < 1;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rippleRef = useRef<any>();

  const handleRipple = () => {
    rippleRef.current.start({});
    setTimeout(() => rippleRef.current.stop({}), 240);
  };

  /**
   * It cleans up the old interval. We use it to stop interval when we change speed or once change card to Rooms etc.
   */
  useEffect(
    () => () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    },
    [intervalId],
  );

  /**
   * It resets intervalId when we change startDate, endDate or room. We use it to stop playback.
   */
  useEffect(
    () => () => {
      setIntervalId(null);
    },
    [startDate, endDate, selectedRoom],
  );

  const handleRefresh = useCallback(() => {
    refetch();
    refetchLastImgs();
    setImages(null);
    setPosition(0);
    setImagesError(null);
    setImagesLoading(true);
    setLastImagesLoading(true);
  }, [refetch, setPosition, refetchLastImgs]);

  const handleStepForward = useCallback(() => {
    if (isNil(images) || position >= images.length - 1) return;
    incrementPosition();
  }, [images, position]);

  const handlePlayClick = useCallback(() => {
    sendAmpEvent('Playback Clicked', {});

    if (intervalId) {
      setIntervalId(null);
      return;
    }

    const newIntervalId = setInterval(() => {
      handleStepForward();
    }, speed.value);

    setIntervalId(newIntervalId);
  }, [intervalId, images, handleStepForward]);

  const handleSpeedChange = useCallback(
    (option: SpeedOption) => {
      setSpeed(option);
      sendAmpEvent('Playback Speed Change', { speed: option });

      if (intervalId) {
        const newIntervalId = setInterval(() => {
          handleStepForward();
        }, option.value);

        setIntervalId(newIntervalId);
      }
    },
    [intervalId, handleStepForward],
  );

  useEffect(() => {
    // Stop playback at end or if images change
    if (
      intervalId &&
      ((maxPosition && position >= maxPosition) || images?.length === 0)
    ) {
      setIntervalId(null);
    }
  }, [position, maxPosition, images]);

  const is1hrOrLess = useMemo(
    () => !doesRangeExceedLimitForImages(startDate, endDate, 1000 * 60 * 60),
    [startDate, endDate],
  );

  const marks = useMarks({
    startDate,
    endDate,
    images,
    showStaffEventMarks,
    showNotifMarks,
    roleMap,
    spacePerMark,
  });

  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
      }}
      data-testid='eventreview-imageplayer'
    >
      <Box
        sx={{
          position: 'relative',
          aspectRatio: '953 / 735',
          width: '100%',
        }}
      >
        {!isPastImageExpiration(endDate, selectedUnit) &&
          !disabled &&
          !imagesLoading && <ImageMenu disableVideoExport={!is1hrOrLess} />}
        <EventImage
          src={imgUrl || null}
          refresh={handleRefresh}
          onClick={() => {
            handleRipple();
            handlePlayClick();
          }}
          selectedRoomId={selectedRoom?.mainId || ''}
          marks={marks}
        />
      </Box>
      <Playbar
        disabled={
          disabled ||
          imagesLoading ||
          isPastImageExpiration(endDate, selectedUnit)
        }
        maxPosition={maxPosition}
        playing={Boolean(intervalId)}
        onPlayClick={handlePlayClick}
        speedOptions={speedOptions}
        speed={speed}
        onSpeedChange={handleSpeedChange}
        rippleRef={rippleRef}
        onRailWidthChange={handleRailWidthChange}
        spacePerMark={spacePerMark}
        marks={marks}
      />
    </Box>
  );
};

export default Sentry.withProfiler(ImagePlayer);

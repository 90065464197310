import { Box, SxProps, Theme, Typography } from '@mui/material';

interface Props {
  status: string;
  sx?: SxProps<Theme>;
}

const RoomStatus = ({ status, sx }: Props) => (
  <Box
    sx={{
      backgroundColor: 'grey.500',
      borderRadius: 1,
      ...sx,
    }}
  >
    <Typography variant='subtitle2'>{status}</Typography>
  </Box>
);

export default RoomStatus;

import { useCallback, useMemo, useState } from 'react';
import { Edit } from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty, keys, some, sortBy, values } from 'lodash';
import * as yup from 'yup';

import { SORT_OPTIONS } from '@inspiren-monorepo/shared-types';

import { SelectRole } from './SelectRole';
import SelectTrainingVideo from './SelectTrainingVideo';
import { SelectUnit } from './SelectUnit';

import { AdminOrganizationSettings } from '../../../../../../types';
import { ipValidationRegex } from '../../../../../utility/regex/ip-regex';
import { putOrgSettings } from '../../../data-access/putOrgSettings';
import { useAdminOrgSettings } from '../../../hooks/useAdminOrgSettings';
import { useTrainingVideos } from '../../../hooks/useTrainingVideo';
import EditModal from '../../../modals/EditModal';
import { RenderFormModal } from '../../../modals/FormModalBase';
import { DataField } from '../../../types/DataFields';

const arrayFieldSchema = yup.array().transform((value, originalValue) =>
  typeof originalValue !== 'string'
    ? value
    : originalValue
        .split(',')
        .map((item: string) => item.trim())
        .filter((item) => !isEmpty(item)),
);

export type EditableSettings = Pick<
  AdminOrganizationSettings,
  | 'name'
  | 'ipAllowlist'
  | 'defaultSort'
  | 'defaultTrainingVideoUrlMobile'
  | 'auth0WebClientId'
  | 'auth0MobileClientId'
  | 'auth0TenantUrl'
  | 'auth0EnterpriseConnections'
  | 'auth0DbConnection'
  | 'domains'
  | 'defaultRoleId'
  | 'fourDigitValidation'
  | 'defaultLevelAccess'
  | 'defaultUnitId'
  | 'labelAllNotifs'
>;

export const fields: Record<
  string,
  DataField<EditableSettings & Pick<AdminOrganizationSettings, 'org'>>
> = {
  org: {
    field: 'org',
    label: 'Organization ID',
    editable: false,
    width: 150,
    editType: 'text',
  },
  domains: {
    field: 'domains',
    label: 'Domains',
    width: 250,
    editType: 'text',
    initialValue: [],
    schema: arrayFieldSchema.when(
      [
        'auth0TenantUrl',
        'auth0WebClientId',
        'auth0MobileClientId',
        'auth0EnterpriseConnections',
        'auth0DbConnection',
      ],
      {
        is: (...formValues: string[]) =>
          some(formValues, (value) => !isEmpty(value)),
        then: (schema) =>
          schema
            .min(1, 'You must provide at least one domain.')
            .required('You must provide at least one domain.'),
      },
    ),
    schemaExclude: true,
    tooltip: 'Comma separated list of domains used by this organization',
  },
  name: {
    field: 'name',
    label: 'Display name',
    width: 250,
    editType: 'text',
    schema: yup.string().required('You must provide an organization name'),
  },
  ipAllowlist: {
    field: 'ipAllowlist',
    label: 'Allowed IP addresses',
    width: 220,
    editType: 'text',
    hideOnAdd: false,
    initialValue: [],
    schema: arrayFieldSchema.test({
      message: 'One of the values is neither IP address nor CIDR notation',
      test: (ips) =>
        !ips || ips.every((ip) => !!ip && ipValidationRegex.test(ip)),
    }),
  },
  defaultSort: {
    field: 'defaultSort',
    label: 'Default sort',
    width: 250,
    editType: 'select',
    options: sortBy(SORT_OPTIONS, 'label'),
  },
  defaultTrainingVideoUrlMobile: {
    field: 'defaultTrainingVideoUrlMobile',
    label: 'Default Training Video (Mobile)',
    width: 300,
    editType: 'special',
  },
  auth0TenantUrl: {
    field: 'auth0TenantUrl',
    label: 'Auth0 Tenant URL',
    width: 250,
    editType: 'text',
    schema: yup
      .string()
      .trim()
      .when(
        [
          'auth0WebClientId',
          'auth0MobileClientId',
          'auth0EnterpriseConnections',
          'auth0DbConnection',
        ],
        {
          is: (...formValues: string[]) =>
            some(formValues, (value) => !isEmpty(value)),
          then: (schema) =>
            schema.required('You must provide an Auth0 Tenant Url.'),
        },
      ),
    schemaExclude: true,
    tooltip: 'The URL of the Auth0 tenant used for this organization.',
  },
  auth0WebClientId: {
    field: 'auth0WebClientId',
    label: 'Auth0 Web Client ID',
    width: 250,
    editType: 'text',
    schema: yup
      .string()
      .trim()
      .when(
        [
          'auth0TenantUrl',
          'auth0MobileClientId',
          'auth0EnterpriseConnections',
          'auth0DbConnection',
        ],
        {
          is: (...formValues: string[]) =>
            some(formValues, (value) => !isEmpty(value)),
          then: (schema) =>
            schema.required('You must provide an Auth0 Web Client ID.'),
        },
      ),
    schemaExclude: true,
    tooltip:
      'The client ID of the Auth0 used for this organization in the web application.',
  },
  auth0MobileClientId: {
    field: 'auth0MobileClientId',
    label: 'Auth0 Mobile Client ID',
    width: 250,
    editType: 'text',
    schema: yup
      .string()
      .trim()
      .when(
        [
          'auth0TenantUrl',
          'auth0WebClientId',
          'auth0EnterpriseConnections',
          'auth0DbConnection',
        ],
        {
          is: (...formValues: string[]) =>
            some(formValues, (value) => !isEmpty(value)),
          then: (schema) =>
            schema.required('You must provide an Auth0 Mobile Client ID.'),
        },
      ),
    schemaExclude: true,
    tooltip:
      'The client ID of the Auth0 used for this organization in the mobile application.',
  },
  auth0EnterpriseConnections: {
    field: 'auth0EnterpriseConnections',
    label: 'Auth0 Enterprise Connections',
    width: 250,
    editType: 'text',
    initialValue: [],
    schema: arrayFieldSchema,
    tooltip:
      'Comma separated list of enterprise connections used by this organization. ' +
      'For these connections we will create a user with default role if user does not exist.',
  },
  auth0DbConnection: {
    field: 'auth0DbConnection',
    label: 'Auth0 Database Connection',
    width: 250,
    editType: 'text',
    schema: yup
      .string()
      .trim()
      .when(
        [
          'auth0TenantUrl',
          'auth0MobileClientId',
          'auth0WebClientId',
          'auth0EnterpriseConnections',
        ],
        {
          is: (...formValues: string[]) =>
            some(formValues, (value) => !isEmpty(value)),
          then: (schema) =>
            schema.required('You must provide an Auth0 Database Connection.'),
        },
      ),
    schemaExclude: true,
    tooltip:
      'The database connection used by this organization. ' +
      'User will be added to this connection when created from Admin page.',
  },
  defaultRoleId: {
    field: 'defaultRoleId',
    label: 'Default Role ID',
    width: 250,
    editType: 'text',
    schema: yup.string().trim().nullable(),
    tooltip:
      'The default role ID used by this organization. ' +
      'User will be assigned to this when created from an enterprise connection.',
  },
  fourDigitValidation: {
    field: 'fourDigitValidation',
    label: 'Four Digit Validation',
    width: 250,
    editType: 'boolean',
    schema: yup.boolean(),
    tooltip:
      'If enabled, the system will require a 4 digit ID suffix for each beacon. ' +
      'If disabled, the system will not require a 4 digit ID suffix for each beacon.',
  },
  defaultLevelAccess: {
    field: 'defaultLevelAccess',
    label: 'Default Level Access',
    width: 250,
    editType: 'select',
    options: [
      { value: '', label: 'None' },
      { value: 'org', label: 'Organization' },
      { value: 'building', label: 'Building' },
      { value: 'unit', label: 'Unit' },
    ],
    tooltip:
      'The default level access for this organization. ' +
      'This will be used when creating a new user.',
  },
  defaultUnitId: {
    field: 'defaultUnitId',
    label: 'Default Unit ID',
    width: 250,
    editType: 'text',
    schema: yup.string().trim().nullable(),
    tooltip:
      'The default unit ID used by this organization. ' +
      'This will be used when creating a new user.',
  },
  labelAllNotifs: {
    field: 'labelAllNotifs',
    label: 'Label All Activity',
    width: 250,
    editType: 'boolean',
    schema: yup.boolean(),
    tooltip:
      'If enabled, all notifications will be labeled regardless of fall risk. ' +
      'If disabled, labeled notifications will depend on fall risk level',
  },
};

const modalFields = values(fields);

interface Props {
  organization: { id: string; name: string };
  disabled: boolean;
  children?: JSX.Element;
}

export const EditOrganizationSettingsModal = ({
  organization: { id, name },
  disabled,
  children,
}: Props) => {
  const [enabled, setEnabled] = useState(false);

  const queryClient = useQueryClient();

  const {
    data: settings,
    isLoading: isSettingsLoading,
    isError: isSettingsError,
  } = useAdminOrgSettings({ enabled, org: id });

  const { mutateAsync } = useMutation({
    mutationFn: (data: AdminOrganizationSettings) =>
      putOrgSettings({
        ...data,
        defaultSort: data.defaultSort || undefined,
      }),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: ['orgs'] });
      queryClient.resetQueries({ queryKey: ['org', id, 'settings'] });
    },
  });

  const onSubmit = useCallback(
    async (data: AdminOrganizationSettings) => mutateAsync(data),
    [mutateAsync],
  );

  const { isLoading: isTrainingVideosLoading, isError: isTrainingVideosError } =
    useTrainingVideos({ enabled });

  const renderModal: RenderFormModal<AdminOrganizationSettings> = useCallback(
    ({ defaultComponents, control }) => (
      <>
        {keys(defaultComponents).map((field) => {
          switch (field) {
            case 'defaultTrainingVideoUrlMobile':
              return (
                <SelectTrainingVideo
                  control={control}
                  id='defaultTrainingVideoUrlMobile'
                  label='Default Training Video (Mobile)'
                  key='defaultTrainingVideoUrlMobile'
                />
              );
            case 'defaultRoleId':
              return (
                <SelectRole
                  org={id}
                  control={control}
                  id='defaultRoleId'
                  label='Default Role ID'
                  key='defaultRoleId'
                />
              );
            case 'defaultUnitId':
              return (
                <SelectUnit
                  org={id}
                  control={control}
                  id='defaultUnitId'
                  label='Default Unit ID'
                  key='defaultUnitId'
                />
              );
            default:
              return defaultComponents[
                field as keyof AdminOrganizationSettings
              ];
          }
        })}
      </>
    ),
    [name, id],
  );

  const loading = useMemo(
    () => !enabled || isSettingsLoading || !settings || isTrainingVideosLoading,
    [enabled, isSettingsLoading, settings, isTrainingVideosLoading],
  );

  const error = useMemo(
    () => isSettingsError || isTrainingVideosError,
    [isSettingsError, isTrainingVideosError],
  );

  return (
    <EditModal<AdminOrganizationSettings>
      itemName={`Organization ${name}`}
      initialValues={settings}
      loading={loading}
      error={error}
      fields={modalFields}
      onOpen={() => setEnabled(true)}
      onSubmit={onSubmit}
      openIcon={<Edit fontSize='small' />}
      renderModal={renderModal}
      disabled={disabled}
    >
      {children}
    </EditModal>
  );
};

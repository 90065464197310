import { TableauUserView } from '@inspiren-monorepo/util-tableau';

import { Api } from '../../../utility/client';

type ViewsResp = {
  views: TableauUserView[];
};

export async function getTableauViews() {
  const res = await Api.get<ViewsResp>('/telesitting/v1/tableau/views');
  return res.data;
}

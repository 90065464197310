import React, { useCallback, useMemo, useState } from 'react';
import { Link, Stack } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { isEmpty, isNil } from 'lodash';
import toast from 'react-hot-toast';
import * as yup from 'yup';

import {
  getUpstreamErrorMessage,
  isUpstreamError,
} from '@inspiren-monorepo/shared-types';
import { isUsernameOrg } from '@inspiren-monorepo/util-users';

import { Battery } from './components/Battery';
import { BeaconModalForm } from './components/BeaconModalForm';
import { BulkUploadCSV } from './components/BulkUploadCSV';
import { createBeacon } from './data-access/createBeacon';
import { updateBeacon } from './data-access/updateBeacon';

import { Organization } from '../../../../../types';
import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import { useUserOrg } from '../../../../hooks/useUserOrg';
import OrgSelector from '../../components/OrgSelector';
import { getBeacons } from '../../data-access/getBeacons';
import { getOrgs } from '../../data-access/getOrgs';
import { OnSubmitFormModal, RenderFormModal } from '../../modals/FormModalBase';
import TableBase from '../../tables/TableBase';
import { DataFields } from '../../types/DataFields';
import { formatNoValue } from '../helpers/formatNoValue';
import { formatUnassigned } from '../helpers/formatUnassigned';
import { noValueClassName } from '../helpers/noValueClassName';

/**
 * When we import beacons we only receive few last digits so we need to add prefix
 */
const BEACON_PREFIX = 'a0224eb0';

export type FieldTypes = {
  id: string;
  assignedTo?: string;
  assignedToEmail?: string;
  assignedToUsername?: string;
  email?: string;
  firstName?: string;
  lastSeen?: string;
  latest: number;
  rm?: string;
  org: string;
  battery?: string;
};

export const BeaconsTable = () => {
  const userOrg = useUserOrg();
  const queryClient = useQueryClient();

  const [selectedOrg, setSelectedOrg] = useState<string | undefined>(userOrg);

  const { isLoading: orgsLoading, data: orgs } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const {
    isLoading: loading,
    data: beacons,
    isError,
  } = useQuery({
    queryKey: ['beacons', selectedOrg],
    queryFn: () => getBeacons(selectedOrg),
  });

  const fields = useMemo(() => {
    const innerFields: DataFields<FieldTypes> = [
      {
        field: 'org',
        label: 'Org',
        editType: 'text',
        width: 'hidden',
        initialValue: selectedOrg,
      },
      {
        field: 'id',
        label: 'ID',
        width: 250,
        editType: 'text',
        editable: false,
        initialValue: BEACON_PREFIX,
        schema: yup
          .string()
          .required('You must provide an ID')
          .when('org', ([org], schema) => {
            if (orgs?.find((o) => o.id === org)?.fourDigitValidation) {
              return schema.length(
                BEACON_PREFIX.length + 4,
                `ID should have ${BEACON_PREFIX.length + 4} characters.`,
              );
            }

            return schema;
          })
          .matches(
            new RegExp(`^${BEACON_PREFIX}\\S+$`, 'i'),
            `ID should start with ${BEACON_PREFIX} prefix.`,
          )
          .matches(/^\S+$/i, "ID can't contain spaces."),
        renderCell: ({ value }) => (
          <Link href={`/admin/beacons/${value}`}>{value}</Link>
        ),
      },
      {
        field: 'assignedTo',
        label: 'Assigned To',
        width: 300,
        editType: 'text',
        renderCell: ({ value, row }) => {
          if (!isEmpty(value)) {
            return isUsernameOrg(userOrg)
              ? row.assignedToUsername
              : row.assignedToEmail;
          }

          return <span className='no-value'>unassigned</span>;
        },
      },
      {
        field: 'firstName',
        label: 'First Name',
        width: 150,
        editType: 'text',
        valueFormatter: formatUnassigned,
        cellClassName: noValueClassName,
      },
      {
        field: 'battery',
        label: 'Battery',
        width: 90,
        editType: 'text',
        editable: false,
        renderCell: ({ value }) =>
          isNil(value) ? '-' : <Battery value={value} />,
      },
      {
        field: 'lastSeen',
        label: 'Last Seen At',
        width: 200,
        editType: 'text',
        valueFormatter: formatNoValue,
      },
      {
        field: 'rm',
        label: 'Last Seen In Room',
        width: 300,
        editType: 'text',
        valueFormatter: formatNoValue,
      },
    ];

    return innerFields;
  }, [selectedOrg, orgs]);

  const { isAdmin } = useIsAdmin();

  const selectedOrganization = useMemo(
    () => (orgs || []).find((org) => org.id === selectedOrg),
    [selectedOrg, orgs],
  );

  const handleOrgChange = useCallback(
    (_e: React.ChangeEvent<object>, newValue: Organization | null) => {
      setSelectedOrg(newValue?.id);
    },
    [],
  );

  const handleEditSubmit: OnSubmitFormModal<FieldTypes> = useCallback(
    async (beacon) => {
      try {
        await updateBeacon(beacon.org, beacon.id, beacon.assignedTo);

        await queryClient.invalidateQueries({
          queryKey: ['beacons', selectedOrg],
        });

        toast.success('Beacon updated successfully.');
      } catch (error) {
        if (isAxiosError(error) && error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {
          toast.error(`Error updating beacon ${error ? `: ${error}` : ''}`);
        }

        toast.error(`Error updating beacon ${error ? `: ${error}` : ''}`);
      }
    },
    [selectedOrg],
  );

  const handleAddSubmit: OnSubmitFormModal<FieldTypes> = useCallback(
    async (beacon) => {
      try {
        await createBeacon(selectedOrg, beacon.id, beacon.assignedTo);

        await queryClient.invalidateQueries({
          queryKey: ['beacons', selectedOrg],
        });

        toast.success('Beacon created successfully.');
      } catch (error) {
        let message = 'Could not add beacon';

        if (
          isAxiosError(error) &&
          error.response?.data &&
          isUpstreamError(error.response.data)
        ) {
          const upstreamErrorMessage = getUpstreamErrorMessage(
            error.response.data,
          );

          if (upstreamErrorMessage) {
            message = upstreamErrorMessage;
          }
        }

        toast.error(message);
      }
    },
    [selectedOrg],
  );

  const renderModal: RenderFormModal<FieldTypes> = useCallback(
    ({ control, type }) => (
      <BeaconModalForm
        control={control}
        type={type}
        defaultOrg={selectedOrg}
        beacons={beacons}
        isAdmin={isAdmin}
      />
    ),
    [selectedOrg, beacons],
  );

  return (
    <Stack spacing={2}>
      {isAdmin && (
        <OrgSelector
          orgs={orgs ?? []}
          loading={orgsLoading}
          value={selectedOrganization || null}
          onChange={handleOrgChange}
        />
      )}
      <TableBase<FieldTypes>
        key={`beacons-${selectedOrg}`}
        itemName='Beacon'
        fields={fields}
        data={beacons}
        loading={loading}
        modalLoading={orgsLoading}
        error={isError}
        onEditSubmit={handleEditSubmit}
        onAddSubmit={handleAddSubmit}
        extraToolbarButtons={isAdmin && <BulkUploadCSV org={selectedOrg} />}
        renderModal={renderModal}
        disableAddButton={!isAdmin}
        getRowId={(row) => row.id}
      />
    </Stack>
  );
};

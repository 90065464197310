import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';

import { DataField } from '../../../types/DataFields';
import { RolePermissionFieldTypes } from '../types/RolePermissionFieldTypes';

export const buildPermissionDataField = (
  field: keyof RolePermissionFieldTypes & string,
  label: string,
): DataField<RolePermissionFieldTypes> => ({
  field,
  label,
  width: 150,
  editType: 'special',
  hideOnAdd: true,
  renderCell: ({ row }) =>
    row[field] ? (
      <CheckCircle color='primary' />
    ) : (
      <CheckCircleOutline color='primary' />
    ),
});

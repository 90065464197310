import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getUnits } from '../../../data-access/getUnits';

interface Props {
  unitId: string;
}

const UnitDisplayName = ({ unitId }: Props) => {
  const { data, isError } = useQuery({
    queryKey: ['units'],
    queryFn: getUnits,
  });

  if (isError) {
    return (
      <Typography variant='body2' color='error'>
        Error loading unit
      </Typography>
    );
  }

  if (!data) return null;

  const unitObj = data.find(
    (unit) => `Room-${unit.floor}-${unit.name}` === unitId,
  );

  if (!unitObj) return null;

  return (
    <Typography variant='body2'>
      {unitObj?.address} {unitObj?.displayName || unitObj?.name}
    </Typography>
  );
};

export default UnitDisplayName;

import { useCallback } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

import { TableauUserView } from '@inspiren-monorepo/util-tableau';

import { useTableauViewPreviewImage } from '../hooks/useTableauViewPreviewImage';

type Props = {
  view: TableauUserView;
};

export const TableauView = ({ view }: Props) => {
  const navigate = useNavigate();

  const { data, isLoading } = useTableauViewPreviewImage(
    view.workbookId,
    view.id,
  );

  const navigateToTableauView = useCallback(() => {
    navigate(`/analytics/${view.id}`);
  }, [view]);

  return (
    <Card
      sx={{ width: 400, cursor: 'pointer' }}
      onClick={navigateToTableauView}
    >
      <CardMedia
        sx={{
          height: 160,
          m: 1,
          borderRadius: 1,
          border: `1px solid ${grey[200]}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        image={`data:image/png;base64,${data}`}
        title={view.name}
      >
        {isLoading && <CircularProgress size={16} />}
      </CardMedia>
      <CardContent sx={{ py: 1, px: 2, pb: '12px !important' }}>
        <Typography component='div'>
          {view.workbookName} / {view.name}
        </Typography>
      </CardContent>
    </Card>
  );
};

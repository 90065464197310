import { useMemo } from 'react';
import { capitalize, isEmpty, isNil, lowerCase, sortBy } from 'lodash';

import { WasabiImage } from '@inspiren-monorepo/shared-react';

import { useNotifications } from './useNotifications';
import { useOfflineEvents } from './useOfflineEvents';
import { useStaffEvents } from './useStaffEvents';
import { useUserEvents } from './useUserEvents';

import { MarkData, RoleMap } from '../../../../types';
import { collapseCrowdedMarks } from '../../../utility/helpers/helpers';
import { getPositionFromDate } from '../../../utility/helpers/time';

interface UseMarksParams {
  images: WasabiImage[] | null;
  showStaffEventMarks: boolean;
  showNotifMarks: boolean;
  spacePerMark: number | null;
  startDate: Date | null;
  endDate: Date | null;
  roleMap: RoleMap;
}

export const useMarks = ({
  images,
  showStaffEventMarks,
  showNotifMarks,
  spacePerMark,
  startDate,
  endDate,
  roleMap,
}: UseMarksParams) => {
  const { data: events } = useStaffEvents();
  const { data: notifications } = useNotifications();
  const { data: offlineEvents } = useOfflineEvents();
  const { data: userEvents } = useUserEvents();

  const marks = useMemo(() => {
    const {
      liveViewOpened,
      augiDisabled,
      augiEnabled,
      privacyModeOn,
      privacyModeOff,
    } = userEvents || {};

    const eventMarks: MarkData[] = [];
    const notifMarks: MarkData[] = [];
    const liveViewOpenMarks: MarkData[] = [];
    const augiDisabledMarks: MarkData[] = [];
    const privacyModeEnabledMarks: MarkData[] = [];
    const offlineMarks: MarkData[] = [];

    offlineEvents?.forEach((event) => {
      const start = new Date(event.start);

      // if the event starts before the start date, set the time position to 1
      const timePosition =
        startDate && start < startDate ? 1 : getPositionFromDate(images, start);

      // if the event ends after the end date, set the end position to the last image
      const end = event.end ? new Date(event.end) : new Date();
      const endPosition = getPositionFromDate(images, end);

      // the span is the difference between the start and end positions
      if (timePosition && endPosition) {
        offlineMarks.push({
          value: timePosition,
          time: start,
          label: 'Offline Event',
          type: 'offlineEvent',
          span: endPosition - timePosition,
        });
      }
    });

    liveViewOpened?.forEach((event) => {
      const time = new Date(event.time);
      const timePosition = getPositionFromDate(images, time);

      if (timePosition) {
        liveViewOpenMarks.push({
          value: timePosition,
          time,
          label: 'Live View Opened',
          type: 'userEvent',
        });
      }
    });

    // get the most recent augiDisabled and augiEnabled events
    const mostRecentAugiDisabled = augiDisabled?.at(0)?.time;
    const mostRecentAugiEnabled = augiEnabled?.at(0)?.time;

    // if there are no augiDisabled events or the most recent augiDisabled event is after the most recent augiEnabled event
    if (
      ((isEmpty(augiDisabled) && !isEmpty(augiEnabled)) ||
        (!isEmpty(augiDisabled) &&
          !isEmpty(augiEnabled) &&
          new Date(mostRecentAugiDisabled || 0) >
            new Date(mostRecentAugiEnabled || 0))) &&
      mostRecentAugiEnabled
    ) {
      const time = new Date(mostRecentAugiEnabled);
      const timePosition = getPositionFromDate(images, time);

      // the span is from start to the next augiEnabled event
      if (timePosition) {
        augiDisabledMarks.push({
          value: 0,
          time,
          label: 'AUGi Disabled',
          type: 'userEvent',
          span: timePosition - 0,
        });
      }
    } else {
      augiDisabled?.forEach((event) => {
        const time = new Date(event.time);
        const timePosition = getPositionFromDate(images, time);

        const nextAugiEnabled = augiEnabled?.find(
          (e) => new Date(e.time) > time,
        );

        const nextAugiEnabledTime = nextAugiEnabled
          ? new Date(nextAugiEnabled.time)
          : new Date();

        const nextAugiEnabledPosition = getPositionFromDate(
          images,
          nextAugiEnabledTime,
        );

        if (timePosition) {
          augiDisabledMarks.push({
            value: timePosition,
            time,
            label: 'AUGi Disabled',
            type: 'userEvent',
            span: nextAugiEnabledPosition! - timePosition,
          });
        }
      });
    }

    // get the most recent privacyModeOn and privacyModeOff events
    const mostRecentPrivacyModeOn = privacyModeOn?.at(0)?.time;
    const mostRecentPrivacyModeOff = privacyModeOff?.at(0)?.time;

    // if there are no privacyModeOn events or the most recent privacyModeOff event is after the most recent privacyModeOn event
    if (
      (isEmpty(privacyModeOn) && !isEmpty(privacyModeOff)) ||
      (!isEmpty(privacyModeOn) &&
        !isEmpty(privacyModeOff) &&
        new Date(mostRecentPrivacyModeOff || 0) >
          new Date(mostRecentPrivacyModeOn || 0))
    ) {
      const time = new Date(mostRecentPrivacyModeOff || 0);
      const timePosition = getPositionFromDate(images, time);

      if (timePosition) {
        privacyModeEnabledMarks.push({
          value: 0,
          time,
          label: 'Virtual Curtain Enabled',
          type: 'userEvent',
          span: timePosition - 0,
        });
      }
    } else {
      privacyModeOn?.forEach((event) => {
        const time = new Date(event.time);
        const timePosition = getPositionFromDate(images, time);

        const nextPrivacyModeOff = privacyModeOff?.find(
          (e) => new Date(e.time) > time,
        );

        const nextPrivacyModeOn = privacyModeOn?.find(
          (e) => new Date(e.time) > time,
        );

        let nextPrivacyModeOffTime = nextPrivacyModeOff
          ? new Date(nextPrivacyModeOff.time)
          : new Date();

        // If there is no "off" event or the next "on" event is within 2 minutes of the current "on" event,
        // set the end of the span to be 2 minutes after the "on" event.
        const isNextOnWithin2Minutes =
          nextPrivacyModeOn?.time &&
          new Date(nextPrivacyModeOn.time).getTime() - time.getTime() <=
            2 * 60 * 1000;

        if (!nextPrivacyModeOff || isNextOnWithin2Minutes) {
          nextPrivacyModeOffTime = new Date(
            new Date(event.time).getTime() + 2 * 60 * 1000,
          );
        }

        const nextPrivacyModeOffPosition = getPositionFromDate(
          images,
          nextPrivacyModeOffTime,
        );

        if (timePosition) {
          privacyModeEnabledMarks.push({
            value: timePosition,
            time,
            label: 'Virtual Curtain Enabled',
            type: 'userEvent',
            span: nextPrivacyModeOffPosition! - timePosition,
          });
        }
      });
    }

    if (showStaffEventMarks) {
      events?.forEach((event) => {
        const staffName =
          event.staff === 'Unassigned' ||
          !event.staffFirstName ||
          !event.staffLastName
            ? 'Staff member'
            : `${event.staffFirstName} ${event.staffLastName}`;

        if (event.timeIn) {
          const timeIn = new Date(event.timeIn);

          if (startDate && timeIn >= startDate) {
            const timeInPosition = getPositionFromDate(images, timeIn);

            if (timeInPosition) {
              eventMarks.push({
                value: timeInPosition,
                time: timeIn,
                label: `${staffName} entered`,
                type: 'staff-event',
              });
            }
          }
        }

        if (event.timeOut) {
          const timeOut = new Date(event.timeOut);

          if (endDate && timeOut <= endDate) {
            const timeOutPosition = getPositionFromDate(images, timeOut);

            if (timeOutPosition) {
              eventMarks.push({
                value: timeOutPosition,
                time: timeOut,
                label: `${staffName} left`,
                type: 'staff-event',
              });
            }
          }
        }
      });
    }

    if (showNotifMarks) {
      notifications?.forEach((notif) => {
        const promoted = new Date(notif.promotedOn);

        const getResolvedLabel = () =>
          notif.resolvedByName === 'expired'
            ? 'Notification expired'
            : `Notification resolved by ${
                isNil(roleMap[notif?.resolvedByRoleId || '']?.displayName)
                  ? 'system'
                  : notif.resolvedByName
              }`;

        if (startDate && promoted >= startDate) {
          const sentAtPosition = getPositionFromDate(images, promoted);

          if (sentAtPosition) {
            notifMarks.push({
              value: sentAtPosition,
              time: promoted,
              label: `${capitalize(lowerCase(notif.type))} notification sent`,
              type: 'notification',
            });
          }
        }

        if (notif.resolvedAt) {
          const resolved = new Date(notif.resolvedAt);

          if (endDate && resolved <= endDate) {
            const resolvedAtPosition = getPositionFromDate(images, resolved);

            if (resolvedAtPosition) {
              notifMarks.push({
                value: resolvedAtPosition,
                time: resolved,
                label: getResolvedLabel(),
                type: 'notification',
              });
            }
          }
        }
      });
    }

    const allMarks = sortBy(
      [...eventMarks, ...notifMarks, ...liveViewOpenMarks],
      'value',
    );

    const spaceTreshold = 12;

    // if their are any other marks within the span of a offlineMark remove them
    // reason: offline marks are the most important and should be visible and no other marks should be visible within the span of a offline mark
    offlineMarks.forEach((offlineMark) => {
      allMarks.forEach((mark) => {
        if (
          mark.value >= offlineMark.value &&
          mark.value <= offlineMark.value + offlineMark.span!
        ) {
          allMarks.splice(allMarks.indexOf(mark), 1);
        }
      });

      // same for augiDisabledMarks and privacyModeEnabledMarks
      augiDisabledMarks.forEach((augiDisabledMark) => {
        if (
          augiDisabledMark.value >= offlineMark.value &&
          augiDisabledMark.value <= offlineMark.value + offlineMark.span!
        ) {
          augiDisabledMarks.splice(
            augiDisabledMarks.indexOf(augiDisabledMark),
            1,
          );
        }
      });

      privacyModeEnabledMarks.forEach((privacyModeEnabledMark) => {
        if (
          privacyModeEnabledMark.value >= offlineMark.value &&
          privacyModeEnabledMark.value <= offlineMark.value + offlineMark.span!
        ) {
          privacyModeEnabledMarks.splice(
            privacyModeEnabledMarks.indexOf(privacyModeEnabledMark),
            1,
          );
        }
      });
    });

    // same for augiDisabledMarks
    augiDisabledMarks.forEach((augiDisabledMark) => {
      allMarks.forEach((mark) => {
        if (
          mark.value >= augiDisabledMark.value &&
          mark.value <= augiDisabledMark.value + augiDisabledMark.span!
        ) {
          allMarks.splice(allMarks.indexOf(mark), 1);
        }
      });

      privacyModeEnabledMarks.forEach((privacyModeEnabledMark) => {
        if (
          privacyModeEnabledMark.value >= augiDisabledMark.value &&
          privacyModeEnabledMark.value <=
            augiDisabledMark.value + augiDisabledMark.span!
        ) {
          privacyModeEnabledMarks.splice(
            privacyModeEnabledMarks.indexOf(privacyModeEnabledMark),
            1,
          );
        }
      });
    });

    // same for privacyModeEnabledMarks
    privacyModeEnabledMarks.forEach((privacyModeEnabledMark) => {
      allMarks.forEach((mark) => {
        if (
          mark.value >= privacyModeEnabledMark.value &&
          mark.value <=
            privacyModeEnabledMark.value + privacyModeEnabledMark.span!
        ) {
          allMarks.splice(allMarks.indexOf(mark), 1);
        }
      });
    });

    const finalMarks = collapseCrowdedMarks(
      allMarks,
      spacePerMark,
      spaceTreshold,
    );

    // augi offline, augi disabled, virtual curtain enabled
    return [
      ...offlineMarks,
      ...augiDisabledMarks,
      ...privacyModeEnabledMarks,
      ...finalMarks,
    ];
  }, [
    images,
    events,
    notifications,
    userEvents,
    offlineEvents,
    showStaffEventMarks,
    showNotifMarks,
    spacePerMark,
    startDate,
    endDate,
  ]);

  return marks;
};

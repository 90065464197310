import { useState } from 'react';
import {
  Box,
  Button,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Slider,
  Stack,
  TextField,
  Toolbar,
} from '@mui/material';
import {
  Check,
  Edit,
  KeyboardArrowDown,
  Search,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material';
import { sortBy } from 'lodash';

import { SORT_OPTIONS, SortMethod } from '@inspiren-monorepo/shared-types';

import { UnitDropdown } from './UnitDropdown';
import ViewMenu, { ViewOptions } from './ViewMenu';

import { ViewMode } from '../../../../types';
import { useCurrentUser } from '../../../HOC/CurrentUserContextProvider';
import { sendAmpEvent } from '../../../utility/amplitude';

interface Props {
  sortingMethod: SortMethod;
  onSortingMethodChange: (sortMethod: SortMethod) => void;
  searchBarText: string;
  onSearchBarTextChange: (text: string) => void;
  onRoomSizeChange: (size: number) => void;
  viewMode: ViewMode;
  onViewModeChange: (mode: ViewMode) => void;
  selectedUnit: string;
  onSelectedUnitChange: (unit: string) => void;
}

const sortOptions = sortBy(
  SORT_OPTIONS.filter(({ value }) => value !== 'smart'),
  'label',
);

const Filterbar = ({
  sortingMethod,
  onSortingMethodChange,
  searchBarText,
  onSearchBarTextChange,
  onRoomSizeChange,
  viewMode,
  onViewModeChange,
  selectedUnit,
  onSelectedUnitChange,
}: Props) => {
  const { user } = useCurrentUser();

  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);
  const sortOpen = Boolean(sortAnchorEl);

  const staffOptions: ViewOptions = [
    { label: 'Monitor View', periscopeMode: 'monitor' },
  ];

  const viewOptions = staffOptions;

  if (user?.ability?.can('view', 'virtual-care.rooms.telesitter')) {
    viewOptions.push({ label: 'Telesitter View', periscopeMode: 'telesitter' });
  }

  const handleCustomViewButtonClick = () => {
    sendAmpEvent('Custom View Button Clicked', {});
  };

  return (
    <Toolbar sx={{ gap: 1 }}>
      <Box sx={{ width: 240 }}>
        <Stack spacing={2} direction='row' sx={{ mr: 2 }} alignItems='center'>
          <ZoomOut />
          <Slider
            min={280}
            max={750}
            defaultValue={280}
            aria-label='Default'
            valueLabelDisplay='off'
            onChange={(_e, newValue) => {
              onRoomSizeChange(newValue as number);
              sendAmpEvent('Rooms Dynamic Sizing', { size: newValue });
            }}
          />
          <ZoomIn />
        </Stack>
      </Box>

      {viewOptions.length > 1 && (
        <ViewMenu
          periscopeMode={viewMode}
          viewOptions={viewOptions}
          onViewModeChange={onViewModeChange}
        />
      )}
      <UnitDropdown
        onSelectedUnitChange={onSelectedUnitChange}
        selectedUnit={selectedUnit}
      />
      <Box sx={{ mr: 'auto' }}>
        <Button
          variant='outlined'
          startIcon={<Edit />}
          href='manage'
          onClick={handleCustomViewButtonClick}
        >
          Custom View
        </Button>
      </Box>
      <Box>
        <Button
          onClick={(e) => {
            setSortAnchorEl(e.currentTarget);
          }}
          endIcon={<KeyboardArrowDown />}
          variant='contained'
          id='sort-button'
          aria-controls={sortOpen ? 'sort-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={sortOpen ? 'true' : undefined}
        >
          Sort by{' '}
          {sortOptions.find(({ value }) => value === sortingMethod)?.label}
        </Button>
        <Menu
          id='sort-menu'
          anchorEl={sortAnchorEl}
          open={sortOpen}
          onClose={() => setSortAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'sort-button',
          }}
        >
          {sortOptions.map(({ label, value }) => (
            <MenuItem
              key={value}
              selected={value === sortingMethod}
              onClick={() => {
                onSortingMethodChange(value);
                sendAmpEvent('Sorted Rooms By', { sort: value });
              }}
            >
              {value === sortingMethod && (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              )}
              <ListItemText inset={value !== sortingMethod}>
                {label}
              </ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Box>
        <TextField
          size='small'
          id='search-rooms'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            ),
          }}
          placeholder='Search...'
          value={searchBarText}
          onChange={(e) => {
            onSearchBarTextChange(e.target.value);
            sendAmpEvent('Rooms Search Box', { searched: e.target.value });
          }}
        />
      </Box>
    </Toolbar>
  );
};

export default Filterbar;

import { ILogger, ErrorFn } from '../types/ILogger';

export class NoopLogger implements ILogger {
  error: ErrorFn = () => {};
  warn(): void {}
  info(): void {}
  trace(): void {}
  debug(): void {}
  time(): void {}
  timeEnd(): void {}

  // TODO: Remove this! No adding unnecessary promises to the queue
  flushLogs(): Promise<void> {
    return Promise.resolve();
  }
}

import { useTracking } from 'react-tracking';

import { ingestUserEvents } from './data-access/ingestUserEvents';

import { UserEvent } from '../../../types';

export const useTrackUserEvent = () =>
  useTracking<UserEvent>(
    {
      time: new Date().toISOString(),
      app: 'virtual-care',
    },
    {
      dispatch: (event) => ingestUserEvents([event]),
    },
  );

import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Control, UseFormSetValue } from 'react-hook-form';

import ControlledPasswordInput from '../../../modals/ControlledPasswordInput';

interface Props {
  control: Control;
  setValue: UseFormSetValue<{ password?: string }>;
}

const ChangePassword = ({ control, setValue }: Props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      expanded={expanded}
      disableGutters
      elevation={0}
      sx={{
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        sx={{
          p: 0,
          '& .MuiAccordionSummary-content.Mui-expanded': {
            mb: 0,
          },
        }}
      >
        <FormControlLabel
          control={<Switch />}
          id='change-password'
          label='Change Password'
          checked={expanded}
          onChange={() => {
            setExpanded((prev) => !prev);
            setValue('password', '');
          }}
          sx={{ display: 'block', my: 1 }}
        />
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <ControlledPasswordInput
          id='password'
          label='New Password'
          control={control}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ChangePassword;

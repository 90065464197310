import { isEmpty } from 'lodash';

import { Api } from '../../../../../utility/client';

export async function updateBeacon(
  org: string,
  id: string,
  assignedTo?: string,
) {
  await Api.put(`/admin/v1/beacons/${id}`, {
    org: isEmpty(org) ? undefined : org,
    id,
    assignedTo: isEmpty(assignedTo) ? undefined : assignedTo,
  });
}

import { Chip, SxProps, Theme } from '@mui/material';

type Props = {
  label: string;
  size?: 'small' | 'medium' | 'large';
  bold?: boolean;
  sx?: SxProps<Theme>;
};

const fontSize = {
  small: undefined,
  medium: 15,
  large: 17,
};

const GeneralChip = ({
  label,
  size = 'medium',
  bold = false,
  sx = {},
}: Props) => (
  <Chip
    label={label}
    size={size === 'small' ? 'small' : 'medium'}
    sx={{
      ...sx,
      fontSize: fontSize[size],
      fontWeight: bold ? 500 : undefined,
      borderRadius: 100,
      height: size === 'large' ? 36 : undefined,
      '& .MuiChip-label': {
        px: size === 'large' ? 1.75 : undefined,
      },
    }}
  />
);

export default GeneralChip;

import { useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';

import { formatRole } from '../../../../../utility/helpers/helpers';
import ControlledDropdown from '../../../modals/ControlledDropdown';
import { RoleOption } from '../helpers/getRoleOptions';

interface Props {
  control: Control;
  items: RoleOption[];
}

/**
 * This component disables the dropdown if the role is not one the user can create/edit.
 */

const SelectRole = ({ control, items }: Props) => {
  const { role } = useWatch({ control });

  const unavailableRole = useMemo(
    () => !items.map((item) => item.value).includes(role),
    [items, role],
  );

  return (
    <ControlledDropdown
      id='role'
      label='Role'
      items={
        unavailableRole ? [{ label: formatRole(role), value: role }] : items
      }
      control={control}
      disabled={unavailableRole}
      required
    />
  );
};

export default SelectRole;

import { Autocomplete, TextField } from '@mui/material';
import { Control, Controller, useWatch } from 'react-hook-form';

import { RoleMap } from '../../../../../../types';

interface Props {
  control: Control;
  roleMap: RoleMap;
}

export const SelectLevelAccess = ({ control, roleMap }: Props) => {
  const roleValue = useWatch({ name: 'role', control });
  return (
    <Controller
      name='levelAccess'
      control={control}
      defaultValue={null}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <Autocomplete
          id={name}
          data-testid='levelAccess'
          options={['building', 'org', 'unit']}
          value={value || null}
          onChange={(a, b) => onChange(b)}
          isOptionEqualToValue={(o, v) => o === v}
          filterOptions={(options) => options}
          renderOption={(props, option) => (
            <li {...props}>
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Level Access'
              error={Boolean(error)}
              required={roleMap[roleValue]?.template !== 'Admin'}
            />
          )}
        />
      )}
    />
  );
};

import { Stack } from '@mui/material';
import { GridCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import * as yup from 'yup';

import {
  ImportOrgSelect,
  ImportUnitSelect,
} from '../../ImportTable/components';
import { ImportableDataFields } from '../../ImportTable/types/importable';
import importUniqueValidator from '../../ImportTable/validators/importUniqueValidator';
import importUniqueRoomValidator from '../../RoomsTable/validators/importUniqueRoomValidator';
import { ImportRoomSelect } from '../components';
import { AssignmentHistory } from '../components/AssignmentHistory';
import { FieldTypes } from '../types/BasestationFieldTypes';
import importProperAssignmentValidator from '../validators/importProperAssignmentValidator';

const dropdownSchema = yup.string().when('assign', {
  is: true,
  then: (schema) => schema.required("Pick one or un-check 'Assign'"),
});

const virtualDropdownSchema = yup.string().when('virtual', {
  is: true,
  then: (schema) => schema.required("Pick one or un-check 'Virtual'"),
});

const formatUnassigned = (params: GridValueFormatterParams<string>) => {
  if (params.value === '') return 'unassigned';
  return params.value;
};

const unassignedClassName = (params: GridCellParams<string>) => {
  if (params.value === '') return 'basestations unassigned';
  return '';
};

export const basestationsTableFields: ImportableDataFields<FieldTypes> = [
  {
    field: 'id',
    label: 'ID',
    width: 150,
    editType: 'text',
    editable: false,
    schema: yup
      .string()
      .required('You must provide an ID')
      .matches(
        /^[\da-z]+$/i,
        'ID can only include letter and number characters',
      ),
    onImportCellEditValidators: [importUniqueValidator],
  },
  {
    field: 'assign',
    label: 'Assign Basestation to room',
    width: 'hidden',
    editType: 'special',
    initialValue: true,
    schema: yup.boolean(),
  },
  {
    field: 'organization',
    label: 'Organization',
    width: 150,
    editType: 'special',
    schema: dropdownSchema,
    valueFormatter: formatUnassigned,
    cellClassName: unassignedClassName,
    importType: 'select',
    renderImportCell: (params) => (
      <ImportOrgSelect {...params} allowEmptyValue />
    ),
  },
  {
    field: 'buildingFloorUnit',
    label: 'Building-Floor-Unit',
    width: 190,
    editType: 'special',
    schema: dropdownSchema,
    valueFormatter: formatUnassigned,
    cellClassName: unassignedClassName,
    importType: 'select',
    renderImportCell: (params) => <ImportUnitSelect {...params} />,
    onImportCellEditValidators: [importProperAssignmentValidator],
  },
  {
    field: 'room',
    label: 'Room',
    width: 160,
    editType: 'special',
    schema: dropdownSchema,
    valueFormatter: formatUnassigned,
    cellClassName: unassignedClassName,
    renderCell: ({ id, formattedValue }) => (
      <Stack
        sx={{ width: '100%' }}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        {formattedValue}
        <AssignmentHistory basestationId={id as string} />
      </Stack>
    ),

    importType: 'select',
    importLabel: 'Room ID',
    renderImportCell: (params) => <ImportRoomSelect {...params} />,
    onImportCellEditValidators: [
      importUniqueRoomValidator(
        'Room is already assigned to other basestation',
      ),
      importProperAssignmentValidator,
    ],
  },
  { field: 'status', label: 'Status', width: 110, editType: 'text' },
  {
    field: 'provisioningStatus',
    label: 'Provisioning Status',
    width: 200,
    editType: 'text',
  },
  { field: 'version', label: 'Version', width: 120, editType: 'text' },
  {
    field: 'virtual',
    label: 'Virtual',
    initialValue: false,
    schema: yup.boolean(),
    editType: 'special',
    importType: 'boolean',
    width: 80,
    flex: 0,
  },
  {
    field: 'scenario',
    label: 'Scenario',
    editType: 'special',
    schema: virtualDropdownSchema,
    width: 200,
  },
];

import classifyPt from 'robust-point-in-polygon';

import { RoomRegion } from '@inspiren-monorepo/hilq/api-contracts';

import { colors } from './colors';

export const convertToAbsolute = (
  pt: RoomRegion,
  width: number,
  height: number,
) => {
  if ((!pt.x && pt.x !== 0) || (!pt.y && pt.y !== 0)) return pt;
  if (pt.x > 1) return pt;
  return {
    x: pt.x * width,
    y: pt.y * height,
    region: pt.region,
  };
};

export const getRegionColor = (region: string) => {
  switch (region) {
    case 'bed':
      return colors.regionBed;
    case 'chair':
      return colors.regionChair;
    case 'bedEdge':
      return colors.regionBedEdge;
    case 'ignore':
      return colors.regionIgnore;
    case 'exit':
      return colors.regionExit;
    default:
      return colors.regionChair;
  }
};

export const drawRegion = (
  ctx: CanvasRenderingContext2D,
  points: RoomRegion[],
  region: string,
) => {
  const color = getRegionColor(region);
  ctx.beginPath();
  ctx.strokeStyle = color.stroke;
  ctx.lineWidth = 3;
  ctx.lineJoin = 'round';

  points.forEach((point) => {
    ctx.lineTo(point.x, point.y);
    ctx.arc(point.x, point.y, 5, Math.PI * 2, 0, false);
  });

  ctx.closePath();
  ctx.stroke();
  ctx.fillStyle = color.fill;
  ctx.fill();
};

// this is a helper function to check if a point is within 15 pixels of another point
export const isThereAPointWithin15Pixels = (
  allPoints: RoomRegion[],
  x: number,
  y: number,
) => {
  const pointsWithin15Pixels = allPoints.filter((point) => {
    const xDiff = Math.abs(point.x - x);
    const yDiff = Math.abs(point.y - y);
    return xDiff < 15 && yDiff < 15;
  });

  return pointsWithin15Pixels;
};

// this is a helper function
// it maps the region points to be in the same format expected for the classifyPt function
// the classifyPoint function will take array and mouse x,y positon and determine if this point is within the polygon
// TODO: needs to be updated to support multiple regions
export const getDragRegion = (
  chairPoints: RoomRegion[],
  bedPoints: RoomRegion[],
  bedEdgePoints: RoomRegion[],
  x: number,
  y: number,
) => {
  const bedClassifyPoints = bedPoints.map((point) => [point.x, point.y]);

  const bedEdgeClassifyPoints = bedEdgePoints.map((point) => [
    point.x,
    point.y,
  ]);

  const chairClassifyPoints = chairPoints.map((point) => [point.x, point.y]);
  const bedClassify = classifyPt(bedClassifyPoints as any, [x, y]);
  const bedEdgeClassify = classifyPt(bedEdgeClassifyPoints as any, [x, y]);
  const chairClassify = classifyPt(chairClassifyPoints as any, [x, y]);

  if (bedClassify === -1) {
    return 'bed';
  }

  if (chairClassify === -1) {
    return 'chair';
  }

  if (bedEdgeClassify === -1) {
    return 'bedEdge';
  }

  return '';
};

export const movePoint = (point: RoomRegion, moveX: number, moveY: number) => ({
  ...point,
  x: point.x + moveX,
  y: point.y + moveY,
});

// this helper function will add the distance of the mouse moved to the current set points
export const addDistanceTravelled = (
  points: RoomRegion[],
  x: number,
  y: number,
) => points.map((point) => movePoint(point, x, y));
// this helper function will take all the region points and divide them by the width and height of the canvas
export const convertToRelative = (
  points: RoomRegion[],
  width: number,
  height: number,
) =>
  points.map((point) => ({
    x: point.x / width,
    y: point.y / height,
    region: point.region,
  }));

import {
  GridColDef,
  GridEditBooleanCell,
  GridEditInputCell,
  GridEditSingleSelectCell,
  GridRenderEditCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid';

import { DataFieldEditType } from '../../../types/DataFields';
import ErrorTooltip from '../components/ErrorTooltip';
import { IsDisabledOnImport } from '../types/importable';

type Map = { [key in DataFieldEditType]?: GridColDef['renderEditCell'] };

const renderMap: Map = {
  text: GridEditInputCell,
  boolean: GridEditBooleanCell,
  select: GridEditSingleSelectCell,
};

const getTitleSection = (error: unknown): React.ReactNode | undefined => {
  if (Array.isArray(error)) {
    return (
      <>
        {error.map((e) => (
          <div>{e}</div>
        ))}
      </>
    );
  }

  if (typeof error === 'string') {
    return error;
  }

  return undefined;
};

export const buildRenderEditCell =
  <TFieldTypes extends GridValidRowModel>(
    type: DataFieldEditType,
    customRender?: GridColDef['renderEditCell'],
    disabledOnImport?: IsDisabledOnImport<TFieldTypes>,
  ): GridColDef['renderEditCell'] =>
  (params: GridRenderEditCellParams) => {
    const { error, id } = params;

    const Component: GridColDef['renderEditCell'] =
      customRender ?? renderMap[type] ?? renderMap.text;

    if (!Component) {
      return undefined;
    }

    const disabled = disabledOnImport?.(params) ?? false;

    return (
      <ErrorTooltip
        key={`row-${id}-errorTooltip`}
        title={getTitleSection(error)}
        arrow
        placement='right'
      >
        <div
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <Component {...params} error={!!error} disabled={disabled} />
        </div>
      </ErrorTooltip>
    );
  };

import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { isNil } from 'lodash';

import { AudibleMessageStatus } from '../../../../../types';

const MESSAGES = {
  end_ok: 'The message was sent successfully.',
  already_playing: 'The message was sent successfully.',
  unknown_sound_file: 'AUGi could not find the given sound.',
  in_use: 'This AUGi is currently playing another message.',
  not_supported: 'This AUGi does not support audible messages.',
  internal_error: 'Internal error. Support has been notified.',
} as Record<string, string>;

interface Props {
  audibleMessageStatus: AudibleMessageStatus | null;
}

export const RoomAudibleMessageModalResult = ({
  audibleMessageStatus,
}: Props) => {
  const theme = useTheme();

  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      sx={{ flex: 1 }}
    >
      {(isNil(audibleMessageStatus) || audibleMessageStatus === 'begin_ok') && (
        <>
          <Typography sx={{ mb: 2 }}>Sending message</Typography>
          <CircularProgress />
        </>
      )}
      {audibleMessageStatus &&
        ['end_ok', 'already_playing'].includes(audibleMessageStatus) && (
          <>
            <Typography
              sx={{
                mb: 2,
                color: theme.palette.success.main,
                textAlign: 'center',
              }}
            >
              {MESSAGES[audibleMessageStatus]}
            </Typography>
            <CheckCircleOutlineIcon
              sx={{ color: theme.palette.success.main, fontSize: '48px' }}
            />
          </>
        )}
      {audibleMessageStatus &&
        !['end_ok', 'already_playing', 'begin_ok'].includes(
          audibleMessageStatus,
        ) && (
          <>
            <Typography
              sx={{
                mb: 2,
                color: theme.palette.error.main,
                textAlign: 'center',
              }}
            >
              {MESSAGES[audibleMessageStatus] ||
                'Something went wrong. Please try again later.'}
            </Typography>
            <ErrorOutlineIcon
              sx={{ color: theme.palette.error.main, fontSize: '48px' }}
            />
          </>
        )}
    </Stack>
  );
};

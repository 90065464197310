import { Grid, TextField, Tooltip, Typography } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

type Props = {
  id: string;
  label: string;
  control: Control;
  disabled?: boolean;
  placeholder?: string;
  tooltip?: string;
  setValue?: (name: string, value: string) => void;
  properties?: string[];
};

const ControlledTextFieldGroup = ({
  id,
  label,
  control,
  disabled = false,
  placeholder,
  tooltip,
  setValue,
  properties,
}: Props) => (
  <Controller
    name={id}
    control={control}
    render={({ field: { value }, fieldState: { error } }) => (
      <Tooltip
        title={tooltip}
        placement='left'
        disableHoverListener={!tooltip}
        disableInteractive={!tooltip}
        disableFocusListener={!tooltip}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <Typography variant='subtitle2'>{label}</Typography>
          </Grid>
          {properties?.map((property) => (
            <Grid item xs={12} sm={4} key={property}>
              <TextField
                key={property}
                label={property}
                margin='dense'
                type='text'
                variant='outlined'
                defaultValue={value[property]}
                onChange={(evt) => {
                  if (setValue) {
                    setValue(`${id}.${property}`, evt.target.value);
                  }
                }}
                disabled={disabled}
                error={Boolean(error)}
                helperText={error?.message}
                placeholder={placeholder}
                InputLabelProps={{
                  shrink: Boolean(placeholder) || undefined,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Tooltip>
    )}
  />
);

export default ControlledTextFieldGroup;

import { useCallback, useMemo, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { SortMethod } from '@inspiren-monorepo/shared-types';
import { parseRoomId, sortRooms } from '@inspiren-monorepo/util-rooms';

import Filterbar from './Filterbar';
import RoomCard from './RoomCard';
import { BuildingUnitDisplayHeader } from './components/BuildingUnitDisplayHeader';
import { useAudibleMessagesStatus } from './hooks/useAudibleMessagesStatus';
import { useRooms } from './hooks/useRooms';
import { useUnits } from './hooks/useUnits';

import { ViewMode, RoomWithBaseId } from '../../../types';
import { useCurrentUser } from '../../HOC/CurrentUserContextProvider';
import Loading from '../../screens/Loading';
import { sendAmpEvent } from '../../utility/amplitude';
import { requestClearImagesFromBasestation } from '../../utility/mqtt';

const SELECTED_UNIT_LOCAL_STORAGE_KEY = 'dropdownSelection';

const RoomsView = () => {
  const { user } = useCurrentUser();

  const { units, unitsIsLoading } = useUnits();

  const [selectedUnit, setSelectedUnit] = useState<string>(
    localStorage.getItem(SELECTED_UNIT_LOCAL_STORAGE_KEY) || user?.unit || '',
  );

  const [sortingMethod, setSortingMethod] = useState<SortMethod>('numeric');
  const [viewMode, setViewMode] = useState<ViewMode>('monitor');
  const [searchBarText, setSearchBarText] = useState<string>('');
  const [roomSize, setRoomSize] = useState<number>(280);

  const { data, isLoading: roomsIsLoading } = useRooms(selectedUnit);

  const rooms = data?.rooms || [];

  const sortedRooms = useMemo(() => {
    if (!isEmpty(searchBarText)) {
      const filteredRooms = rooms.filter((room: RoomWithBaseId) =>
        room.name.toLowerCase().includes(searchBarText.toLowerCase().trim()),
      );

      return sortRooms(filteredRooms, sortingMethod);
    }

    return sortRooms(rooms, sortingMethod);
  }, [rooms, sortingMethod, searchBarText]);

  /*
  {
    'unitId1': RoomWithBaseId[],
    'unitId2': RoomWithBaseId[],
  }
 */
  const sortedRoomsWithUnits = useMemo(() => {
    const unitsMap: { [key: string]: RoomWithBaseId[] } = {};

    sortedRooms.forEach((room) => {
      const { org, building, floor, unit } = parseRoomId(room.mainId);
      const unitId = `Room-${org}-${building}-${floor}-${unit}`;

      if (!unitsMap[unitId]) {
        unitsMap[unitId] = [];
      }

      unitsMap[unitId].push(room);
    });

    return unitsMap;
  }, [sortedRooms]);

  const groupedUnitsWithRooms = useMemo(
    () => Object.keys(sortedRoomsWithUnits),
    [sortedRoomsWithUnits],
  );

  const handleSelectedUnitChange = useCallback((unitId: string) => {
    setSelectedUnit(unitId);
    localStorage.setItem(SELECTED_UNIT_LOCAL_STORAGE_KEY, unitId);

    sendAmpEvent('Select Unit Dropdown', {
      unit: unitId,
    });
  }, []);

  const handleSortingMethodChange = useCallback(
    (sortMethod: SortMethod) => setSortingMethod(sortMethod),
    [],
  );

  const handleSearchBarTextChange = useCallback(
    (text: string) => setSearchBarText(text),
    [],
  );

  const handleViewModeChange = useCallback(
    (mode: ViewMode) => {
      if (mode === 'telesitter') {
        rooms.forEach((room: RoomWithBaseId) => {
          const { org } = parseRoomId(room.mainId);
          requestClearImagesFromBasestation(org, room.baseId);
        });
      }

      setViewMode(mode);
    },
    [rooms],
  );

  const handleRoomSizeChange = useCallback((size: number) => {
    setRoomSize(size);
  }, []);

  const { playingAudibleMessages } = useAudibleMessagesStatus();

  if (unitsIsLoading) return <Loading />;

  return (
    <Box sx={{ gap: 1 }}>
      <Filterbar
        sortingMethod={sortingMethod}
        onSortingMethodChange={handleSortingMethodChange}
        searchBarText={searchBarText}
        onSearchBarTextChange={handleSearchBarTextChange}
        onRoomSizeChange={handleRoomSizeChange}
        viewMode={viewMode}
        onViewModeChange={handleViewModeChange}
        selectedUnit={selectedUnit}
        onSelectedUnitChange={handleSelectedUnitChange}
      />
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          p: 1,
        }}
      >
        {groupedUnitsWithRooms.map((unitId) => (
          <Box
            key={unitId}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 1,
            }}
          >
            <BuildingUnitDisplayHeader units={units} unitId={unitId} />
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                p: 1,
              }}
            >
              {sortedRoomsWithUnits[unitId].map((room) => (
                <RoomCard
                  key={room.mainId}
                  room={room}
                  playingAudibleMessage={
                    playingAudibleMessages[room.baseId] || false
                  }
                  roomSize={roomSize}
                  viewMode={viewMode}
                />
              ))}
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          p: 1,
        }}
      >
        {(!selectedUnit || sortedRooms.length === 0) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '75vh',
              gap: 1.5,
            }}
          >
            {!selectedUnit && (
              <Typography variant='h3' sx={{ color: 'grey.400', mb: 1 }}>
                Please select a unit
              </Typography>
            )}
            {selectedUnit &&
              sortedRooms.length === 0 &&
              (roomsIsLoading ? (
                <CircularProgress />
              ) : (
                <Typography variant='h3' sx={{ color: 'grey.400', mb: 1 }}>
                  {searchBarText === '' && selectedUnit === 'CUSTOM'
                    ? 'Looks like you need to add rooms'
                    : 'No rooms found'}
                </Typography>
              ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RoomsView;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useMemo } from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import * as Sentry from '@sentry/react';
import { Duration, format, formatDuration, intervalToDuration } from 'date-fns';
import { format as formatTZ } from 'date-fns-tz';
import { isEmpty, isNil } from 'lodash';

import ColorSwitch from './ColorSwitch';
import { DataGridBase } from './DataGridBase';
import DisabledTimeLink from './DisabledTimeLink';
import TableMenu from './TableMenu';
import TableRowMenu from './TableRowMenu';

import { Room } from '../../../../types';
import { sendAmpEvent } from '../../../utility/amplitude';
import {
  formatUserRole,
  makeEventReviewFilename,
} from '../../../utility/helpers/helpers';
import {
  dateTimeFormat,
  dateTimeFormatNoYear,
  getPositionFromDate,
  getTimestampOfNearestImage,
  timeFormat,
} from '../../../utility/helpers/time';
import { useOrganizationRoles } from '../../Admin/hooks/useOrganizationRoles';
import { formatDurationConcise } from '../helpers/formatDurationConcise';
import { gridDurationComparator } from '../helpers/gridDurationComparator';
import { useStaffEvents } from '../hooks/useStaffEvents';
import { useTimestamp } from '../hooks/useTimestamp';
import { useEventReviewStore } from '../store/EventReviewStore';

interface Props {
  showDate?: boolean;
  showRowMenu: boolean;
}

const EventsTable = ({ showDate = false, showRowMenu }: Props) => {
  const {
    selectedRoom,
    selectedOrg,
    startDate,
    endDate,
    images,
    showStaffEventMarks,
    setShowStaffEventMarks,
    setPosition,
  } = useEventReviewStore();

  const handleSetTimeStamp = useCallback(
    (timestamp: Date) => {
      if (isNil(images)) return;
      const position = getPositionFromDate(images, timestamp) || 0;
      setPosition(position);
    },
    [images, setPosition],
  );

  // TODO: Add field types

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'timeIn',
          headerName: 'Time in',
          width: 150,
          editable: false,
          valueFormatter: (params) => {
            const formatString = showDate ? dateTimeFormatNoYear : timeFormat;
            return format(params.value, formatString);
          },
          renderCell: (params: any) => {
            if (
              startDate &&
              params.value &&
              (params.value < startDate || isEmpty(images))
            ) {
              return (
                <DisabledTimeLink
                  tooltipText={
                    isEmpty(images) ? '' : 'Outside of selected range'
                  }
                >
                  {params.formattedValue}
                </DisabledTimeLink>
              );
            }

            return (
              <Link
                component='button'
                variant='body2'
                onClick={() => handleSetTimeStamp(params.value)}
                sx={{ fontWeight: 500 }}
              >
                {params.formattedValue}
              </Link>
            );
          },
        },
        {
          field: 'timeOut',
          headerName: 'Time out',
          width: 150,
          editable: false,
          valueFormatter(params) {
            if (!params.value) return '';
            const formatString = showDate ? dateTimeFormatNoYear : timeFormat;
            return format(params.value, formatString);
          },
          renderCell: (params: any) => {
            if (
              endDate &&
              params.value &&
              (params.value > endDate || isEmpty(images))
            ) {
              return (
                <DisabledTimeLink
                  tooltipText={
                    isEmpty(images) ? '' : 'Outside of selected range'
                  }
                >
                  {params.formattedValue}
                </DisabledTimeLink>
              );
            }

            return (
              <Link
                component='button'
                variant='body2'
                onClick={() => handleSetTimeStamp(params.value)}
                sx={{ fontWeight: 500 }}
              >
                {params.formattedValue}
              </Link>
            );
          },
        },
        {
          field: 'elapsed',
          headerName: 'Time elapsed',
          width: 150,
          editable: false,
          valueFormatter: (params) => {
            const duration = params.value as Duration | null;
            return isNil(duration) ? '' : formatDurationConcise(duration);
          },
          sortComparator: gridDurationComparator,
        },
        {
          field: 'staffName',
          headerName: 'Name',
          width: 150,
          editable: false,
        },
        {
          field: 'staffRole',
          headerName: 'Role',
          width: 150,
          editable: false,
        },
        {
          field: 'id',
          headerName: '',
          editable: false,
          filterable: false,
          width: 80,
          hidden: !showRowMenu,
          renderCell: (params: any) => (
            <TableRowMenu
              id={params.value}
              eventStart={params.row.timeIn}
              eventEnd={params.row.timeOut}
            />
          ),
        },
      ] as GridColDef[],
    [startDate, endDate, images, showDate, showRowMenu],
  );

  const { data: events, isLoading, isError } = useStaffEvents();

  const timestamp = useTimestamp();
  const eventsExist = events && events.length > 0;
  const showToggle = eventsExist && !isNil(images) && images.length > 0;

  const timezone = formatTZ(new Date(), 'zzz');
  const { roleMap } = useOrganizationRoles(selectedOrg?.id);

  const staffEventsTableData = useMemo(
    () =>
      events?.map((e) => {
        const timeIn = isNil(e.timeIn) ? null : new Date(e.timeIn);
        const timeOut = isNil(e.timeOut) ? null : new Date(e.timeOut);

        return {
          id: `${e.timeIn}-${e.beaconEntryId || e.beacon || ''}`,
          // TODO: timeIn and timeOut are already Date objects, so this is unnecessary
          timeIn: timeIn ? new Date(timeIn) : null,
          timeOut: timeOut ? new Date(timeOut) : null,
          elapsed:
            timeIn &&
            timeOut &&
            intervalToDuration({ start: timeIn, end: timeOut }),
          staffName:
            e.staff === 'Unassigned'
              ? 'Unassigned'
              : e.staffFirstName &&
                e.staffLastName &&
                `${e.staffFirstName} ${e.staffLastName}`,
          staffRole: formatUserRole(
            e.staffRoleId && e.staffRoleId,
            roleMap,
            e.staffRole && e.staffRole,
          ),
        };
      }) || [],
    [events, roleMap],
  );

  const csvData = useMemo(
    () =>
      events?.map((e) => {
        const timeIn = isNil(e.timeIn) ? null : new Date(e.timeIn);
        const timeOut = isNil(e.timeOut) ? null : new Date(e.timeOut);

        return {
          [`Time in (${timezone})`]: timeIn && format(timeIn, dateTimeFormat),
          [`Time out (${timezone})`]:
            timeOut && format(timeOut, dateTimeFormat),
          'Time elapsed':
            timeIn &&
            timeOut &&
            formatDuration(
              intervalToDuration({
                start: timeIn,
                end: timeOut,
              }),
            ),
          'Staff name':
            e.staff === 'Unassigned'
              ? 'Unassigned'
              : e.staffFirstName &&
                e.staffLastName &&
                `${e.staffFirstName} ${e.staffLastName}`,
          'Staff role': formatUserRole(
            e.staffRoleId && e.staffRoleId,
            roleMap,
            e.staffRole && e.staffRole,
          ),
        };
      }) || [],
    [events, timezone, roleMap],
  );

  const activeRows = useMemo(() => {
    if (!events) return [];

    return events?.map((event) => {
      const timeInImage =
        (event.timeIn &&
          getTimestampOfNearestImage(images, new Date(event.timeIn))) ||
        null;

      const timeOutImage =
        (event.timeOut &&
          getTimestampOfNearestImage(images, new Date(event.timeOut))) ||
        null;

      const active =
        timestamp &&
        timeInImage &&
        timeOutImage &&
        timestamp >= timeInImage &&
        timestamp <= timeOutImage;

      if (active) return event.timeIn;
      return null;
    });
  }, [events, images, timestamp]);

  const theme = useTheme();
  const backgroundColor = theme.palette.staffEvent.light;

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center' }}
        data-testid='eventreview-eventstable-header'
      >
        <Typography variant='h5' component='h3' p={1.5}>
          Staff Events
        </Typography>
        <ColorSwitch
          checked={showToggle ? showStaffEventMarks : false}
          disabled={!showToggle}
          onChange={() => {
            setShowStaffEventMarks(!showStaffEventMarks);

            sendAmpEvent('Toggled Staff Events', {
              toggle: showStaffEventMarks,
            });
          }}
          inputProps={{ 'aria-label': 'controlled' }}
          size='small'
          customColor={(theme) => theme.palette.staffEvent.main}
        />
        {eventsExist && (
          <TableMenu
            id='staff-events'
            csvData={csvData}
            filename={
              selectedRoom &&
              startDate &&
              endDate &&
              makeEventReviewFilename(
                'Staff_Events',
                selectedRoom as Room,
                startDate,
                endDate,
              )
            }
          />
        )}
      </Box>
      <DataGridBase
        noRowsLabel='No staff events in range'
        rows={staffEventsTableData}
        columns={columns}
        loading={isLoading}
        error={isError}
        getRowClassName={(params) => {
          const date = new Date(params.row.timeIn);

          if (activeRows.includes(date.toISOString())) {
            return 'row-active--true';
          }

          return '';
        }}
        backgroundColor={backgroundColor}
      />
    </>
  );
};

export default Sentry.withProfiler(EventsTable);

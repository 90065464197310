import { useCallback, useMemo, useState } from 'react';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import { Add, Download, UploadFile } from '@mui/icons-material';
import {
  GridCsvExportOptions,
  GridToolbarContainer,
  GridValidRowModel,
  useGridApiContext,
} from '@mui/x-data-grid';
import { capitalize } from 'lodash';
import toast from 'react-hot-toast';
import { useCSVReader } from 'react-papaparse';

import { ImportableDataFields } from '../types/importable';

interface ImportTableToolbarProps<TFieldTypes extends GridValidRowModel> {
  onAddRow: () => void;
  onCsvImportAccepted: (data: string[][]) => Promise<void>;
  loading?: boolean;
  itemName: string;
  fields: ImportableDataFields<TFieldTypes>;
}

const ImportTableToolbar = <TFieldTypes extends GridValidRowModel>({
  onAddRow,
  onCsvImportAccepted,
  loading,
  itemName,
  fields,
}: ImportTableToolbarProps<TFieldTypes>) => {
  const [imported, setImported] = useState(false);

  const apiRef = useGridApiContext();
  const { CSVReader } = useCSVReader();

  const { hasRows, exportBlocked } = useMemo(
    () => ({
      hasRows: apiRef.current.state.rows.totalRowCount > 0,
      exportBlocked:
        Object.keys(apiRef.current.state.editRows || {}).length > 0,
    }),
    [apiRef.current.state],
  );

  const fieldsOrder = useMemo(
    () => fields?.map((e) => e.label) ?? [],
    [fields],
  );

  const onDownloadClick = useCallback(() => {
    const options: GridCsvExportOptions = {
      fileName: `${capitalize(itemName)}Import`,
      getRowsToExport: (params) => params.apiRef.current.getAllRowIds(),
    };

    apiRef.current.exportDataAsCsv(options);
  }, [apiRef.current, itemName]);

  const onUploadAccepted = useCallback(
    async (results: { data: string[][] }) => {
      try {
        await onCsvImportAccepted(results.data);
        setImported(true);
      } catch (error) {
        toast.error(`Failed to import CSV. ${(error as Error).message}`);

        setImported(false);
      }
    },
    [onCsvImportAccepted],
  );

  return (
    <GridToolbarContainer>
      <Stack direction='row' flexWrap='wrap' width='100%'>
        <Stack direction='row' mr={2} mb={1} gap={1}>
          <Button
            variant='contained'
            size='small'
            component='label'
            startIcon={<Add />}
            onClick={onAddRow}
            disabled={loading}
          >
            Add record
          </Button>

          <CSVReader
            config={{ skipEmptyLines: true }}
            onUploadAccepted={onUploadAccepted}
            disabled={loading}
            onUploadRejected={() => {
              setImported(false);
              toast.error('File upload rejected');
            }}
          >
            {({
              getRootProps,
            }: {
              acceptedFile: File;
              getRootProps: () => any;
              getRemoveFileProps: () => any;
            }) => (
              <Tooltip
                title={
                  imported
                    ? 'CSV uploaded. Refresh the page to upload another file.'
                    : `Upload a CSV file with columns: ${fieldsOrder.join(', ')}`
                }
              >
                <span>
                  <Button
                    variant='contained'
                    size='small'
                    component='label'
                    startIcon={<UploadFile />}
                    {...getRootProps()}
                    disabled={loading || imported}
                  >
                    Upload CSV
                  </Button>
                </span>
              </Tooltip>
            )}
          </CSVReader>
        </Stack>
        <Box ml='auto'>
          <Button
            variant='contained'
            size='small'
            component='label'
            startIcon={<Download />}
            disabled={exportBlocked}
            onClick={onDownloadClick}
          >
            {hasRows ? 'Download as CSV' : 'Download template'}
          </Button>
        </Box>
      </Stack>
    </GridToolbarContainer>
  );
};

export default ImportTableToolbar;

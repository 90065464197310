import React, { useCallback, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash';
import toast from 'react-hot-toast';

import { careLevelFields } from './constants/careLevelFields';
import { postCareLevel } from './data-access/postCareLevel';
import { putCareLevel } from './data-access/putCareLevel';
import {
  getCareLevelsQueryOptions,
  useCareLevels,
} from './hooks/useCareLevels';
import { CareLevelFieldTypes } from './types/CareLevelFieldTypes';

import { Organization } from '../../../../../types';
import OrgHeader from '../../components/OrgHeader';
import OrgSelector from '../../components/OrgSelector';
import { getOrgs } from '../../data-access/getOrgs';
import { OnSubmitFormModal, RenderFormModal } from '../../modals/FormModalBase';
import SelectBuilding from '../../modals/special/SelectBuilding';
import TableBase from '../TableBase';

const CareLevelsTable = () => {
  const queryClient = useQueryClient();

  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);

  const { isLoading: orgsLoading, data: orgs } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const {
    isFetching: careLevelsFetching,
    isError: careLevelsError,
    data: careLevels,
  } = useCareLevels(selectedOrg?.id);

  const handleOrgChange = useCallback(
    (_e: React.ChangeEvent<object>, newValue: Organization | null) => {
      setSelectedOrg(newValue);
    },
    [],
  );

  const data = useMemo(
    () =>
      (careLevels || []).map(
        ({ id, displayName, price, building, description }) => ({
          id,
          displayName,
          price: price.toString(),
          building: building || '',
          description: description || '',
        }),
      ),
    [careLevels, selectedOrg],
  );

  const renderModal: RenderFormModal<CareLevelFieldTypes> = useCallback(
    ({ defaultComponents, control, type, fields }) => (
      <>
        <OrgHeader displayName={selectedOrg?.name} id={selectedOrg?.id} />
        {defaultComponents.displayName}
        <SelectBuilding
          control={control}
          type={type}
          tooltip={fields.building.tooltip}
        />
        {defaultComponents.price}
        {defaultComponents.description}
      </>
    ),
    [selectedOrg, selectedOrg],
  );

  const onEditSubmit: OnSubmitFormModal<CareLevelFieldTypes> = useCallback(
    async (item) => {
      if (isNil(selectedOrg)) return;

      try {
        const careLevel = {
          displayName: item.displayName,
          price: parseInt(item.price),
          description: item.description,
          building: item.building || undefined,
        };

        await putCareLevel(item.id, careLevel);

        await queryClient.invalidateQueries({
          queryKey: getCareLevelsQueryOptions(selectedOrg.id).queryKey,
        });

        toast.success(`Successfully updated care level ${item.displayName}`);
      } catch {
        toast.error(`Error updating care level ${item.displayName}`);
      }
    },
    [selectedOrg],
  );

  const onAddSubmit: OnSubmitFormModal<CareLevelFieldTypes> = useCallback(
    async (item) => {
      if (isNil(selectedOrg)) return;

      try {
        const careLevel = {
          displayName: item.displayName,
          price: parseInt(item.price),
          description: item.description || undefined,
          org: selectedOrg.id,
          building: item.building || undefined,
        };

        await postCareLevel(careLevel);

        await queryClient.invalidateQueries({
          queryKey: getCareLevelsQueryOptions(selectedOrg.id).queryKey,
        });

        toast.success(`Successfully added care level ${item.displayName}`);
      } catch {
        toast.error(`Error adding care level ${item.displayName}`);
      }
    },
    [selectedOrg],
  );

  return (
    <Stack spacing={2}>
      <OrgSelector
        orgs={orgs ?? []}
        loading={orgsLoading}
        value={selectedOrg}
        onChange={handleOrgChange}
      />
      <TableBase<CareLevelFieldTypes>
        itemName='Care Level'
        fields={careLevelFields}
        data={data}
        loading={careLevelsFetching}
        renderModal={renderModal}
        error={careLevelsError}
        onEditSubmit={onEditSubmit}
        onAddSubmit={onAddSubmit}
        customNoRowsText={
          isNil(selectedOrg) ? 'No organization selected' : undefined
        }
        disableAddButton={isNil(selectedOrg)}
      />
    </Stack>
  );
};

export default CareLevelsTable;

import { useWindowSize } from 'usehooks-ts';

import { TableauUserView } from '@inspiren-monorepo/util-tableau';

type TableViz = {
  id: string;
  src: string;
  width: string | number;
  height: string | number;
  toolbar: string;
  token?: string;
  'hide-tabs'?: boolean;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'tableau-viz': TableViz;
    }
  }
}

type Props = {
  token: string;
  view: TableauUserView;
};

export const TableauEmbeddedView = ({ view, token }: Props) => {
  const { width = 0, height = 0 } = useWindowSize();

  return (
    <tableau-viz
      id='tableau-viz'
      src={view.embeddedUrl}
      width={width - 24}
      height={height - 104}
      toolbar='bottom'
      token={token}
      hide-tabs
    />
  );
};

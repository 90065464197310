import { useCallback } from 'react';
import { Link } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import * as yup from 'yup';

import { useIsUmbrella } from '../../../../HOC/CurrentUserContextProvider';
import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import { postOrg } from '../../../../utility';
import { getOrgs } from '../../data-access/getOrgs';
import { DataFields } from '../../types/DataFields';
import TableBase from '../TableBase';

import type { OnSubmitFormModal } from '../../modals/FormModalBase';

export type FieldTypes = {
  id: string;
  name: string;
  ipAllowlist?: string[];
  roles?: string[];
  openSettingsEditModal: never;
};

const fields: DataFields<FieldTypes> = [
  {
    field: 'id',
    label: 'ID',
    width: 150,
    editType: 'text',
    editable: false,
    renderCell: (params) => (
      <Link href={`/admin/organizations/${params.value}`}>{params.value}</Link>
    ),
    schema: yup
      .string()
      .required('You must provide an ID')
      .matches(/^[A-Z]+$/, 'ID can only contain capital letters'),
  },
  {
    field: 'name',
    label: 'Display name',
    width: 250,
    editType: 'text',
    schema: yup.string().required('You must provide an organization name'),
  },
  {
    field: 'ipAllowlist',
    label: 'IP allowlist',
    width: 300,
    flex: 1,
    editType: 'text',
    editable: false,
    hideOnAdd: true,
    hideOnEdit: true,
    valueFormatter: (params) =>
      params.value?.length ? params.value.join(', ') : '',
  },
  {
    field: 'roles',
    label: 'Roles',
    width: 200,
    editType: 'text',
    editable: false,
    hideOnAdd: true,
    hideOnEdit: true,
    flex: 2,
    valueFormatter: (params) => {
      const formattedValues = params.value
        ?.map((value: string) => value.charAt(0).toUpperCase() + value.slice(1))
        .sort();

      return formattedValues?.length ? formattedValues.join(', ') : '';
    },
  },
];

const OrganizationsTable = () => {
  const queryClient = useQueryClient();
  const { isAdmin } = useIsAdmin();
  const isUmbrella = useIsUmbrella();

  const {
    isLoading: orgsLoading,
    data: orgs,
    isError: orgsError,
  } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const handleAddSubmit: OnSubmitFormModal<FieldTypes> = useCallback(
    async ({ id, name, roles = [], ipAllowlist }) => {
      try {
        const data = { id, name, roles, ipAllowlist };

        await postOrg(data);

        await queryClient.invalidateQueries({
          queryKey: ['orgs'],
        });

        toast.success(`Successfully added ${name}`);
      } catch (error) {
        toast.error(`Error adding organization${error ? `: ${error}` : ''}`);
      }
    },
    [],
  );

  return (
    <TableBase<FieldTypes>
      itemName='Organization'
      fields={fields}
      data={orgs}
      loading={orgsLoading}
      error={orgsError}
      onAddSubmit={handleAddSubmit}
      disableEditing
      disableAddButton={!isAdmin || isUmbrella}
      getRowId={(row) => row.id}
    />
  );
};

export default OrganizationsTable;

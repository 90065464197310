import { skipToken, useQuery } from '@tanstack/react-query';
import { sortBy } from 'lodash';

import { getUserEvents } from '../data-access/getUserEvents';
import { useEventReviewStore } from '../store/EventReviewStore';

export const useUserEvents = () => {
  const { selectedRoom, startDate, endDate } = useEventReviewStore();
  const roomId = selectedRoom?.mainId;

  return useQuery({
    queryKey: ['eventReview', 'userEvents', roomId, startDate, endDate],
    queryFn:
      roomId && startDate && endDate
        ? async () => {
            const res = await getUserEvents(
              roomId,
              startDate.toISOString(),
              endDate.toISOString(),
            );

            return {
              augiEnabled: sortBy(res.augiEnabled, 'time'),
              augiDisabled: sortBy(res.augiDisabled, 'time'),
              privacyModeOn: sortBy(res.privacyModeOn, 'time'),
              privacyModeOff: sortBy(res.privacyModeOff, 'time'),
              liveViewOpened: sortBy(res.liveViewOpened, 'time'),
            };
          }
        : skipToken,
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* TODO: Fix any types */

import { useState } from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';

import FormModalBase, {
  OnSubmitFormModal,
  RenderFormModal,
} from './FormModalBase';

import type { DataFieldValue, DataFields } from '../types/DataFields';

type Props = {
  itemName: string;
  fields: DataFields<any>;
  onSubmit: OnSubmitFormModal<any>;
  loading?: boolean;
  error?: string | null;
  onOpen?: () => void;
  disabled?: boolean;
  renderModal?: RenderFormModal<any>;
};

const AddModal = ({
  itemName,
  fields,
  onSubmit,
  loading = false,
  error = null,
  onOpen,
  disabled = false,
  renderModal,
}: Props) => {
  const [open, setOpen] = useState(false);

  // Set initial values to empty strings if unset in FieldTypes
  const initialValues = fields.reduce((obj, { field, initialValue }) => {
    let value: DataFieldValue = '';

    // Checking for undefined here because initialValue may be
    // manually set to false or 0
    if (initialValue !== undefined) value = initialValue;

    return { ...obj, [field]: value };
  }, {});

  const handleOpen = () => {
    if (onOpen) onOpen();
    setOpen(true);
  };

  return (
    <>
      <Button
        variant='contained'
        size='small'
        onClick={() => handleOpen()}
        startIcon={<Add />}
        disabled={disabled}
      >
        Add {itemName}
      </Button>
      <FormModalBase
        type='add'
        initialValues={initialValues}
        itemName={itemName}
        fields={fields}
        open={open}
        handleClose={() => setOpen(false)}
        loading={loading}
        error={error}
        onSubmit={async (data) => {
          if (onSubmit) return onSubmit(data);
        }}
        renderModal={renderModal}
      />
    </>
  );
};

export default AddModal;

import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getAllUnits } from '../data-access/getAllUnits';

export const useUnitForRoom = (roomId: string) => {
  const { data: units } = useQuery({
    queryKey: ['getAllUnits'],
    queryFn: () => getAllUnits(),
  });

  const unit = useMemo(() => {
    if (!units) return undefined;
    const [, org, building, floorNr, unitName] = roomId.split('-');
    const floor = `${org}-${building}-${floorNr}-${unitName}`;
    return units?.find((u) => `${u.floor}-${u.name}` === floor);
  }, [roomId, units]);

  return unit;
};

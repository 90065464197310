import React, { useEffect } from 'react';
import { Theme, useMediaQuery } from '@mui/material';

import { sendAmpEvent } from './amplitude';

export const useZoom = (
  enable: boolean,
  zoomHTMLElement: React.MutableRefObject<HTMLImageElement | null>,
) => {
  useEffect(() => {
    if (enable && zoomHTMLElement && zoomHTMLElement.current) {
      const zoom = zoomHTMLElement.current;
      let scale = 1;
      let pointX = 0;
      let pointY = 0;
      const zoomPosition = { x: 0, y: 0 };

      // eslint-disable-next-line no-inner-declarations
      function setTransform() {
        scale = Math.max(1, scale);

        zoom.style.transform = `scale(${scale})`;
        zoom.style.transformOrigin = `${zoomPosition.x}px ${zoomPosition.y}px`;
      }

      zoom.onmousemove = (e) => {
        e.preventDefault();

        const eventTarget = e.target as HTMLElement;
        const rect = eventTarget.getBoundingClientRect();

        const rectParent = (
          eventTarget.parentElement as HTMLElement
        ).getBoundingClientRect();

        if (rectParent) {
          const x = e.clientX - rect.left; // x position within the element.
          const y = e.clientY - rect.top; // y position within the element.

          if (scale <= 1) {
            zoomPosition.x = x;
            zoomPosition.y = y;
          } else {
            const originalWidth = zoom.width;
            const originalHeight = zoom.height;
            zoomPosition.x = (originalWidth * x) / rect.width;
            zoomPosition.y = (originalHeight * y) / rect.height;
            setTransform();
          }
        }

        setTransform();
      };

      zoom.onwheel = (e) => {
        sendAmpEvent('Digital Zoom', {});
        e.preventDefault();
        const xs = (e.clientX - pointX) / scale;
        const ys = (e.clientY - pointY) / scale;
        const delta = -e.deltaY;

        if (delta > 0) {
          scale *= 1.2;
        } else {
          scale /= 1.2;
        }

        scale = scale > 2.5 ? 2.5 : scale;

        pointX = e.clientX - xs * scale;
        pointY = e.clientY - ys * scale;

        setTransform();
      };

      const resizeHandler = () => {
        pointX = 0;
        pointY = 0;
        scale = 1;
        setTransform();
      };

      window.addEventListener('resize', resizeHandler);

      return () => {
        window.removeEventListener('resize', resizeHandler);
      };
    }

    return undefined;
  }, [zoomHTMLElement.current]);
};

// TODO: Fix autoscroll feature. This will require upgrading to MUI DataGrid v7.
// The use of the ref API is not available to free users in v5.

export const useTableAutoscroll = (
  rowRef: React.MutableRefObject<HTMLTableRowElement | null>,
  active: boolean,
) => {
  // Display is split screen above md breakpoint
  const splitScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('md'),
  );

  useEffect(() => {
    if (active && splitScreen) {
      rowRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [active]);
};

import { TriggerAction } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../../../utility/client';

export async function getVirtualBsActions(baseId: string) {
  const response = await Api.get<TriggerAction[]>(
    `/telesitting/v1/vbase/${baseId}/actions`,
  );

  return response.data;
}

import { CareLevel } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../../../utility/client';

export const putCareLevel = async (
  id: string,
  careLevel: Partial<Omit<CareLevel, 'id'>>,
) => {
  const res = await Api.put<CareLevel>(
    `/admin/v1/care-levels/${id}`,
    careLevel,
  );

  return res.data;
};

import { SxProps, Theme } from '@mui/material';

import { CustomPalette } from './palette';
import theme from './theme';

import { AlertType } from '../../types';

const alertColorMap = {
  fall: 'fallAlert',
  out_of_bed: 'outOfBedAlert',
  out_of_chair: 'outOfChairAlert',
  moving_in_bed: 'movingInBedAlert',
  hidden: 'hiddenAlert',
  urgent: 'urgentAlert',
  early_warning: 'earlyWarningAlert',
} as const satisfies Record<Exclude<AlertType, ''>, keyof CustomPalette>;

export const getAlertAnimationSxProps = (
  alertType?: AlertType,
  target?: 'backgroundColor' | 'border',
): SxProps<Theme> => {
  if (!alertType) return {};

  const startColor = theme.palette[alertColorMap[alertType]].main;
  const endColor = theme.palette[alertColorMap[alertType]].light;

  const animation = `1s ease-out infinite alternate ${alertType}-${target}`;
  const keyframesTitle = `@keyframes ${alertType}-${target}`;

  if (target === 'backgroundColor')
    return {
      animation,
      [keyframesTitle]: {
        from: { backgroundColor: startColor },
        to: { backgroundColor: endColor },
      },
    };

  if (target === 'border')
    return {
      animation,
      [keyframesTitle]: {
        from: { border: `10px solid ${startColor}` },
        to: { border: `10px solid ${endColor}` },
      },
    };

  return {};
};

import { isNil } from 'lodash';
import { Control, useWatch } from 'react-hook-form';

import { ControlledAutocomplete } from '../../modals/ControlledAutocomplete';
import { useCareLevels } from '../CareLevelsTable/hooks/useCareLevels';

interface Props {
  control: Control;
  org?: string;
}

export const SelectCareLevel = ({ control, org }: Props) => {
  const buildingFloorUnit = useWatch({ name: 'buildingFloorUnit', control });

  const building = `${org}-${buildingFloorUnit.split('-')[0]}`;

  const { data: careLevels = [], isLoading } = useCareLevels(org, building);

  return (
    <ControlledAutocomplete
      id='careLevel'
      label='Care Level'
      items={careLevels.map((careLevel) => careLevel.id)}
      loading={isLoading}
      disabled={isNil(org)}
      control={control}
      getOptionLabel={(id) =>
        careLevels.find((careLevel) => careLevel.id === id)?.displayName || id
      }
    />
  );
};

import * as yup from 'yup';

import {
  adminPagePermissions,
  eventReviewPagePermissions,
  globalPermissions,
  mobilePermissions,
  roomsPagePermissions,
} from './permissions';

import { DataFields } from '../../../types/DataFields';
import TrainingVideoCell from '../components/TrainingVideoCell';
import { buildPermissionDataField } from '../helpers/buildPermissionDataField';
import { RoleFieldTypes } from '../types/RoleFieldTypes';

const numberSchema = yup
  .number()
  .typeError('This setting must be a number')
  .min(0)
  .integer('This setting must be a whole number')
  .transform((value, originalValue) => (originalValue === '' ? null : value))
  .nullable();

export const orgProfileFields: DataFields<RoleFieldTypes> = [
  {
    field: 'template',
    label: 'Template',
    width: 150,
    editType: 'text',
    editable: false,
  },
  {
    field: 'displayName',
    label: 'Role Name',
    width: 150,
    editType: 'text',
    editable: true,
    schema: yup.string().required('Display Name is required'),
  },
  {
    field: 'maxAssign',
    label: 'Max Assignments',
    width: 150,
    editType: 'text',
    editable: true,
    schema: numberSchema,
  },
  {
    field: 'requireTrainingVideoMobile',
    label: 'Require Training Video (Mobile)?',
    width: 250,
    editType: 'boolean',
    editable: true,
    initialValue: false,
  },
  {
    field: 'trainingVideoUrlMobile',
    label: 'Training Video (Mobile)',
    width: 200,
    editType: 'special',
    editable: true,
    renderCell: ({ value }) => <TrainingVideoCell value={value} />,
  },

  ...globalPermissions.map((permission) =>
    buildPermissionDataField(permission.id, permission.label),
  ),

  buildPermissionDataField('virtual-care.access', 'Virtual Care Access'),

  buildPermissionDataField(
    'virtual-care.analytics.view',
    'View Analytics Page',
  ),

  buildPermissionDataField(
    'virtual-care.tableau.view',
    'View Tableau Launch Button',
  ),

  buildPermissionDataField('virtual-care.rooms.view', 'View Rooms Page'),

  ...roomsPagePermissions.map((permission) =>
    buildPermissionDataField(permission.id, permission.label),
  ),

  buildPermissionDataField(
    'virtual-care.event-review.view',
    'View Event Review Page',
  ),

  ...eventReviewPagePermissions.map((permission) =>
    buildPermissionDataField(permission.id, permission.label),
  ),

  buildPermissionDataField('virtual-care.admin.view', 'View Admin Page'),

  ...adminPagePermissions.map((permission) =>
    buildPermissionDataField(permission.id, permission.label),
  ),

  buildPermissionDataField('mobile.access', 'Mobile Access'),
  ...mobilePermissions.map((permission) =>
    buildPermissionDataField(permission.id, permission.label),
  ),
];

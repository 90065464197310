import { ConsoleLogger } from './loggers/ConsoleLogger';
import { ErrorFn, ILogger } from './types/ILogger';
import { LOG_LEVELS, LogLevel } from './types/LogLevel';

class Logger implements ILogger {
  private readonly loggers: ILogger[] = [];

  readonly level: number;
  readonly env: string;

  constructor(level: LogLevel = 'info') {
    this.level = LOG_LEVELS[level] || LOG_LEVELS.info;

    this.loggers.push(new ConsoleLogger());
  }

  error: ErrorFn = (message, error, logContext) => {
    if (this.level >= LOG_LEVELS.error) {
      this.loggers.forEach((logger) =>
        logger.error(message, error, logContext),
      );
    }
  };

  warn(...args: any[]) {
    if (this.level >= LOG_LEVELS.warn) {
      this.loggers.forEach((logger) => logger.warn(...args));
    }
  }

  info(...args: any[]) {
    if (this.level >= LOG_LEVELS.info) {
      this.loggers.forEach((logger) => logger.info(...args));
    }
  }

  trace(...args: any[]) {
    if (this.level >= LOG_LEVELS.trace) {
      this.loggers.forEach((logger) => logger.trace(...args));
    }
  }

  debug(...args: any[]) {
    if (this.level >= LOG_LEVELS.debug) {
      this.loggers.forEach((logger) => logger.debug(...args));
    }
  }

  time(label?: string) {
    this.loggers.forEach((logger) => logger.time(label));
  }

  timeEnd(label?: string) {
    this.loggers.forEach((logger) => logger.timeEnd(label));
  }

  async flushLogs() {
    for (const logger of this.loggers) {
      await logger.flushLogs();
    }
  }

  includeLogger(logger: ILogger) {
    this.loggers.push(logger);
  }
}

// We check if process is undefined so it will not throw an error in the browser

const envLoggingLevel =
  typeof process === 'undefined'
    ? undefined
    : process.env.LOGGING_LEVEL || process.env.EXPO_PUBLIC_LOGGING_LEVEL;

if (envLoggingLevel) {
  console.log('[LOGGER] Logging level set to', envLoggingLevel);
} else {
  console.log('[LOGGER] Logging level not set, defaulting to info');
}

// We keep `info` as our default to decrease the amount of information logged to CloudWatch
// For local just override in `.env` file

const loggingLevel = (envLoggingLevel || 'info') as LogLevel;

export const logger = new Logger(loggingLevel);

import { Room } from '../../../../types';
import { Api } from '../../../utility/client';

export type AdminToolRoom = Pick<
  Room,
  | 'mainId'
  | 'name'
  | 'hide'
  | 'fallRiskLevel'
  | 'unit'
  | 'deleted'
  | 'careLevelId'
>;

export async function getRoomsByOrg(org: string, includeDeleted = false) {
  return Api.get<AdminToolRoom[]>(`admin/v1/rooms/all/${org}`, {
    params: { includeDeleted },
  }).then((res) => res.data);
}

import { useMemo } from 'react';
import { Button, IconButton, Popover, Stack } from '@mui/material';
import { MoreVertOutlined as MenuIcon } from '@mui/icons-material';
import {
  bindTrigger,
  bindPopover,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import { BasestationIdDisplay } from './components/BasestationIdDisplay';
import DisableAugiButton from './components/DisableAugiButton';
import FallRiskToggle from './components/FallRiskToggle';
import { TriggerVirtualBsActionButton } from './components/TriggerVirtualBsActionButton';
import { useVirtualBsActions } from './hooks/useVirtualBsActions';

import { FallRiskLevel } from '../../../../../types';
import { useCurrentUser } from '../../../../HOC/CurrentUserContextProvider';
import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import { Can } from '../../../Can/Can';
import { useUnits } from '../../hooks/useUnits';

interface Props {
  roomId: string;
  baseId: string;
  roomName: string;
  fallRiskLevel: FallRiskLevel;
  onPrivacyModeChange: () => void;
  onToggleZoneUpdate: () => void;
  offline: boolean;
  privacy: boolean;
  augiDisabled: boolean;
  unitId: string;
  isDemoSession?: boolean;
  isDemoAction?: boolean;
}

const RoomMenu = ({
  roomId,
  baseId,
  roomName,
  fallRiskLevel,
  onPrivacyModeChange,
  onToggleZoneUpdate,
  offline,
  privacy,
  augiDisabled,
  unitId,
  isDemoSession,
  isDemoAction,
}: Props) => {
  const { user } = useCurrentUser();
  const { units } = useUnits();
  const { isAdmin } = useIsAdmin();

  const { actions, isLoading: isLoadingActions } = useVirtualBsActions(
    baseId,
    isDemoSession,
  );

  const unitAllowsDisableAugi = useMemo(() => {
    const unit = units?.find(
      (unit) => `Room-${unit.floor}-${unit.name}` === unitId,
    );

    return unit?.disableAugi || false;
  }, [unitId, units]);

  const unitAllowsNightLow = useMemo(() => {
    const unit = units?.find(
      (unit) => `Room-${unit.floor}-${unit.name}` === unitId,
    );

    return unit?.nightLow || false;
  }, [unitId, units]);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'room-menu',
  });

  return (
    <>
      <IconButton
        {...bindTrigger(popupState)}
        sx={{
          color: 'white',
          height: 36,
          width: 36,
          backgroundColor: 'grey.800',
          ':hover': { backgroundColor: 'grey.900' },
        }}
        size='large'
        // Disable the button if the menu is empty
        disabled={
          !user?.ability?.can('view', 'global.fall-risk-high-low') &&
          privacy &&
          !user?.ability?.can('view', 'global.disable-augi')
        }
      >
        <MenuIcon fontSize='inherit' />
      </IconButton>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Stack width={250} m={1} gap={1}>
          <Can
            permission={{
              subject: 'global.fall-risk-high-low',
              action: 'view',
            }}
          >
            <FallRiskToggle
              roomId={roomId}
              roomName={roomName}
              fallRiskLevel={fallRiskLevel}
              nightLowFallRisk={unitAllowsNightLow}
            />
          </Can>

          {privacy && (
            <Button
              size='medium'
              variant='contained'
              color='primary'
              onClick={onPrivacyModeChange}
            >
              Close Virtual Curtain
            </Button>
          )}

          {isAdmin && !privacy && !augiDisabled && !offline && (
            <Button
              size='medium'
              variant='contained'
              color='primary'
              onClick={onToggleZoneUpdate}
            >
              Update Zones
            </Button>
          )}

          {unitAllowsDisableAugi && (
            <Can
              permission={{ subject: 'global.disable-augi', action: 'view' }}
            >
              <DisableAugiButton roomId={roomId} augiDisabled={augiDisabled} />
            </Can>
          )}
          <BasestationIdDisplay baseId={baseId} isAdmin={isAdmin} />
          {isAdmin &&
            isDemoSession &&
            !isLoadingActions &&
            actions?.map((action) => (
              <TriggerVirtualBsActionButton
                baseId={baseId}
                action={action}
                isRunning={isDemoAction}
              />
            ))}
        </Stack>
      </Popover>
    </>
  );
};

export default RoomMenu;

import { GlobalStylesProps, lighten } from '@mui/material';

const globalStyles: GlobalStylesProps['styles'] = (theme) => ({
  'body, html': {
    backgroundColor: '#fff',
    height: '100%',
  },

  '#root, main': {
    height: '100%',
  },

  '.react-datepicker__tab-loop': {
    margin: '-8px !important',
  },

  '.datepicker-wrapper': {
    width: 'auto',
  },

  '.datepicker-calendar': {
    fontFamily: theme.typography.fontFamily,
    letterSpacing: theme.typography.h6.letterSpacing,
    boxSizing: 'content-box',
    boxShadow: theme.shadows[1],
    border: 'none',

    '.react-datepicker__header': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '.react-datepicker__time-container': {
      borderLeft: `1px solid ${theme.palette.divider}`,
    },

    '.react-datepicker__day:hover, .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range)':
      {
        backgroundColor: theme.palette.grey[300],
      },
    '.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range':
      {
        backgroundColor: theme.palette.primary.main,
      },
    '.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover':
      {
        backgroundColor: theme.palette.primary.dark,
      },
    '.react-datepicker__day--keyboard-selected, .react-datepicker__day--keyboard-selected:hover':
      {
        backgroundColor: 'inherit',
        color: 'inherit',
      },
    '.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range)':
      {
        backgroundColor: lighten(theme.palette.primary.main, 0.5),
      },
    '.react-datepicker__day--disabled': {
      cursor: 'default',
      color: theme.palette.grey[400],
    },
    '.react-datepicker__day--disabled:hover': {
      backgroundColor: 'transparent',
    },
    '.react-datepicker__day--today': {
      fontWeight: 'inherit',
    },

    'li.react-datepicker__time-list-item': {
      lineHeight: 2.25,
    },
  },

  '.react-datepicker__time-list-item:hover': {
    backgroundColor: `${theme.palette.grey[300]} !important`,
  },
  '.react-datepicker__time-list-item--selected': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    fontWeight: '700 !important',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.dark} !important`,
    },
  },

  '.datepicker-popper': {
    zIndex: theme.zIndex.modal,
  },

  '.no-value': {
    color: theme.palette.grey[400],
    fontStyle: 'italic',
  },
});

export default globalStyles;

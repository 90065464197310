import { skipToken, useQuery } from '@tanstack/react-query';

import { UserRole } from '../../../../types';
import { getStaffEvents } from '../data-access/getStaffEvents';
import { useEventReviewStore } from '../store/EventReviewStore';

export const useStaffEvents = () => {
  const { selectedRoom, startDate, endDate } = useEventReviewStore();
  const roomId = selectedRoom?.mainId;

  return useQuery({
    queryKey: ['eventReview', 'staffEvents', roomId, startDate, endDate],
    queryFn:
      roomId && startDate && endDate
        ? async () => {
            const events = await getStaffEvents(
              roomId,
              startDate.toISOString(),
              endDate.toISOString(),
            );

            return events?.map((item) => {
              if (!item.staffFirstName) {
                return {
                  ...item,
                  staffFirstName: 'Staff',
                  staffLastName: 'Member',
                  staffRole: '' as UserRole,
                };
              }

              return item;
            });
          }
        : skipToken,
  });
};

import { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';

type Props = PropsWithChildren<{ title: string }>;

const FormCategory = ({ title, children }: Props) => (
  <Box
    sx={(theme) => ({
      bgcolor: 'grey.100',
      py: theme.spacing(1.5),
      px: theme.spacing(2.5),
      borderRadius: theme.spacing(1),
      my: theme.spacing(3),
    })}
  >
    <Typography variant='h6'>{title}</Typography>
    {children}
  </Box>
);

export default FormCategory;

import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

type Props = {
  id: string;
  label: string;
  control: Control;
  tooltip?: string;
  disabled?: boolean;
};

const ControlledCheckbox = ({
  id,
  label,
  control,
  tooltip,
  disabled = false,
}: Props) => (
  <Controller
    name={id}
    control={control}
    render={({ field: { name, value, onChange } }) => (
      <Tooltip
        title={tooltip}
        placement='left'
        disableHoverListener={!tooltip}
        disableInteractive={!tooltip}
        disableFocusListener={!tooltip}
      >
        <FormControlLabel
          control={<Switch />}
          id={name}
          label={label}
          checked={value}
          disabled={disabled}
          onChange={onChange}
          sx={{ display: 'block', my: 1 }}
        />
      </Tooltip>
    )}
  />
);

export default ControlledCheckbox;

import { OrgAuthSettings } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../utility/client';

export async function getOrgAuthSettings(domain: string) {
  try {
    const res = await Api.get<OrgAuthSettings>(
      `/telesitting/v1/orgs/auth/${domain}`,
    );

    return res && res.data;
  } catch (error) {
    console.error('error getting org settings', error);
    return {};
  }
}

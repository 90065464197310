import {
  AbilityBuilder,
  MongoQuery,
  PureAbility,
  createMongoAbility,
} from '@casl/ability';

import { AbilityTuple } from './types/AbilityTuple';
import { Permissions } from './types/Permissions';

export type Ability = PureAbility<AbilityTuple, MongoQuery>;

export const buildRole = (permissions: Permissions[]): Ability => {
  const { can, build } = new AbilityBuilder<Ability>(createMongoAbility);

  permissions.forEach((permission) => {
    can(permission.action, permission.subject);
  });

  return build();
};

import { Chip, SxProps, Theme } from '@mui/material';
import { isNil } from 'lodash';

import {
  FALL_RISK_OPTIONS,
  isValidFallRiskLevel,
} from '@inspiren-monorepo/shared-types';

import { FallRiskLevel } from '../../../types';

const FONT_SIZES = {
  small: undefined,
  medium: 15,
  large: 17,
};

type Props = {
  fallRisk: FallRiskLevel | null;
  label?: string;
  size?: 'small' | 'medium' | 'large';
  bold?: boolean;
  sx?: SxProps<Theme>;
  testID?: string;
};

const FallRiskPill = ({
  fallRisk = 'off',
  label,
  size = 'medium',
  bold = false,
  sx = {},
  testID,
}: Props) => {
  if (isNil(fallRisk)) return null;
  if (!isNil(fallRisk) && !isValidFallRiskLevel(fallRisk)) return null;

  const displayLabel = label || FALL_RISK_OPTIONS[fallRisk].description;

  return (
    <Chip
      label={displayLabel}
      size={size === 'small' ? 'small' : 'medium'}
      data-testid={testID}
      sx={{
        ...sx,
        backgroundColor: `${fallRisk}FallRisk.main`,
        color: `${fallRisk}FallRisk.contrastText`,
        fontSize: FONT_SIZES[size],
        fontWeight: bold ? 600 : 500,
        borderRadius: 100,
        height: size === 'large' ? 36 : undefined,
        '& .MuiChip-label': {
          px: size === 'large' ? 1.75 : undefined,
        },
      }}
    />
  );
};

export default FallRiskPill;

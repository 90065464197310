import { BasestationHistory } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../../../utility/client';

export const getBasestationHistory = async (basestationId: string) => {
  const res = await Api.get<BasestationHistory[]>(
    `/admin/v1/basestations/${basestationId}/history`,
  );

  return res.data;
};

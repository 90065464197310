import { useCallback } from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useConfirm } from 'material-ui-confirm';

import {
  phoneVerificationCopy,
  useChangeStage,
} from '@inspiren-monorepo/shared-react';

import ControlledPhoneNumberInput from '../ControlledPhoneNumberInput';
import PhoneNumberError from '../PhoneNumberError';
import { updatePhoneNumber } from '../data-access/updatePhoneNumber';

interface Props {
  confirmedMobilePhone?: string;
  onConfirmedMobilePhoneChange: (value: string | null) => void;
  goBack: () => void;
}

const ChangeStage = ({
  confirmedMobilePhone,
  onConfirmedMobilePhoneChange,
  goBack,
}: Props) => {
  const confirm = useConfirm();

  const confirmClear = useCallback(async (clearPhoneNumber: () => void) => {
    try {
      await confirm({
        description: 'Are you sure you want to clear the mobile phone number?',
        confirmationText: 'Clear',
      });

      clearPhoneNumber();
    } catch {
      // Do nothing.
    }
  }, []);

  const {
    handleSetPhoneNumber,
    upsertButtonText,
    isPending,
    isError,
    updateMutationError,
    control,
    mobilePhoneNumber,
    clearPhoneNumberField,
  } = useChangeStage({
    confirmClear,
    confirmedMobilePhone,
    onConfirmedMobilePhoneChange,
    updatePhoneNumberMutationFn: updatePhoneNumber,
    userQueryKey: ['auth-user'],
  });

  return (
    <>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          {phoneVerificationCopy.legal}
        </DialogContentText>
        <ControlledPhoneNumberInput
          control={control}
          onClear={clearPhoneNumberField}
        />
        <Box sx={{ mt: 2 }}>
          {isError && <PhoneNumberError error={updateMutationError} />}
        </Box>
      </DialogContent>
      <DialogActions>
        {confirmedMobilePhone && <Button onClick={goBack}>Cancel</Button>}
        <LoadingButton
          disabled={!confirmedMobilePhone && !mobilePhoneNumber}
          loading={isPending}
          onClick={handleSetPhoneNumber}
          variant='contained'
        >
          {upsertButtonText}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ChangeStage;

import { UnitRes } from '../../../../types';
import { Api } from '../../../utility/client';

interface Props {
  userId: string;
  roomIds: string[];
}

export const updateRoomAssignments = async ({
  userId,
  roomIds,
}: Props): Promise<UnitRes> => {
  try {
    const res = await Api.post('/v1/users/telesitting/batchAssign', {
      userId,
      roomIds,
    });

    return res && res.data;
  } catch (error) {
    console.error('error updating assignments');
    throw error;
  }
};

import { Unit } from '../../../../types';
import { Api } from '../../../utility/client';

export const postUnit = async (
  unit: Pick<Unit, 'floor' | 'name' | 'displayName'>,
) => {
  try {
    const res = await Api.post<Unit>('/admin/v1/units', unit);
    return res?.data;
  } catch (error) {
    console.error('error adding unit');
    throw error;
  }
};

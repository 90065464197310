import { Auth0ContextInterface } from '@auth0/auth0-react';
import axios from 'axios';

import unauthorizedInterceptor from './interceptors/unauthorized.interceptor';

import { getApiBaseUrl } from '../config/apiUrlConfig';

const Api = axios.create({
  baseURL: getApiBaseUrl(),
  timeout: 30000,
});

Api.interceptors.request.use((config) => {
  const newConfig = config;
  newConfig.headers['X-Application-Name'] = 'virtual-care';
  return newConfig;
});

unauthorizedInterceptor(Api);

export const addAuthHeader = (
  getToken: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  Api.interceptors.request.use(async (config) => {
    const token = await getToken();
    const newConfig = config;
    newConfig.headers.Authorization = `Bearer ${token}`;
    return newConfig;
  });
};

export { Api };

import { UnitUpdate } from './types/UnitUpdate';

import { Unit } from '../../../../types';
import { Api } from '../../../utility/client';

export const putUnit = async (unit: UnitUpdate) => {
  try {
    const res = await Api.put<Unit>(
      `/admin/v1/units/${unit.floor}-${unit.name}`,
      unit,
    );

    return res?.data;
  } catch (error) {
    console.error('error editing unit');
    throw error;
  }
};

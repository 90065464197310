import { useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';

import { RoomRegion } from '@inspiren-monorepo/hilq/api-contracts';

import { drawRegion } from '../helpers';

type CanvasProps = React.DetailedHTMLProps<
  React.CanvasHTMLAttributes<HTMLCanvasElement>,
  HTMLCanvasElement
>;

interface Props extends CanvasProps {
  handleDrawingPointChange: (e: React.MouseEvent<HTMLElement>) => void;
  chairPoints: RoomRegion[];
  bedPoints: RoomRegion[];
  bedEdgePoints: RoomRegion[];
  multiRegions: RoomRegion[][];
  handleMouseMove: (e: React.MouseEvent<HTMLElement>) => void;
  handleMouseUp: (e: React.MouseEvent<HTMLElement>) => void;
  showExitIgnore?: boolean;
}

export const Canvas = ({
  width,
  height,
  handleDrawingPointChange,
  chairPoints,
  bedEdgePoints,
  bedPoints,
  multiRegions,
  showExitIgnore,
  handleMouseMove,
  handleMouseUp,
}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas) {
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (!isEmpty(chairPoints)) {
          drawRegion(ctx, chairPoints, 'chair');
        }

        if (!isEmpty(bedPoints)) {
          drawRegion(ctx, bedPoints, 'bed');
        }

        if (!isEmpty(bedEdgePoints)) {
          drawRegion(ctx, bedEdgePoints, 'bedEdge');
        }

        if (showExitIgnore && !isEmpty(multiRegions)) {
          for (const shape of multiRegions) {
            if (!isEmpty(shape)) {
              drawRegion(ctx, shape, shape[0].region);
            }
          }
        }
      }
    }
  }, [chairPoints, bedPoints, bedEdgePoints, multiRegions]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      style={{ position: 'absolute', top: 0, left: 0, zIndex: 1000000 }}
      onMouseDown={handleDrawingPointChange}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    />
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { QueryKey, useQuery } from '@tanstack/react-query';

import {
  DrawingRegion,
  RoomRegion,
} from '@inspiren-monorepo/hilq/api-contracts';

import { CanvasControls } from './components/CanvasControls';
import { ZoneUpdateImage } from './components/ZoneUpdateImage';
import {
  addDistanceTravelled,
  convertToAbsolute,
  getDragRegion,
  isThereAPointWithin15Pixels,
  movePoint,
} from './helpers';

interface ZoneUpdateProps {
  direction?: ResponsiveStyleValue<'column' | 'column-reverse'>;
  isSavePending: boolean;
  imagesIsLoading: boolean;
  lastSuccessfulImage?: string;
  regionConfigParams: {
    queryKey: QueryKey;
    queryFn: () => Promise<RoomRegion[][]>;
  };
  saveRegions: (allRegions: RoomRegion[]) => void;
  showControls: boolean;
  showExitIgnore?: boolean;
  handleCancel: () => void;
  roomName: string;
  width: number;
  height: number;
}

const multiRegionSupport = ['ignore', 'exit'];

export const ZoneUpdate = (props: ZoneUpdateProps) => {
  const {
    direction = 'column',
    handleCancel,
    imagesIsLoading,
    lastSuccessfulImage,
    regionConfigParams,
    showControls,
    showExitIgnore,
    isSavePending,
    roomName,
    saveRegions,
    width,
    height,
  } = props;

  // this state is for when the user is dragging the mouse
  const [dragState, setDragState] = useState<boolean>(false);

  // this is state where the user has clicked and is dragging the mouse
  const [dragStart, setDragStart] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  // this is state is which region the user clicked
  const [selectedDragRegion, setSelectedDragRegion] = useState<
    'chair' | 'bed' | 'bedEdge' | null
  >(null);

  // this is state is which point the user selected if they clicked one
  const [selectedPoint, setSelectedPoint] = useState<RoomRegion | null>(null);
  // this is state is which region the user is drawing
  const [drawingRegion, setDrawingRegion] = useState<DrawingRegion>('bedEdge');
  // this is state is which action the user is doing on the canvas
  const [canvasAction, setCanvasAction] = useState<'draw' | 'move'>('move');
  // these state are the points or the regions
  const [chairPoints, setChairPoints] = useState<RoomRegion[]>([]);
  const [bedPoints, setBedPoints] = useState<RoomRegion[]>([]);
  const [bedEdgePoints, setBedEdgePoints] = useState<RoomRegion[]>([]);
  const [multiRegions, setMultiRegions] = useState<RoomRegion[][]>([]);

  const {
    isLoading: isGetLoading,
    data,
    isSuccess: isGetSuccess,
  } = useQuery<RoomRegion[][]>({
    ...regionConfigParams,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (isGetSuccess && Array.isArray(data)) {
      const newChairPoints: RoomRegion[] = [];
      const newBedPoints: RoomRegion[] = [];
      const newBedEdgePoints: RoomRegion[] = [];
      const newMultiRegions: RoomRegion[][] = [];

      for (const regionArray of data) {
        // TODO: this logic is all going away when multi
        // region support is live for all region types
        if (regionArray?.[0]?.region) {
          const newRegionArray = [];
          const { region } = regionArray[0];
          const isMultiRegion = multiRegionSupport.includes(region);

          for (const config of regionArray) {
            // when we get the region config convert the points to absolute
            const adjustedPoint = convertToAbsolute(config, width, height);

            if (isMultiRegion) {
              newRegionArray.push(adjustedPoint);
            } else {
              switch (config.region) {
                case 'bed':
                  newBedPoints.push(adjustedPoint);
                  break;
                case 'bedEdge':
                  newBedEdgePoints.push(adjustedPoint);
                  break;
                case 'chair':
                  newChairPoints.push(adjustedPoint);
                  break;
                default:
                  break;
              }
            }
          }

          if (isMultiRegion) {
            newMultiRegions.push(newRegionArray);
          }
        }
      }

      setChairPoints(newChairPoints);
      setBedPoints(newBedPoints);
      setBedEdgePoints(newBedEdgePoints);
      setMultiRegions(newMultiRegions);
    }
  }, [data, isGetSuccess]);

  //  this is the function that is called when the user clicks which region they want to draw
  const handleDrawingRegionChange = useCallback(
    (
      _event: React.MouseEvent<HTMLElement>,
      newDrawingRegion: DrawingRegion,
    ) => {
      setDrawingRegion(newDrawingRegion);
    },
    [],
  );

  // this is the function that is called when the user clicks which action they want to do on the canvas
  const handleCanvasActionChange = useCallback(
    (
      _event: React.MouseEvent<HTMLElement>,
      newCanvasAction: 'draw' | 'move',
    ) => {
      setCanvasAction(newCanvasAction);
    },
    [],
  );

  // this is the function that is called when the user clicks the clear button
  const handleClearSelectedRegion = useCallback(() => {
    // we check what region the user has selected and clear that region
    // eslint-disable-next-line default-case
    switch (drawingRegion) {
      case 'bedEdge':
        setBedEdgePoints([]);
        break;
      case 'bed':
        setBedPoints([]);
        break;
      case 'chair':
        setChairPoints([]);
        break;
    }
  }, [drawingRegion]);

  // this is the function that is called when the user clicks the clear all button
  const handleClearAllRegions = useCallback(() => {
    setBedEdgePoints([]);
    setBedPoints([]);
    setChairPoints([]);
  }, []);

  // this is the function that is called when the user clicks down on the mouse
  const handleDrawingPoint = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      // get the x and y coordinates of the click
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      // if the user has selected the move action
      if (canvasAction === 'move') {
        // set the drag state to true
        setDragState(true);
        // set the drag start state
        setDragStart({ x, y });

        // check if there is a point within 15 pixels of the click
        const newSelectedPoint = isThereAPointWithin15Pixels(
          [...bedPoints, ...bedEdgePoints, ...chairPoints],
          x,
          y,
        );

        // if there is a point set the selected point state to first closest point
        if (newSelectedPoint.length > 0) {
          setSelectedPoint(newSelectedPoint[0]);
        }

        // check if points are within the regions
        const newDragRegion = getDragRegion(
          chairPoints,
          bedPoints,
          bedEdgePoints,
          x,
          y,
        );

        if (newDragRegion) {
          setSelectedDragRegion(newDragRegion);
        }
      }
      // if the user has selected the draw action
      else if (canvasAction === 'draw') {
        // add he point to the appropriate region
        const newPoint = { x, y, region: drawingRegion };

        switch (drawingRegion) {
          case 'chair':
            setChairPoints([...chairPoints, newPoint]);
            break;
          case 'bed':
            setBedPoints([...bedPoints, newPoint]);
            break;
          case 'bedEdge':
            setBedEdgePoints([...bedEdgePoints, newPoint]);
            break;
          default:
            break;
        }
      }
    },
    [canvasAction, drawingRegion, chairPoints, bedPoints, bedEdgePoints],
  );

  const setAndUpdateDraggedPoint = useCallback(
    (point: RoomRegion, dx: number, dy: number) => {
      if (
        selectedPoint &&
        point.x === selectedPoint.x &&
        point.y === selectedPoint.y
      ) {
        const newPoint = movePoint(point, dx, dy);
        setSelectedPoint(newPoint);

        return newPoint;
      }

      return point;
    },
    [selectedPoint],
  );

  // this is the function that is called when the user moves the mouse
  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const rect = e.currentTarget.getBoundingClientRect();
      // get the x and y coordinates of the mouse
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const dx = x - dragStart.x;
      const dy = y - dragStart.y;

      if (dragState) {
        setDragStart({ x, y });

        // a region was selected, and there is no selected point
        if (drawingRegion && !selectedPoint) {
          switch (selectedDragRegion) {
            case 'bed':
              setBedPoints(addDistanceTravelled(bedPoints, dx, dy));
              break;
            case 'bedEdge':
              setBedEdgePoints(addDistanceTravelled(bedEdgePoints, dx, dy));
              break;
            case 'chair':
              setChairPoints(addDistanceTravelled(chairPoints, dx, dy));
              break;
            default:
              break;
          }
        }
        // if drag state is true and there is a selected point within 15 pixels
        else if (selectedPoint) {
          switch (selectedPoint.region) {
            case 'bed':
              setBedPoints(
                bedPoints.map((point) =>
                  setAndUpdateDraggedPoint(point, dx, dy),
                ),
              );

              break;
            case 'bedEdge':
              setBedEdgePoints(
                bedEdgePoints.map((point) =>
                  setAndUpdateDraggedPoint(point, dx, dy),
                ),
              );

              break;
            case 'chair':
              setChairPoints(
                chairPoints.map((point) =>
                  setAndUpdateDraggedPoint(point, dx, dy),
                ),
              );

              break;
            default:
              break;
          }
        }
      }
    },
    [
      dragState,
      dragStart,
      drawingRegion,
      selectedPoint,
      selectedDragRegion,
      bedPoints,
      bedEdgePoints,
      chairPoints,
    ],
  );

  // create a useMemo that turns true or false if allPoints are empty
  const allPointsEmpty = useMemo(
    () =>
      bedPoints.length === 0 &&
      bedEdgePoints.length === 0 &&
      chairPoints.length === 0,
    [bedPoints, bedEdgePoints, chairPoints],
  );

  // this is the function that is called when the user releases the mouse
  const handleMouseUp = useCallback(() => {
    setDragState(false);
    setSelectedPoint(null);
    setSelectedDragRegion(null);
  }, []);

  const handleUpdate = useCallback(() => {
    const allRegions = [...bedPoints, ...bedEdgePoints, ...chairPoints];

    saveRegions(allRegions);
  }, [bedPoints, bedEdgePoints, chairPoints]);

  return (
    <Stack direction={direction}>
      <ZoneUpdateImage
        roomName={roomName}
        isLoading={isGetLoading || imagesIsLoading}
        handleDrawingPointChange={handleDrawingPoint}
        handleMouseMove={handleMouseMove}
        handleMouseUp={handleMouseUp}
        chairPoints={chairPoints}
        bedPoints={bedPoints}
        bedEdgePoints={bedEdgePoints}
        multiRegions={multiRegions}
        showExitIgnore={showExitIgnore}
        lastSuccessfulImage={lastSuccessfulImage}
        width={width}
        height={height}
      />
      {showControls ? (
        <CanvasControls
          drawingRegion={drawingRegion}
          handleDrawingRegionChange={handleDrawingRegionChange}
          canvasAction={canvasAction}
          handleCanvasActionChange={handleCanvasActionChange}
          handleClearAllRegions={handleClearAllRegions}
          handleClearSelectedRegion={handleClearSelectedRegion}
          handleCancel={handleCancel}
          handleRegionUpdate={handleUpdate}
          isLoading={isSavePending}
          allPointsEmpty={allPointsEmpty}
          width={width}
        />
      ) : null}
    </Stack>
  );
};

import { Link } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import {
  GridEditSingleSelectCell,
  getGridStringOperators,
} from '@mui/x-data-grid';
import { isEmpty, startCase } from 'lodash';
import * as yup from 'yup';
import YupPassword from 'yup-password';

import {
  isValidMobileNumber,
  PhoneNumberFormat,
  formatPhoneNumberSafe,
} from '@inspiren-monorepo/util-formatters';
import { isUsernameOrg } from '@inspiren-monorepo/util-users';

import { getRoleOptions } from './getRoleOptions';
import usersTableSchemas from './usersTableSchemas';

import { LevelAccess, RoleMap } from '../../../../../../types';
import { Can } from '../../../../Can/Can';
import { Cannot } from '../../../../Can/Cannot';
import GeneralChip from '../../../../shared/GeneralChip';
import { ImportableDataFields } from '../../ImportTable/types/importable';
import importUniqueValidator from '../../ImportTable/validators/importUniqueValidator';
import ImportUnitAutocomplete from '../components/ImportUnitAutocomplete';
import UnitDisplayName from '../components/UnitDisplayName';

YupPassword(yup);

export type FieldTypes = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  mobilePhone?: string;
  password: string;
  role: string;
  unit: string;
  levelAccess?: LevelAccess;
  beacon: string;
  auth0Connection?: string;
  org?: string;
};

const getPasswordSchema = (org?: string, required?: boolean) => {
  const baseSchema: yup.StringSchema<string | null | undefined> = required
    ? yup.string().required('Password is required')
    : yup
        .string()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value,
        )
        .nullable();

  if (org === 'MAPLEWOOD' || org === 'ANTHEM')
    return baseSchema.min(4, 'Password must be at least 4 characters long');

  const strongSchema = baseSchema
    .minLowercase(1, 'Password must contain at least 1 lowercase letter')
    .minUppercase(1, 'Password must contain at least 1 uppercase letter')
    .minNumbers(1, 'Password must contain at least 1 number')
    .minSymbols(1, 'Password must contain at least 1 special character');

  if (org === 'MGH' || org === 'CAREONE')
    return strongSchema.min(8, 'Password must be at least 8 characters long');

  return strongSchema.min(12, 'Password must be at least 12 characters long');
};

export const getUsersTableFields = (
  isAdminRole: boolean,
  roleMap: RoleMap,
  org?: string,
  textMessageAlerts?: boolean,
): ImportableDataFields<FieldTypes> => {
  const usernameRequired = !!org && isUsernameOrg(org);

  return [
    {
      field: 'id',
      label: 'ID',
      width: isAdminRole ? 380 : 'hidden',
      editType: 'text',
      editable: false,
      hideOnAdd: true,
      disabledOnImport: () => true,
    },
    {
      field: 'firstName',
      label: 'First Name',
      width: 150,
      editType: 'text',
      schema: yup.string().required('You must provide a first name.'),
    },
    {
      field: 'lastName',
      label: 'Last Name',
      width: 150,
      editType: 'text',
      schema: yup.string().required('You must provide a last name.'),
    },
    {
      field: 'email',
      label: 'Email',
      width: 300,
      editType: 'text',
      editable: true,
      // email is optional for username organization but required for other one
      schema: usersTableSchemas.getEmailSchema(!usernameRequired),
      onImportCellEditValidators: [importUniqueValidator],
    },
    {
      field: 'username',
      label: 'Username',
      width: usernameRequired ? 160 : 'hidden',
      editType: 'text',
      editable: true,
      // username is required for username organization but optional for other one
      schema: usernameRequired
        ? usersTableSchemas.usernameSchema
        : yup.string(),
    },
    {
      field: 'mobilePhone',
      label: 'Mobile phone',
      width: textMessageAlerts ? 160 : 'hidden',
      editType: 'mobile-phone',
      editable: !!textMessageAlerts,
      schema: yup
        .string()
        .optional()
        .nullable()
        .test('validMobilePhoneNumber', (value) =>
          value ? isValidMobileNumber(value) : true,
        )
        .transform((value, originalValue) =>
          // controlled input can return originalValue as '+1' for empty number
          originalValue === '' || originalValue === '+1'
            ? null
            : formatPhoneNumberSafe(value, PhoneNumberFormat.E164),
        ),
      valueFormatter: ({ value }) =>
        value
          ? formatPhoneNumberSafe(
              value,
              PhoneNumberFormat.US_NATIONAL_OTHER_INTERNATIONAL,
            )
          : '',
      renderCell: ({ value, row }) => (
        <>
          {value && (
            <GeneralChip
              bold
              label={formatPhoneNumberSafe(
                value,
                PhoneNumberFormat.US_NATIONAL_OTHER_INTERNATIONAL,
              )}
              size='small'
            />
          )}
          {row.mobilePhoneVerified && <VerifiedIcon />}
        </>
      ),
    },
    {
      field: 'password',
      label: 'Password',
      width: 'hidden',
      editType: 'password',
      addSchema: getPasswordSchema(org, true),
      editSchema: getPasswordSchema(org, false),
      importType: 'password',
      importSchema: getPasswordSchema(org, true),
    },
    {
      field: 'role',
      label: 'Role',
      width: 160,
      editType: 'select',
      options: getRoleOptions(isAdminRole, roleMap),
      schema: yup.string().required('User Role is required'),
      renderCell: ({ value }) => (
        <GeneralChip
          bold
          label={roleMap[value]?.displayName || ''}
          size='small'
        />
      ),
      valueFormatter: ({ value }) => roleMap[value]?.displayName || '',
      filterOperators: getGridStringOperators().map((filter) => ({
        ...filter,
        getApplyFilterFn: (filterItem, column) => {
          const filterValue = filterItem.value.toLowerCase();

          const filterFunction = filter.getApplyFilterFn(
            { ...filterItem, value: filterValue },
            column,
          );

          return (params) => {
            if (isEmpty(filterValue)) return true;
            const value = roleMap[params.value]?.displayName?.toLowerCase();
            return filterFunction?.({ ...params, value }) || false;
          };
        },
      })),
    },
    {
      field: 'org',
      label: 'Org',
      width: 'hidden',
      hideOnAdd: true,
      hideOnEdit: true,
      editType: 'select',
      options: [org ?? ''],
      importSchema: yup
        .string()
        .required('User Organization is required')
        .oneOf([org ?? '']),
    },
    {
      field: 'levelAccess',
      label: 'Level Access',
      width: 160,
      editType: 'special',
      initialValue: null,
      renderCell: ({ value }) =>
        value && <GeneralChip bold label={startCase(value)} size='small' />,
      schema: yup.string().when('role', (value: any) => {
        const role = roleMap[value];
        return role && role.template === 'Admin'
          ? yup.string().nullable()
          : yup.string().required('Level Access is required');
      }),
      importType: 'select',
      options: ['building', 'org', 'unit'],
      renderImportCell: (props) => (
        <GridEditSingleSelectCell {...props} value={props.value ?? ''} />
      ),
    },
    {
      field: 'unit',
      label: 'Unit',
      width: 300,
      editType: 'special',
      initialValue: null,
      schema: yup.string().when('role', (value: any) => {
        const role = roleMap[value];
        return role && role.template === 'Admin'
          ? yup.string().nullable()
          : yup.string().required('Unit is required');
      }),
      renderCell: isAdminRole
        ? undefined
        : ({ value }) => <UnitDisplayName unitId={value} />,
      renderImportCell: (props) => (
        <ImportUnitAutocomplete org={org} {...props} />
      ),
    },
    {
      field: 'beacon',
      label: 'Beacon ID',
      width: 160,
      editType: 'text',
      editable: false,
      renderCell: ({ value }) => (
        <>
          <Can
            permission={{
              action: 'view',
              subject: 'virtual-care.admin.beacons',
            }}
          >
            <Link href={`/admin/beacons/${value}`}>{value}</Link>
          </Can>
          <Cannot
            permission={{
              action: 'view',
              subject: 'virtual-care.admin.beacons',
            }}
          >
            {value}
          </Cannot>
        </>
      ),
    },
    {
      field: 'auth0Connection',
      label: 'Auth0 Connection',
      hideOnAdd: true,
      hideOnEdit: true,
      editable: false,
      width: 'hidden',
      editType: 'text',
    },
  ];
};

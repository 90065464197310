import PromisePool from '@supercharge/promise-pool';

import { WasabiImage } from '@inspiren-monorepo/shared-react';

import { Api } from '../../../../utility/client';
import { getSecondsBetweenDates } from '../../helpers/getSecondsBetweenDates';

export interface LiveRoomImages {
  images: WasabiImage[];
  room: string;
}

export const getRoomLiveImages = async (
  roomId: string,
  timeFrames: { start: Date; end: Date }[],
) => {
  const results = await PromisePool.withConcurrency(50)
    .for(timeFrames)
    .process(async (timeFrame) => {
      try {
        const res = await Api.get(`/v1/images/${roomId}`, {
          params: {
            start: timeFrame.start.toISOString(),
            duration: getSecondsBetweenDates(timeFrame.start, timeFrame.end),
            clear: false,
          },
        });

        return res && res?.data;
      } catch (error) {
        console.error('error requesting room live image');
        throw error;
      }
    });

  return results;
};

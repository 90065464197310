import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@mui/material';

type Props<Value> = Omit<
  AutocompleteProps<Value, false, false, false>,
  'renderInput' | 'loading' | 'loadingText'
> &
  Pick<TextFieldProps, 'label' | 'error' | 'margin'> & {
    loading?: boolean;
    tooltip?: string;
  };

const LoadingAutocomplete = <Value,>({
  label,
  tooltip,
  loading,
  disabled,
  error,
  margin,
  ...autocompleteProps
}: Props<Value>) => (
  <Autocomplete
    {...autocompleteProps}
    disabled={disabled}
    renderInput={(params) => (
      <Tooltip
        title={tooltip}
        placement='left'
        disableHoverListener={!tooltip}
        disableInteractive={!tooltip}
        disableFocusListener={!tooltip}
      >
        <TextField
          {...params}
          error={error}
          label={label}
          margin={margin}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && (
                  <CircularProgress
                    color={disabled ? 'secondary' : 'primary'}
                    size={18}
                  />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      </Tooltip>
    )}
  />
);

export default LoadingAutocomplete;

import { useMemo } from 'react';
import { UseQueryResult, skipToken, useQuery } from '@tanstack/react-query';

import { Role, RoleMap } from '../../../../types';
import { createRoleMap } from '../../../utility/helpers/createRoleMap';
import { getRolesForOrg } from '../data-access/getRolesForOrg';

type UseOrganizationRolesResult = UseQueryResult<Role[], unknown> & {
  roleMap: RoleMap;
};

export const useOrganizationRoles = (
  orgId?: string,
): UseOrganizationRolesResult => {
  const query = useQuery({
    queryKey: ['org', orgId, 'roles'],
    queryFn: orgId ? () => getRolesForOrg(orgId) : skipToken,
  });

  const roleMap = useMemo(() => createRoleMap(query.data), [query.data]);

  return {
    ...query,
    roleMap,
  };
};

import { useCallback } from 'react';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import {
  Bed,
  BedroomParent,
  Brush,
  Chair,
  Clear,
  ClearAll,
  PanTool,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useConfirm } from 'material-ui-confirm';

import { DrawingRegion } from '@inspiren-monorepo/hilq/api-contracts';

interface CanvasControlsProps {
  drawingRegion: DrawingRegion;
  handleDrawingRegionChange: (
    event: React.MouseEvent<HTMLElement>,
    newDrawingRegion: DrawingRegion,
  ) => void;
  canvasAction: 'draw' | 'move';
  handleCanvasActionChange: (
    event: React.MouseEvent<HTMLElement>,
    newCanvasAction: 'draw' | 'move',
  ) => void;
  handleClearSelectedRegion: () => void;
  handleClearAllRegions: () => void;
  handleCancel: () => void;
  handleRegionUpdate: () => void;
  isLoading: boolean;
  allPointsEmpty: boolean;
  width: number;
}

export const CanvasControls = ({
  drawingRegion,
  handleDrawingRegionChange,
  canvasAction,
  handleCanvasActionChange,
  handleClearAllRegions,
  handleClearSelectedRegion,
  handleCancel,
  handleRegionUpdate,
  isLoading,
  allPointsEmpty,
  width,
}: CanvasControlsProps) => {
  const confirm = useConfirm();

  const handleConfirm = useCallback(async () => {
    try {
      await confirm({
        title: "WARNING! You're about to save this room without any regions!",
      });

      handleRegionUpdate();
    } catch {
      // Do nothing. `confirm` will throw if the user cancels, which
      // is fine
    }
  }, []);

  return (
    <Paper sx={{ width: `${width}px`, height: '54px' }} elevation={3}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        height='100%'
        sx={{ padding: 2 }}
      >
        <Box>
          <ToggleButtonGroup
            color='primary'
            value={drawingRegion}
            exclusive
            onChange={handleDrawingRegionChange}
            aria-label='Platform'
          >
            <ToggleButton value='bedEdge'>
              <BedroomParent />
            </ToggleButton>
            <ToggleButton value='bed'>
              <Bed />
            </ToggleButton>
            <ToggleButton value='chair'>
              <Chair />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box>
          <ToggleButtonGroup
            color='primary'
            value={canvasAction}
            exclusive
            onChange={handleCanvasActionChange}
            aria-label='Platform'
          >
            <ToggleButton value='move'>
              <PanTool />
            </ToggleButton>
            <ToggleButton value='draw'>
              <Brush />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Stack direction='row' spacing={1}>
          <IconButton
            aria-label='delete'
            color='info'
            onClick={handleClearSelectedRegion}
          >
            <Clear />
          </IconButton>
          <IconButton
            aria-label='delete'
            color='info'
            onClick={handleClearAllRegions}
          >
            <ClearAll />
          </IconButton>
        </Stack>
        <Stack direction='row' spacing={2}>
          <Button
            variant='outlined'
            color='warning'
            sx={{ fontSize: 14, width: '140px' }}
            onClick={handleCancel}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            color='secondary'
            sx={{ fontSize: 14, width: '140px' }}
            onClick={allPointsEmpty ? handleConfirm : handleRegionUpdate}
            loading={isLoading}
            disabled={isLoading}
            loadingPosition='end'
          >
            Update
          </LoadingButton>
        </Stack>
      </Stack>
    </Paper>
  );
};

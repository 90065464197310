import { useCallback, useEffect, useMemo } from 'react';
import { Divider, Stack, Toolbar } from '@mui/material';
import { skipToken, useQuery, useQueryClient } from '@tanstack/react-query';
import { isBefore, subDays } from 'date-fns';
import { sortBy } from 'lodash';

import { EventReviewRoom } from '@inspiren-monorepo/virtual-care/api-contracts';

import DatePicker from './DatePicker';
import { getOrgs } from './data-access/getOrgs';
import { getRoomsForOrg } from './data-access/getRoomsForOrg';
import { getUnits } from './data-access/getUnits';
import { clearUrlParams } from './helpers/clearUrlParams';
import { useUpdateUrlParams } from './hooks/useUpdateURLParams';
import { useEventReviewStore } from './store/EventReviewStore';

import { useCurrentUser } from '../../HOC/CurrentUserContextProvider';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { sendAmpEvent } from '../../utility/amplitude';
import { sortUnitDropdown } from '../../utility/helpers/sortUnitDropdown';
import LoadingAutocomplete from '../shared/LoadingAutocomplete';

const EventToolbar = () => {
  const {
    selectedOrg,
    selectedUnit,
    selectedRoom,
    startDate,
    endDate,
    setSelectedOrg,
    setSelectedRoom,
    setSelectedUnit,
    setStartDate,
    setEndDate,
    setImages,
    setImagesError,
    setPosition,
    setShowNotifMarks,
    setShowStaffEventMarks,
  } = useEventReviewStore();

  const queryClient = useQueryClient();
  const { user } = useCurrentUser();
  const { isAdmin, isAdminLoading } = useIsAdmin();
  const levelAccess = user?.levelAccess;
  const userOrg = user?.org;

  const crossOrgAccess = isAdmin && !levelAccess;

  const { isLoading: orgsLoading, data: orgs = [] } = useQuery({
    queryKey: ['eventReview', 'orgs'],
    queryFn: getOrgs,
  });

  const { isLoading: unitsLoading, data: units } = useQuery({
    queryKey: ['eventReview', 'units'],
    queryFn: getUnits,
  });

  const { isFetching: roomsLoading, data: rooms } = useQuery({
    queryKey: ['eventReview', 'rooms', selectedOrg?.id],
    queryFn: selectedOrg ? () => getRoomsForOrg(selectedOrg?.id) : skipToken,
  });

  // If user doesn't have cross-org access,
  // auto-select their org (org dropdown will be hidden)
  useEffect(() => {
    if (
      orgs?.length &&
      userOrg &&
      !crossOrgAccess &&
      !orgsLoading &&
      !isAdminLoading
    ) {
      const orgObj = orgs.find((o) => o.id === userOrg);
      if (orgObj) setSelectedOrg(orgObj);
    }
  }, [orgs, userOrg, crossOrgAccess, orgsLoading, isAdminLoading]);

  useEffect(() => {
    // reset dates if outside of the deletion range of the unit
    if (
      selectedUnit?.imageExp &&
      selectedUnit?.eventExp &&
      (startDate || endDate)
    ) {
      const timeWindowStart = subDays(
        new Date(),
        Math.max(selectedUnit.imageExp, selectedUnit.eventExp),
      );

      if (
        (startDate && isBefore(startDate, timeWindowStart)) ||
        (endDate && isBefore(endDate, timeWindowStart))
      ) {
        setStartDate(null);
        setEndDate(null);
      }
    }

    // reset end date if its before start date
    if (startDate && endDate && isBefore(endDate, startDate)) {
      setEndDate(null);
    }

    if (selectedRoom && startDate && endDate) {
      sendAmpEvent('Date Selection', {
        room: selectedRoom.mainId,
        start: startDate,
        end: endDate,
      });
    }

    queryClient.invalidateQueries({ queryKey: ['eventReview', 'images'] });

    setImages(null);
    setPosition(0);
    setShowNotifMarks(true);
    setShowStaffEventMarks(true);
    setImagesError(null);
  }, [
    queryClient,
    selectedRoom,
    startDate,
    endDate,
    selectedUnit,
    setStartDate,
    setEndDate,
    setImages,
    setImagesError,
    setPosition,
    setShowNotifMarks,
    setShowStaffEventMarks,
  ]);

  const filteredUnits = useMemo(
    () =>
      selectedOrg
        ? sortUnitDropdown(
            units?.filter(
              (unit) =>
                unit.floor.includes(`${selectedOrg.id}-`) && unit.hide !== true,
            ),
          )
        : [],
    [selectedOrg, units],
  );

  const filteredRooms = useMemo(
    () =>
      selectedUnit
        ? sortBy(
            rooms?.filter(
              (room) =>
                room.unit === `Room-${selectedUnit.floor}-${selectedUnit.name}`,
            ),
            'name',
          )
        : [],
    [selectedUnit, rooms],
  );

  const handleRoomChange = useCallback(
    (_e: any, newValue: EventReviewRoom | null) => {
      setSelectedRoom(newValue);
    },
    [setSelectedRoom],
  );

  useUpdateUrlParams({
    orgs,
    units,
    filteredRooms,
    orgsLoading,
    unitsLoading,
    roomsLoading,
  });

  return (
    <Toolbar>
      <Stack
        direction='row'
        gap={2}
        flexWrap='wrap'
        justifyContent='center'
        alignItems='center'
        width='100%'
        p={2}
      >
        {crossOrgAccess && (
          <LoadingAutocomplete
            label='Organization'
            loading={orgsLoading}
            size='small'
            id='org'
            data-testid='org'
            options={sortBy(orgs, 'name')}
            value={selectedOrg}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={(_e, newValue) => {
              setSelectedRoom(null);
              setSelectedUnit(null);
              setSelectedOrg(newValue);
              clearUrlParams();
            }}
            sx={{ minWidth: 200 }}
          />
        )}

        <LoadingAutocomplete
          label='Unit'
          loading={
            crossOrgAccess
              ? unitsLoading && Boolean(selectedOrg)
              : unitsLoading || orgsLoading
          }
          size='small'
          id='unit'
          data-testid='unit'
          options={filteredUnits}
          getOptionLabel={(option) =>
            `${option.buildingDisplayName || option.address} ${
              option.displayName || option.name
            }`
          }
          renderOption={(props, option) => (
            <li {...props}>{option.displayName || option.name}</li>
          )}
          groupBy={(option) => option.buildingDisplayName || option.address}
          value={selectedUnit}
          isOptionEqualToValue={(o, v) =>
            o.floor === v.floor && o.name === v.name
          }
          onChange={(_e, newValue) => {
            setSelectedRoom(null);
            setSelectedUnit(newValue);
            clearUrlParams();
          }}
          disabled={crossOrgAccess && !selectedOrg}
          sx={{ minWidth: 285 }}
        />

        <LoadingAutocomplete
          label='Room'
          loading={roomsLoading && Boolean(selectedUnit)}
          size='small'
          id='room'
          data-testid='room'
          options={filteredRooms}
          getOptionLabel={(option) => option.name}
          value={selectedRoom}
          isOptionEqualToValue={(o, v) => o.mainId === v.mainId}
          onChange={handleRoomChange}
          disabled={!selectedOrg || !selectedUnit}
          sx={{ minWidth: 180 }}
        />

        <Divider
          sx={{
            width: '100%',
            border: 'none',
            display: { xs: 'block', sm: 'none' },
          }}
        />

        <DatePicker />
      </Stack>
    </Toolbar>
  );
};

export default EventToolbar;

import { differenceWith, isNil } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { FieldsConfiguration, Importable } from '../types/importable';

type FieldToIndexMap<TFieldTypes> = { [key in keyof TFieldTypes]: number };

const getFieldToIndexMap = <TFieldTypes>(
  headerRow: string[],
  fieldsConfiguration: FieldsConfiguration<TFieldTypes>,
): FieldToIndexMap<TFieldTypes> => {
  const requiredFields = Object.entries(fieldsConfiguration)
    .filter(([, value]) => !isNil(value))
    .map(([key]) => key as keyof TFieldTypes);

  // eslint-disable-next-line unicorn/no-array-reduce
  const fieldTypeToIndex = requiredFields.reduce((acc, field) => {
    const configuration = fieldsConfiguration[field];

    if (!configuration) {
      return acc;
    }

    const { csvHeader, csvHeaderRegExp } = configuration;

    if (!csvHeader) {
      return acc;
    }

    const headerIndex = headerRow.findIndex((r) => {
      const lowercase = r.toLocaleLowerCase();
      return csvHeaderRegExp
        ? csvHeaderRegExp.test(lowercase)
        : csvHeader === lowercase;
    });

    if (headerIndex >= 0) {
      return {
        ...acc,
        [field]: headerIndex,
      };
    }

    return acc;
  }, {} as FieldToIndexMap<TFieldTypes>);

  const fieldsInCsv = Object.keys(fieldTypeToIndex) as (keyof TFieldTypes)[];

  const missingColumns = differenceWith(requiredFields, fieldsInCsv);

  if (missingColumns.length > 0) {
    throw new Error(
      `Missing required columns: ${missingColumns.map((field) => `'${fieldsConfiguration[field]?.csvHeader}'`).join(', ')}`,
    );
  }

  return fieldTypeToIndex;
};

export const mapCsvRowsToFields = <TFieldTypes>(
  csvRows: string[][],
  fieldsConfiguration: FieldsConfiguration<TFieldTypes>,
  alreadyExists?: (fields: TFieldTypes) => boolean,
): Importable<TFieldTypes>[] => {
  const fieldTypeToIndex = getFieldToIndexMap(csvRows[0], fieldsConfiguration);

  return csvRows.slice(1).map((row): Importable<TFieldTypes> => {
    const fields = Object.keys(fieldsConfiguration).reduce((acc, cur) => {
      const field = cur as keyof TFieldTypes;
      return {
        ...acc,
        [field]: fieldsConfiguration[field]?.mapper(
          row[fieldTypeToIndex[field]],
        ),
      };
    }, {} as TFieldTypes);

    return {
      ...fields,
      $meta: {
        alreadyExists: alreadyExists ? alreadyExists(fields) : false,
        uniqueId: uuidv4(),
        isNew: true,
      },
    };
  });
};

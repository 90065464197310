import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { NewUser } from '../../../../../../types';
import { useCurrentUser } from '../../../../../HOC/CurrentUserContextProvider';
import { AdminToolUser } from '../../../data-access/getUsersTableQuery';
import { postUser } from '../../../data-access/postUser';
import { putUser } from '../../../data-access/putUser';
import { OnSubmitFormModal } from '../../../modals/FormModalBase';
import { FieldTypes } from '../helpers/getUsersTableFields';

type Props = {
  orgId: string | undefined;
  users?: AdminToolUser[];
};

const useUsersUpsert = ({ orgId, users }: Props) => {
  const queryClient = useQueryClient();

  const { user } = useCurrentUser();

  const handleEditSubmit: OnSubmitFormModal<FieldTypes> = useCallback(
    async (params) => {
      if (!orgId) return;

      const editedUser = users?.find((u) => u.mainId === params.id);

      const data: Partial<NewUser> & { userId: string } = {
        userId: params.id,
        firstName: params.firstName,
        lastName: params.lastName,
        roleId: params.role,
        mobilePhone: params.mobilePhone,
        email: params.email || editedUser?.email,
      };

      if (params.username) data.username = params.username;
      if (params.password) data.password = params.password;
      if (params.unit) data.unit = params.unit;
      if (params.levelAccess) data.levelAccess = params.levelAccess;

      await putUser(data);

      if (data.userId === user?.mainId) {
        // when logged user edits own settings, then we have to refresh current user
        await queryClient.invalidateQueries({ queryKey: ['auth-user'] });
      }

      await queryClient.invalidateQueries({ queryKey: ['users'] });
    },
    [orgId, users, queryClient, user],
  );

  const handleAddSubmit = useCallback(
    async (params: FieldTypes, invalidateQueries = true) => {
      if (!orgId) return;

      const data: Partial<NewUser> = {
        firstName: params.firstName,
        lastName: params.lastName,
        roleId: params.role,
        password: params.password,
        org: orgId,
      };

      if (params.unit) data.unit = params.unit;
      if (params.levelAccess) data.levelAccess = params.levelAccess;
      if (params.email) data.email = params.email;
      if (params.username) data.username = params.username;
      if (params.mobilePhone) data.mobilePhone = params.mobilePhone;

      await postUser(data as NewUser);

      if (invalidateQueries) {
        await queryClient.invalidateQueries({ queryKey: ['users'] });
      }
    },
    [orgId, queryClient],
  );

  return {
    handleEditSubmit,
    handleAddSubmit,
  };
};

export default useUsersUpsert;

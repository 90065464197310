import { getUnixTime, subDays } from 'date-fns';
import { isNil } from 'lodash';

import { EventImage, Unit } from '../../../types';

export const timeFormat = 'h:mm:ss aa';
export const dateTimeFormat = 'MM/dd/yyyy h:mm:ss aa';
export const dateTimeFormatNoYear = 'M/dd h:mm:ss aa';

export const getPositionFromDate = (
  images: EventImage[] | null,
  date: Date,
) => {
  if (isNil(images) || images.length === 0) return null;
  if (images.length === 1) return 0;

  const dateStr = date.toISOString();

  // Find first image after given date/time
  const after = images.findIndex((i) => i.time >= dateStr);

  // If none, return last position
  if (after === -1) return images.length - 1;

  // If first image, return first position
  if (after === 0) return 0;

  const before = after - 1;
  const unix = getUnixTime(date);

  const beforeRange = unix - getUnixTime(new Date(images[before].time));
  const afterRange = getUnixTime(new Date(images[after].time)) - unix;

  return beforeRange > afterRange ? after : before;
};

export const getTimestampOfNearestImage = (
  images: EventImage[] | null,
  date: Date,
) => {
  if (isNil(images)) return null;

  const position = getPositionFromDate(images, date);
  if (position === null) return null;

  const timestamp = new Date(images[position].time);
  return timestamp;
};

export const doesRangeExceedLimitForImages = (
  start: Date | null,
  end: Date | null,
  limit = 1000 * 60 * 60 * 24,
) => {
  if (!start || !end) return false;

  const startTime = start.getTime();
  const endTime = end.getTime();
  return endTime - startTime > limit;
};

export const isPastImageExpiration = (
  endDate: Date | null,
  selectedUnit: Unit | null,
): boolean =>
  Boolean(
    endDate &&
      selectedUnit &&
      !isNil(selectedUnit.imageExp) &&
      endDate < subDays(new Date(), selectedUnit.imageExp),
  );

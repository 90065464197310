import { useMemo } from 'react';
import { skipToken, useQuery } from '@tanstack/react-query';

import { useCurrentUser } from '../HOC/CurrentUserContextProvider';
import { getRoleById } from '../components/Admin/data-access/getRoleById';

export const useIsAdmin = () => {
  const { user } = useCurrentUser();

  const { data: userRoleData, isLoading: userRoleLoading } = useQuery({
    queryKey: ['userRole', user?.roleId],
    queryFn: user?.roleId ? () => getRoleById(user.roleId) : skipToken,
  });

  const isAdmin = useMemo(
    () => userRoleData?.template === 'Admin',
    [userRoleData?.template],
  );

  return { isAdmin, isAdminLoading: userRoleLoading };
};

import { useEffect, useState } from 'react';

import {
  AudibleMessageResponse,
  AudibleMessageStatus,
} from '../../../../types';
import { useUserOrg } from '../../../hooks/useUserOrg';
import { keysToCamelCase } from '../../../utility/helpers/helpers';
import {
  registerMessageHandler,
  unregisterMessageHandler,
} from '../../../utility/mqtt/mqttClient';
import { generateAudibleMessagesStatusTopic } from '../../../utility/mqtt/topicHelpers';

const TIMEOUT = 20 * 1000; // 20 seconds

export const useAudibleMessageRequestStatus = (
  baseId: string,
  requestId: string | null,
) => {
  const [status, setStatus] = useState<AudibleMessageStatus | null>(null);
  const org = useUserOrg();

  useEffect(() => {
    if (org && requestId) {
      const handlerId = `audible-message-${requestId}`;
      const topic = generateAudibleMessagesStatusTopic(org, baseId);

      const timeoutTimer = setTimeout(() => {
        setStatus('timeout');
        unregisterMessageHandler(topic, handlerId);
      }, TIMEOUT);

      registerMessageHandler(topic, {
        id: handlerId,
        call: (type, messageBaseId, payload) => {
          if (type === 'sound_resp' && baseId === messageBaseId) {
            const message = keysToCamelCase<AudibleMessageResponse>(
              JSON.parse(new TextDecoder().decode(payload)),
            );

            if (message.requestId === requestId) {
              clearTimeout(timeoutTimer);
              setStatus(message.status);
            }
          }
        },
      });

      return () => {
        clearTimeout(timeoutTimer);
        setStatus(null);
        unregisterMessageHandler(topic, handlerId);
      };
    }

    return () => true;
  }, [org, baseId, requestId]);

  return status;
};
